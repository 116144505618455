import { useStoreActions } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// import icons
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';

//model data Material
const refMaterial = {
  idMaterial: '',
  name: '',
  reference: null,
  quantity: '',
};

// data fix
const createName = 'Vétilla Zobda';
const supplierName = 'Sacarabany SARL';

const lastNumberCoupon = (table, key = null) => {
  const maxNumber = table.reduce((r, b) => Math.max(r, key ? b[key] : b), 0);
  const final = maxNumber + 1;
  return final;
};

const PrefillPurchaseOrder = () => {
  //state (données)
  const [customer, setCustomer] = useState();
  const [listeMaterialCreate, setListeMaterialCreate] = useState([refMaterial]);
  const [materials, setMaterials] = useState([]);
  const [referencesMaterials, setReferencesMaterials] = useState([]);
  const [numberCoupon, setNumberCoupon] = useState('');
  const [formError, setformError] = useState(false);
  const [idSaca, setIdSaca] = useState([]);

  //use Param
  const { idCustomer, idWorksite } = useParams();

  // navigate
  let navigate = useNavigate();

  //  🌱🌱 init store(services)   🌱🌱
  const listAllNumberOrderCoupon = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.listAllNumberOrderCoupon
  );
  const getListMaterialSettings = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.getListMaterialSettings
  );
  const createOrderCoupon = useStoreActions((actions) => actions.middleware.OrdersMiddleware.createOrderCoupon);
  const getSacaCompany = useStoreActions((actions) => actions.middleware.CompanyMiddleware.getSacaCompany);
  const getCompany = useStoreActions((actions) => actions.middleware.OrdersMiddleware.getCompany);

  // effect disable msg error form
  useEffect(() => {
    setformError(false);
  }, [numberCoupon, listeMaterialCreate]);

  //effect
  useEffect(() => {
    callGetCompagny();
    callListMaterialSettings();
    callListSaca();
    // middlewareCoupon.listAllNumberOrderCoupon().then((result) => {
    //   if (result.statut) {
    //     setNumberCoupon(lastNumberCoupon(result.data, 'numero'));
    //   }
    // });
    callListAllNumberOrderCoupon();
  }, []);

  // call list all number order coupon
  const callListAllNumberOrderCoupon = async () => {
    try {
      const res = await listAllNumberOrderCoupon();
      setNumberCoupon(lastNumberCoupon(res, 'numero'));
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ #1 Une erreur est survenue lors de la récupération des bons');
    }
  };

  //add line Material form
  const addMaterial = (e) => {
    e.preventDefault();
    setListeMaterialCreate([...listeMaterialCreate, refMaterial]);
  };

  //set value Material
  const changeValueMaterial = (index, key, value) => {
    let tempData = JSON.parse(JSON.stringify(listeMaterialCreate));

    if (key === 'name') {
      tempData[index].idMaterial = value;
      tempData[index][key] = referencesMaterials.find((el) => el.id === value).name;
      tempData[index].reference = null;
    } else {
      tempData[index][key] = value;
    }

    setListeMaterialCreate(tempData);
  };

  // delete line Material
  const deleteMaterial = (e, index) => {
    e.preventDefault();
    const temps = JSON.parse(JSON.stringify(listeMaterialCreate));
    temps.splice(index, 1);
    setListeMaterialCreate(temps);
  };

  //call customer and data worksite in coupon
  const callGetCompagny = async () => {
    try {
      const res = await getCompany(idCustomer);
      setCustomer(res);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ #2 Une erreur est survenue lors de la récupération des informations du client');
      window.location.href = '/clients';
    }
  };

  //call list materialSettings
  const callListMaterialSettings = async () => {
    try {
      const res = await getListMaterialSettings();
      const data = res.filter((item) => item.name !== 'Climatiseur');
      setReferencesMaterials(data);
      setMaterials(data);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ #3 Une erreur est survenue lors de la récupération des matériaux');
      window.location.href = '/clients';
    }
  };

  const navigateOrder = () => {
    navigate(-1);
  };

  //call list id saca
  const callListSaca = async () => {
    try {
      const res = await getSacaCompany();
      setIdSaca(res[0].id);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ #4 Une erreur est survenue lors de la récupération des informations du client');
      window.location.href = '/clients';
    }
  };

  // verif is all form not empty
  const isOkfrom = () => {
    let result = true;
    !createName && (result = false);
    !supplierName && (result = false);
    !numberCoupon && (result = false);
    !listeMaterialCreate.length && (result = false);
    for (const element of listeMaterialCreate) {
      for (const key in element) {
        !element[key] && (result = false);
      }
    }
    return result;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newdata = {
      name: createName,
      supplierName,
      numero: parseInt(numberCoupon),
      worksiteId: idWorksite,
      listeMaterialCreate,
      idSaca: idSaca,
    };
    if (isOkfrom()) {
      try {
        await createOrderCoupon(newdata);
        navigate(-1);
      } catch (error) {
        console.log('error: ', error);
        toast.error('❌ #5 Une erreur est survenue lors de la création du bon');
      }
    } else {
      setformError(true);
    }
  };

  return (
    <section>
      <div className="mt-14">
        <form onSubmit={onSubmit}>
          <div className=" mx-14 flex space-x-6">
            <div className="flex flex-col space-y-4">
              <label>Nom du client</label>
              <input
                className="inputFocus w-[343px] inputPreFill text-blue-admin"
                type="text"
                placeholder="Nom du client"
                defaultValue={customer?.name}
                disabled
              />
            </div>
            <div className="flex flex-col space-y-4 ">
              <label>Nom du fournisseur</label>
              <input
                className="inputFocus inputPreFill w-[343px] text-blue-admin"
                type="text"
                placeholder="Sacarabany SARL"
                defaultValue={supplierName}
                disabled
              />
            </div>
            <div className="flex flex-col space-y-4  w-full">
              <label>Numéro du bon</label>
              <input
                className="inputFocus inputPreFill text-blue-admin"
                type="number"
                defaultValue={numberCoupon}
                disabled
              />
            </div>
          </div>
          <div className="flex mx-14 space-x-6">
            <div className="  flex flex-col space-y-4 mt-5">
              <label>Créer par</label>
              <input
                className="inputFocus inputPreFill w-[343px]  text-blue-admin"
                type="text"
                placeholder="Vétilla Zobda"
                defaultValue={createName}
                disabled
              />
            </div>
          </div>
          {/* div list */}
          <div className="mt-8 mx-14">
            <h4 className="font-semibold">Liste du matériel utilisé</h4>
            {listeMaterialCreate.map((materiel, index) => {
              const idMaterial = listeMaterialCreate[index].idMaterial;
              const reference = referencesMaterials
                .find((el) => el.id === idMaterial)
                ?.references[0]?.reference?.split(',');
              return (
                <>
                  <div
                    key={materiel.id}
                    className="flex space-x-5 mt-5 items-center"
                  >
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Nom du matériel</label>
                      <select
                        className="w-[234px] rounded-lg py-4 border-gray-separate text-blue-admin"
                        onChange={(e) => changeValueMaterial(index, 'name', e.target.value)}
                      >
                        <option
                          selected
                          disabled
                        >
                          {' '}
                          -{' '}
                        </option>

                        {materials?.map((material) => {
                          return (
                            <option
                              key={material.id}
                              value={material.id}
                              selected={material.id === listeMaterialCreate[index]?.name}
                            >
                              {material.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Référence</label>
                      <select
                        className="w-[234px] rounded-lg py-4 border-gray-separate text-blue-admin"
                        onChange={(e) => changeValueMaterial(index, 'reference', e.target.value)}
                      >
                        <option
                          disabled
                          selected={listeMaterialCreate[index]?.reference === null ? true : false}
                        >
                          -
                        </option>

                        {reference?.map((referenceMaterial, index) => {
                          return (
                            <option
                              key={referenceMaterial.id}
                              value={index}
                              selected={
                                listeMaterialCreate[index]?.reference === null &&
                                referenceMaterial?.id === listeMaterialCreate[index]?.reference
                              }
                            >
                              {referenceMaterial}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="flex flex-col space-y-3">
                      <label className="text-gray-placeholder">Quantité</label>
                      <input
                        className="max-w-[234px] rounded-lg py-4 border border-gray-separate placeholder:text-blue-admin text-blue-admin"
                        type="number"
                        placeholder="-"
                        value={listeMaterialCreate[index]?.quantity}
                        onInput={(e) => changeValueMaterial(index, 'quantity', e.target.value)}
                      />
                    </div>
                    <div>
                      <button
                        onClick={(e) => deleteMaterial(e, index)}
                        className="w-8 p-2 border rounded-lg mt-7 border-white-admin"
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete icon"
                        />
                      </button>
                    </div>
                  </div>
                </>
              );
            })}
            <button
              onClick={addMaterial}
              className="text-blue-admin font-semibold mt-6 border bg-yellow-admin py-3 px-4 text-sm rounded-lg"
            >
              + Ajouter un nouveau matériel
            </button>
            {formError && <p className="text-red-error text-end mt-5">Veuillez remplir tous les champs!</p>}
          </div>
          <div className="mt-16 py-4 flex justify-between items-center border-t border-white-admin">
            <button
              onClick={navigateOrder}
              className="border rounded-lg cursor-pointer ml-14 border-gray-admin px-5 py-3 text-blue-admin"
            >
              Annuler
            </button>

            <div className=" cursor-pointer mr-14 bg-yellow-admin rounded-lg">
              <button
                type="submit"
                className="py-3 px-5 text-blue-admin"
              >
                Enregistrer ce bon pré-rempli
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default PrefillPurchaseOrder;

import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const OrdersMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST PURCHASE ORDERS
  //✨✨==========================================✨✨
  getListOrders: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listOrderCoupons(filter: {not: {classified: {eq: false}}}) {
          items {
            id
            name
            numero
            createdAt
            ctm
            worksite {
              type {
                name
              }
              name
              company {
                name
              }
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrderCoupons.items;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST INTERVENTION ORDERS
  //✨✨==========================================✨✨
  getListInterventionOrders: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listInterventionCoupons(filter: {not: {classified: {eq: false}}}) {
          items {
            id
            name
            numero
            createdAt
            ctm
            worksite {
              type {
                name
              }
              name
              company {
                name
              }
            }
      
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listInterventionCoupons.items;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST INTERVENTION CTM ORDERS
  //✨✨==========================================✨✨
  getListInterventionCtmOrders: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listCouponPreInters(filter: {dateSigned: {attributeExists: true}}) {
          items {
            id
            numero
            dateSigned
            worksites {
              name
              type {
                name
              }
              company {
                name
              }
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listCouponPreInters?.items;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ORDER COUPON
  //✨✨==========================================✨✨
  getOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getOrderCoupon(id: "${payload}") {
        id
        numero
        name
        signed
        nameClient
        nameWorksite
        createdAt
        updatedAt
        worksite {
          company {
            address
            mail
            siret
            name
          }
          name
        }
        materialData {
          items {
            name
            quantity
            materialSettings {
              name
              references {
                published
                reference
              }
            }
            indexRef
            description
          }
        }
        company {
          address
          mail
          name
          siret
        }
        collaborator {
          first_name
          last_name              
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getOrderCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ORDER COUPON BY ID
  //✨✨==========================================✨✨
  getOrderCouponById: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getOrderCoupon(id: "${payload}") {
        classified
        name
        numero
        signed
        worksite {
          id
          company {
            name
          }
        }
        materialData {
          items {
            id
            indexRef
            quantity
            materialSettings {
              id
              name
              references {
                published
                reference
              }
            }
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getOrderCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET INTERVENTION COUPON
  //✨✨==========================================✨✨
  getInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getInterventionCoupon(id: "${payload}") {
        id
        ctm
        numero
        bcCtm
        nameWorksite
        nameClient
        newMailClient
        createdAt
        updatedAt
        worksite {
          company {
            name
            siret
            address
            mail
            phone
          }
          name
          date_planned
        }
        materialData {
          items {
            name
            materialSettings {
              references {
                published
                reference
              }
              name
            }
            surface
            quantity
            indexRef
            description
            referenceQr
            address
            poseAddress
          }
        }
        signed
        orderCoupon {
          numero
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getInterventionCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST INTERVENTION SETTINGS
  //✨✨==========================================✨✨
  getListInterventionSettings: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listTypeInterventionSettings {
        items {
          id
          name
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTypeInterventionSettings.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST MATERIAL SETTINGS
  //✨✨==========================================✨✨
  getListMaterialSettings: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listMaterialSettings {
        items {
          name
          id
          references {
            published
            reference
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listMaterialSettings.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST ORDER COUPON
  //✨✨==========================================✨✨
  getListOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listOrderCoupons(filter: {classified: {eq: false}})
        {
          items 
          {
            classified
            id
            name
            nameClient
            nameWorksite
            numero
            orderCouponCollaboratorId
            signed
            updatedAt
            createdAt
            collaborator 
          {
            type {
              name
            }
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrderCoupons.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST INTERVENTION COUPON
  //✨✨==========================================✨✨
  getListInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listInterventionCoupons(filter: {classified: {eq: false}}) {
          items {
            createdAt
            classified
            id
            ctm
            bcCtm
            interventionCouponOrderCouponId
            interventionCouponUserId
            nameClient
            name
            nameWorksite
            numero
            orderCoupon {
              collaborator {
                type {
                  name
                }
              }
            }
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listInterventionCoupons.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //         GET LIST WORKSITES CLASSIFY
  //✨✨==========================================✨✨
  getListWorksitesClassify: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listWorksites(limit: 500000) {
          items {
            id
            name
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listWorksites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //         GET LIST COMPANY CLASSIFY
  //✨✨==========================================✨✨
  getListCompanyClassify: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listTypeCompanies(filter: {name: {eq: "client"}}) {
          items {
            id
            name
            companies {
              items {
                id
                name
                worksites {
                  items {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTypeCompanies.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST ALL NUMBER ORDER COUPONS
  //✨✨==========================================✨✨
  listAllNumberOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listOrderCoupons {
          items {
            numero
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listOrderCoupons.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST ALL NUMBER INTERVENTION COUPONS
  //✨✨==========================================✨✨
  listAllNumberInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listInterventionCoupons {
          items {
            numero
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listInterventionCoupons.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           CREATE MATERIAL DATA
  //✨✨==========================================✨✨
  createMaterialData: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createMaterialData(input: {
        name: "${payload.name}",
        quantity: ${payload.quantity},
        ${payload.address ? `,address: "${payload.address}"` : ''}
        ${payload.poseAddress ? `,poseAddress: "${payload.poseAddress}"` : ''}
        ${payload.indexRef !== undefined ? `,indexRef: ${payload.indexRef}` : ''}
        ${payload.description ? `,description: "${payload.description}"` : ''}
        ${payload.referenceQr ? `,referenceQr: "${payload.referenceQr}"` : ''}
        ${payload.orderCouponId ? `,orderCouponMaterialDataId: "${payload.orderCouponId}"` : ''}
        ${payload.materialSettingsId ? `,materialDataMaterialSettingsId: "${payload.materialSettingsId}"` : ''}
        ${
          payload.typeInterventionSettingsId
            ? `,materialDataTypeInterventionSettingsId: "${payload.typeInterventionSettingsId}"`
            : ''
        }
        ${payload.interventionCouponId ? `,interventionCouponMaterialDataId: "${payload.interventionCouponId}"` : ''}
      })
      {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createMaterialData;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           CREATE INTERVENTION COUPON
  //✨✨==========================================✨✨
  createInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createInterventionCoupon(input: {
        bcCtm: "${payload.bcCtm}",
        ctm: "true", 
        interventionCouponUserId: "${payload.colabSelected}",
        name: "Vétilla Zobda", 
        numero: "${payload.numero}", 
        worksiteIntervention_couponId: "${payload.worksite}"
      }) 
      {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createInterventionCoupon;
      payload.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          interventionCouponId: query.data?.createInterventionCoupon,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
          typeInterventionSettingsId: material.typeInterventionSettingsId,
        };
        OrdersMiddleware.createMaterialData(newDate);
      });
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE ORDER COUPON
  //✨✨==========================================✨✨
  createOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createOrderCoupon(input: {
        name: "${payload.name}",
        orderCouponCompanyId: "${payload.idSaca}", 
        numero: "${payload.numero}", 
        worksiteOrder_couponId: "${payload.worksiteId}", 
        bcCtm: "", 
        ctm: "true"
        }) {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createOrderCoupon;
      payload.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          orderCouponId: query.data?.createOrderCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
        };
        OrdersMiddleware.createMaterialData(newDate);
      });
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE ORDER COUPON
  //✨✨==========================================✨✨
  updateOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateOrderCoupon(input: {
        id: "${payload.id}", 
        numero: "${payload.numero}", 
        name: "${payload.name}", 
        ctm: "true", 
        bcCtm: "", 
        orderCouponCompanyId: "${payload.idSaca}", 
        worksiteOrder_couponId: "${payload.worksiteId}"
        }) {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateOrderCoupon;
      if (payload.newListeMaterialCreate.length >= 1) {
        payload.newListeMaterialCreate.forEach((material) => {
          const newDate = {
            ...material,
            orderCouponId: query.data?.updateOrderCoupon.id,
            materialSettingsId: material.idMaterial,
            indexRef: material.reference,
          };
          OrdersMiddleware.createMaterialData(newDate);
        });
      }
      payload.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          orderCouponId: query.data?.updateOrderCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
        };
        OrdersMiddleware.updateMaterialData(newDate);
      });
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE INTERVENTION COUPON
  //✨✨==========================================✨✨
  updateInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateInterventionCoupon(input: {
          id: "${payload.id}"
          bcCtm: "${payload.bcCtm}",
          ctm: "true", 
          interventionCouponUserId: "${payload.colabSelected}",
          name: "Vétilla Zobda", 
          numero: "${payload.numero}", 
          worksiteIntervention_couponId: "${payload.worksite}"
        }) 
        {
          id
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateInterventionCoupon;
      if (payload.newListeMaterialCreate.length >= 1) {
        payload.newListeMaterialCreate.forEach((material) => {
          const newDate = {
            ...material,
            interventionCouponId: query.data?.updateInterventionCoupon.id,
            materialSettingsId: material.idMaterial,
            indexRef: material.reference,
            typeInterventionSettingsId: material.typeInterventionSettingsId,
          };
          OrdersMiddleware.createMaterialData(newDate);
        });
      }
      payload.listeMaterialCreate.forEach((material) => {
        const newDate = {
          ...material,
          interventionCouponId: query.data?.updateInterventionCoupon.id,
          materialSettingsId: material.idMaterial,
          indexRef: material.reference,
        };
        OrdersMiddleware.updateMaterialData(newDate);
      });
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE MATERIAL DATA
  //✨✨==========================================✨✨
  updateMaterialData: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateMaterialData(input: {
        id :  "${payload.id}",
        name: "${payload.name}",
        quantity: ${payload.quantity},
        ${payload.address ? `,address: "${payload.address}"` : ''}
        ${payload.indexRef !== undefined ? `,indexRef: ${payload.indexRef}` : ''}
        ${payload.description ? `,description: "${payload.description}"` : ''}
        ${payload.referenceQr ? `,referenceQr: "${payload.referenceQr}"` : ''}
        ${payload.orderCouponId ? `,orderCouponMaterialDataId: "${payload.orderCouponId}"` : ''}
        ${payload.materialSettingsId ? `,materialDataMaterialSettingsId: "${payload.materialSettingsId}"` : ''}
        ${
          payload.typeInterventionSettingsId
            ? `,materialDataTypeInterventionSettingsId: "${payload.typeInterventionSettingsId}"`
            : ''
        }
        ${payload.interventionCouponId ? `,interventionCouponMaterialDataId: "${payload.interventionCouponId}"` : ''}
      })
      {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateMaterialData;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            MOVE ORDER COUPON TO WORKSITE
  //✨✨==========================================✨✨
  moveOrderCouponToWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        updateOrderCoupon(input: 
          {
            id: "${payload.id}", 
            worksiteOrder_couponId: "${payload.idWorksite}", 
            classified: ${true}}) 
          {
            id
          }
        }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateOrderCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            MOVE INTERVENTION COUPON TO WORKSITE
  //✨✨==========================================✨✨
  moveInterventionCouponToWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        updateInterventionCoupon(input: 
          {
            id: "${payload.id}", 
            worksiteIntervention_couponId: "${payload.idWorksite}", 
            classified: true}) 
            {
            id
          }
        }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateInterventionCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE MATERIAL DATA
  //✨✨==========================================✨✨
  deleteMaterialData: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteMaterialData(input: {
      id: "${payload.id}"
      }) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteMaterialData;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE MULTIPLE MATERIALS DATA
  //✨✨==========================================✨✨
  deletesMultipleMaterialsData: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      ${payload.listMaterial.map(
        (material, index) =>
          `task${index}: deleteMaterialData(input: {id: "${material.id}"}) {
          id
        }`
      )}
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteMaterialData;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE PREFILL COUPON
  //✨✨==========================================✨✨
  deletePrefillCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteOrderCoupon(input: {
        id: "${payload.id}"
      }, condition: {ctm: {eq: "true"}}) 
      {
      id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteOrderCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE INTERVENTION COUPON
  //✨✨==========================================✨✨
  deletePrefillInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteInterventionCoupon(input: {id: "${payload.id}"}, condition: {ctm: {eq: "true"}}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteInterventionCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE ORDER COUPON
  //✨✨==========================================✨✨
  deleteOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        deleteOrderCoupon(input: {id: "${payload}"}, condition: {classified: {eq: false}}) {
          id
        } 
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteOrderCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE INTERVENTION COUPON
  //✨✨==========================================✨✨
  deleteInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        deleteInterventionCoupon(input: {id: "${payload}"}, condition: {classified: {eq: false}}) {
          id
        } 
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteInterventionCoupon;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET COMPANY
  //✨✨==========================================✨✨
  getCompany: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getCompany(id: "${payload}") {
        name
        worksites {
          items {
            id
            users {
              items {
                user {
                  first_name
                  last_name
                  id
                }
              }
            }
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getCompany;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default OrdersMiddleware;


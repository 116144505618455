/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import Warning from '../../../assets/images/svg/attention.svg';

// Tools
import { dateFormat } from '../../tools/date';
import CustomSelect from '../CustomSelect';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const TrainingsList = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const listTrainings = useStoreActions((actions) => actions.middleware.TrainingMiddleware.listTrainings);
  const listTrainingsByCollaborator = useStoreActions(
    (actions) => actions.middleware.TrainingMiddleware.listTrainingsByCollaborator
  );
  const getListCollaborators = useStoreActions(
    (actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators
  );

  //  🌱🌱  Etats (données)  🌱🌱
  const [trainings, setTrainings] = useState([]);
  const [collaborators, setCollaborators] = useState([]);

  //  🌱🌱 Request call List Trainings  🌱🌱
  const callListTrainings = async () => {
    try {
      const res = await listTrainings();
      setTrainings(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des formations');
    }
  };

  //   Check if the expiration date is in the future
  const isExpired = (date) => {
    const today = new Date();
    const expirationDate = new Date(date);
    return expirationDate <= today;
  };

  // 🌱🌱  Call get list collaborators  🌱🌱
  const callGetListCollaborators = async () => {
    try {
      const payload = {
        nameCollab: collaboratorName.toString(),
      };
      const data = await getListCollaborators(payload);
      console.log('data', data);

      //   Sort by name
      data.sort((a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });

      setCollaborators(
        data.map((collab) => {
          return { ...collab, fullName: `${collab.first_name} ${collab.last_name}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListTrainings();
    callGetListCollaborators();
  }, []);

  //  🌱🌱  filter list by collaborator  🌱🌱
  const filterListTrainingsByCollaborator = async (collaborator) => {
    setTrainings([]);
    try {
      if (!collaborator) {
        callListTrainings();
        return;
      }
      const res = await listTrainingsByCollaborator(collaborator);
      setTrainings(res);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des formations');
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="px-4 md:pt-14 md:px-9">
      {/* div Title +button */}
      <div className="flex flex-col justify-between pb-4 mb-4 space-y-4 border-b items center border-gray-admin pt-11 md:pt-0 md:flex-row md:space-y-0">
        <h3 className="text-xl font-bold ">Liste des formations</h3>
        <SecondaryButton
          title=" + Ajouter une nouvelle formation"
          link={'add'}
        />
      </div>

      {/* Table list Trainings */}
      <div className="mt-9">
        {/* <select
          className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown py-3 mb-4 pl-5 pr-10 rounded-lg border-none inputFocus"
          onChange={(e) => filterListTrainingsByCollaborator(e.target.value)}
        >
          <option value="">Filtre par collaborateur</option>
          {collaborators.map((collaborator) => (
            <option
              key={collaborator.id}
              value={collaborator.id}
            >
              {collaborator.fullName}
            </option>
          ))}
        </select> */}
        <CustomSelect
          options={collaborators}
          onChange={(value) => filterListTrainingsByCollaborator(value)}
          placeholder="Filtre par collaborateur"
        />
        <Table>
          <thead>
            <tr>
              <th>Nom de la formation</th>
              <th>Collaborateur</th>
              <th>Date d'expiration </th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {trainings.length ? (
              trainings.map((training) => {
                const expirationDate = dateFormat(training?.expiration_date, '/').full;
                return (
                  <tr key={training.id}>
                    <td>{training.name}</td>
                    <td>
                      <span>
                        {training?.collaborator?.first_name} {training?.collaborator?.last_name}
                      </span>
                    </td>
                    <td className={isExpired(training?.expiration_date) ? 'text-red-error' : ''}>
                      <div className="flex items-center space-x-2">
                        {expirationDate}
                        {isExpired(training?.expiration_date) && (
                          <>
                            <span className="text-red-error ml-4">Expirée</span>
                            <img
                              src={Warning}
                              alt="icone warning"
                            />
                          </>
                        )}
                      </div>
                    </td>
                    <td className="flex items-center justify-end pt-4 space-x-4">
                      <Link
                        to={`edit/${training.id}`}
                        className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1 "
                      >
                        <img
                          src={More}
                          alt="more icon"
                        />
                      </Link>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-lg text-center"
                >
                  La liste des formations est vide. Veuillez ajouter une formation.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default TrainingsList;


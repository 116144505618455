import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { format, startOfMonth, endOfMonth, addMonths, subMonths, eachDayOfInterval, isBefore } from 'date-fns';
import { fr } from 'date-fns/locale';
import Modal from '../Modal_2';
import { toast } from 'react-toastify';
import { getWaveBlob } from 'webm-to-wav-converter';

// Import Icons
import RecordIcon from '../../../assets/images/svg/record.svg';
import StopIcon from '../../../assets/images/svg/stop.svg';
import SaveIcon from '../../../assets/images/svg/download1.svg';
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import LeftArrow from '../../../assets/images/svg/left-arrow.svg';
import RightArrow from '../../../assets/images/svg/right-arrow.svg';
import AttentionIcon from '../../../assets/images/svg/attention.svg';
import Check from '../../../assets/images/svg/check.svg';

// Import Components
import HoverStatuts from '../HoverStatuts';
import AudioPlayer from '../AudioPlayer';

const S3url = process.env.REACT_APP_URL_S3_OBJECT;

const Plannings = () => {
  const [listWorkSites, setListWorkSites] = useState([]);
  const [clients, setClients] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedClient, setSelectedClient] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [worksiteToMove, setWorksiteToMove] = useState(null);
  const [newDate, setNewDate] = useState(null);

  const getlistWorkSite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.listWorkSites);
  const getlistWorksiteByCompany = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.getlistWorkSite);
  const updateDateWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.updateDateWorkSite);

  useEffect(() => {
    callListWorkSite();
  }, []);

  const callListWorkSite = async () => {
    try {
      const res = await getlistWorkSite();
      const clientsArray = [];
      res.forEach((element) => {
        if (element.company && !clientsArray.includes(element.company)) {
          clientsArray.push(element.company);
        }
      });
      const uniqueClients = Array.from(new Map(clientsArray.map((item) => [item.name, item])).values());
      setClients(uniqueClients);
      setListWorkSites(res);
    } catch (error) {
      setModalMessage('Erreur lors de la récupération des chantiers.');
      setModalIsOpen(true);
    }
  };

  const filterWorksite = async (client) => {
    try {
      setSelectedClient(client);
      if (!client) {
        callListWorkSite();
        return;
      } else {
        const res = await getlistWorksiteByCompany(client);
        setListWorkSites(res);
      }
    } catch (error) {
      setModalMessage('Erreur lors de la récupération des chantiers.');
      setModalIsOpen(true);
    }
  };

  const confirmMoveWorksite = async () => {
    try {
      console.log('je rentre ici', worksiteToMove, newDate);

      await updateDateWorksite({ id: worksiteToMove.id, date: newDate });
      setListWorkSites((prev) =>
        prev.map((worksite) => (worksite.id === worksiteToMove.id ? { ...worksite, date_planned: newDate } : worksite))
      );
      setModalIsOpen(false);
    } catch (error) {
      setModalMessage('Erreur lors de la modification du chantier.');
      setModalIsOpen(true);
    }
  };

  const startOfMonthDate = startOfMonth(currentMonth);
  const endOfMonthDate = endOfMonth(currentMonth);
  const daysInMonth = eachDayOfInterval({ start: startOfMonthDate, end: endOfMonthDate });

  return (
    <section className="flex flex-col h-full px-4 pt-4 md:pt-14 md:px-9">
      <h3 className="font-bold text-xl border-b border-gray-admin pb-[2.125rem]">Planning des chantiers</h3>
      <div>
        <HoverStatuts text="Statut ☉" />
      </div>
      <DndProvider backend={HTML5Backend}>
        <div className="pt-4">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
              className="bg-primary-blue flex items-center text-white px-4 py-2 rounded-lg text-sm md:text-md lg:text-base"
            >
              <img
                src={LeftArrow}
                alt="left arrow"
                className="w-4 h-4"
              />
              <span className="ml-2">Mois précédent</span>
            </button>
            <span className="font-bold text-md md:text-lg lg:text-xl">
              {format(currentMonth, 'MMMM yyyy', { locale: fr })}
            </span>
            <button
              onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
              className="bg-primary-blue flex items-center text-white px-4 py-2 rounded-lg text-sm md:text-md lg:text-base"
            >
              <span className="mr-2">Mois suivant</span>
              <img
                src={RightArrow}
                alt="right arrow"
                className="w-4 h-4"
              />
            </button>
          </div>
          <div className="mb-4">
            <select
              onChange={(e) => filterWorksite(e.target.value)}
              className="p-2 border rounded-lg border-gray-admin outline-none"
            >
              <option value="">Filtrer par client</option>
              {clients.map((client) => (
                <option
                  key={client.id}
                  value={client.id}
                >
                  {client.name}
                </option>
              ))}
            </select>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 gap-4">
            {listWorkSites.length
              ? daysInMonth.map((date, index) => (
                  <DayColumn
                    key={index}
                    date={date}
                    listWorkSites={listWorkSites}
                    setWorksiteToMove={setWorksiteToMove}
                    setNewDate={setNewDate}
                    setModalMessage={setModalMessage}
                    setModalIsOpen={setModalIsOpen}
                  />
                ))
              : null}
          </div>
          <Modal isVisible={modalIsOpen}>
            <div>
              <div className="pt-5 pl-5">
                <img
                  src={Check}
                  alt="validate icon"
                />
                <h2 className="py-7 font-semibold">Confirmer le déplacement</h2>
                <p>Voulez-vous vraiment déplacer ce chantier à la nouvelle date ?</p>
                {worksiteToMove && (
                  <>
                    <p>
                      Ancienne date : {format(new Date(worksiteToMove?.date_planned), 'dd/MM/yyyy', { locale: fr })}
                    </p>
                    <p>Nouvelle date : {format(newDate, 'dd/MM/yyyy', { locale: fr })}</p>
                  </>
                )}
                <div className="text-center pt-7">
                  <button
                    className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                    onClick={() => setModalIsOpen(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="px-10 py-3 text-bue-admin bg-yellow-admin rounded-xl"
                    onClick={confirmMoveWorksite}
                  >
                    Confirmer
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </DndProvider>
    </section>
  );
};

const DayColumn = ({ date, listWorkSites, setWorksiteToMove, setNewDate, setModalMessage, setModalIsOpen }) => {
  const [, drop] = useDrop(() => ({
    accept: 'worksite',
    drop: (item) => {
      console.log('item', item);
      console.log('listWorkSites', listWorkSites);

      const worksite = listWorkSites.find((ws) => ws.id === item.id);
      if (isBefore(date, new Date())) {
        toast.error("Vous ne pouvez pas déplacer un chantier à une date antérieure à aujourd'hui.");
        return;
      }
      if (worksite?.status === 'Passé' || worksite?.status === 'Terminé') {
        toast.error("Vous ne pouvez pas déplacer un chantier avec le statut 'Passé' ou 'Terminé'.");
        return;
      }
      setWorksiteToMove(worksite);
      setNewDate(date);
      setModalMessage('Voulez-vous vraiment déplacer ce chantier à la nouvelle date ?');
      setModalIsOpen(true);
    },
  }));

  return (
    <div
      ref={drop}
      className="p-2 rounded-lg shadow-md bg-blue-dropdown"
    >
      <h3 className="font-bold">{format(date, 'EEEE dd/MM', { locale: fr })}</h3>
      {listWorkSites
        .filter((worksite) => format(new Date(worksite.date_planned), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd'))
        .map((worksite) => (
          <WorksiteCard
            key={worksite.id}
            worksite={worksite}
          />
        ))}
    </div>
  );
};



const WorksiteCard = ({ worksite }) => {
  // États
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Actions API
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const deleteFile = useStoreActions((actions) => actions.middleware.FileS3.deleteFile);
  const updateAudioWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.updateAudioWorksite);

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else if (mediaRecorder) {
      mediaRecorder.stop();
    }
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        getWaveBlob(event.data, true).then((e) => {
          setAudioBlob(e);
        });
      };
      recorder.start();
      setMediaRecorder(recorder);
    } catch (error) {
      toast.error("Impossible de démarrer l'enregistrement audio.");
    }
  };

  const handleSaveAudio = async () => {
    if (!audioBlob) {
      toast.error('Aucun enregistrement audio disponible à sauvegarder.');
      return;
    }

    if (!worksite) {
      toast.error('Aucun chantier sélectionné pour ajouter une note vocale.');
      return;
    }

    try {
      // Convertir le blob en fichier
      const file = new File([audioBlob], `audio_note_${worksite.id}_${Date.now()}.wav`, {
        type: 'audio/wave',
      });
      console.log('file', file);

      // Générer un chemin unique
      const filePath = `audio/audio_note_${worksite.id}_${Date.now()}.wav`;
      console.log('filePath', filePath);

      // Étape 1 : Upload du fichier audio dans S3
      await uploadFile({
        file: file,
        nameFile: filePath,
        contentType: file.type,
      });

      // Étape 2 : Mise à jour des données du chantier avec l'URL de l'audio
      const audioUrl = filePath;
      console.log('audioUrl', audioUrl);
      await updateAudioWorksite({
        id: worksite.id,
        audioUrl: audioUrl,
      });

      toast.success('Note vocale ajoutée avec succès.');
      setAudioBlob(null);
      window.location.reload();
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la note vocale. Veuillez réessayer.");
      console.error('Erreur lors de la sauvegarde de la note vocale:', error);
    }
  };

  const handleDeleteAudio = async () => {
    try {
      await deleteFile({
        nameFile: worksite?.audioUrl,
      });
      // Update audioUrl to null
      await updateAudioWorksite({
        id: worksite.id,
        audioUrl: null,
      });
      toast.success('Note vocale supprimée avec succès.');
    } catch (error) {
      toast.error('Erreur lors de la suppression de la note vocale. Veuillez réessayer.');
      console.error('Erreur lors de la suppression de la note vocale:', error);
    } finally {
      setShowModal(false);
      window.location.reload();
      setAudioBlob(null);
      setIsRecording(false);
      setMediaRecorder(null);
    }
  };

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'worksite',
    item: { id: worksite.id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const getStatusColor = (status) => {
    switch (status) {
      case 'En cours':
        return 'border-b-4 border-statut-step-3';
      case 'Futur':
        return 'border-b-4 border-statut-step-1';
      case 'Passé':
      case 'Terminé':
        return 'border-b-4 border-statut-step-0';
      default:
        return 'border-b-4 border-statut-step-2';
    }
  };
  return (
    <section>
      <div
        ref={drag}
        className={`p-2 mb-2 rounded-lg bg-white shadow-md ${getStatusColor(worksite.status)} ${
          isDragging ? 'opacity-50' : ''
        }`}
      >
        <h4 className="font-bold text-lg">{worksite?.name}</h4>
        <p>{worksite?.company?.name}</p>
        {/* Assigned collaborators */}
        {worksite?.collaborators?.items.length ? (
          <div className="flex items-center gap-4 my-2">
            <span className="">
              {worksite?.collaborators?.items.map((collab) => (
                <span
                  key={collab.user.id}
                  className="flex flex-col items-center gap-2 mb-2"
                >
                  <span className="hoverInitial">
                    {collab.user.first_name[0]}
                    {collab.user.last_name[0]}
                    <span>
                      {collab.user.first_name}
                      {collab.user.last_name}
                    </span>
                  </span>
                </span>
              ))}
            </span>
          </div>
        ) : null}
        <p className="text-sm text-gray-600">{worksite?.status}</p>
        {/* Lecture de l'audio existant */}
        {worksite?.audioUrl && worksite?.audioUrl !== '' && worksite?.audioUrl !== 'null' && (
          <div className="mt-4">
            <AudioPlayer src={`${S3url}${worksite?.audioUrl}`} />
            <div className="flex items-center space-x-2 bg-gray-100 p-2 rounded-lg shadow-md mt-4">
              <button
                onClick={() => setShowModal(true)}
                className="w-10 h-10 flex items-center justify-center text-white"
              >
                <img
                  src={DeleteIcon}
                  alt="delete icon"
                  className="w-6 h-6"
                />
              </button>
              <div className="flex-grow text-sm text-gray-700">Cliquez pour supprimer</div>
            </div>
          </div>
        )}

        {/* Enregistrement d'une nouvelle note vocale */}
        {(worksite?.audioUrl === '' || !worksite?.audioUrl || worksite?.audioUrl === 'null') && (
          <div className="my-4 flex flex-col sm:items-center text-xs md:text-sm">
            <button
              onClick={() => setIsRecording(!isRecording)}
              className="px-4 py-2 rounded-lg w-full sm:w-auto flex items-center justify-center bg-transparent"
            >
              <img
                src={isRecording ? StopIcon : RecordIcon}
                alt={isRecording ? 'Arrêter l’enregistrement' : 'Enregistrer une note vocale'}
                className="w-8 h-8"
              />
            </button>

            {/* Lecture et sauvegarde de l'audio */}
            {audioBlob && (
              <div className="mt-4 sm:mt-0 flex flex-col sm:items-center w-full">
                <AudioPlayer src={URL.createObjectURL(audioBlob)} />
                <button
                  onClick={handleSaveAudio}
                  className="w-full sm:w-auto mt-2 flex items-center justify-center bg-transparent"
                >
                  Sauvegarder la note vocale
                  <img
                    src={SaveIcon}
                    alt="Sauvegarder la note vocale"
                    className="ml-2 w-8 h-8"
                  />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Modal */}
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <h2 className="py-7 font-semibold">Confirmer la suppression de l'audio</h2>
            <p>Voulez-vous vraiment supprimer cet audio ? Cette action est définitive.</p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 bg-yellow-admin text-blue-admin rounded-xl"
                onClick={handleDeleteAudio}
              >
                Confirmer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default Plannings;


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { twMerge } from 'tailwind-merge';
//components custom
import PrimaryInput from './PrimaryInput';

// Import icons
import Eye from '../../assets/images/png/eye.png';
import EyeSlash from '../../assets/images/png/eye-slash.png';

//Exemple Regex:
// - Password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/
// - Phone: /^[0-9]{10}$|([+])([0-9]{11,12})$/
// - Siret: /^[0-9]{14}$/
// - Email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
// - Adresse française: /^\d+\D+\d{5}\D+\w+$/

const FormCustom = forwardRef(({ data, resultSubmit, containerStyle, onChange }, ref) => {
  useImperativeHandle(ref, () => ({
    submit() {
      const form = myForm.current;
      const formData = new FormData(form);
      const values = Object.fromEntries(formData);
      verif(values);
    },
  }));

  //init ref
  const myForm = useRef(null);

  //init state
  const [valueErreur, setValueErreur] = useState([]);
  const [passwordVisible, setPasswordVisible] = useState({}); // Toggle password visibility
  const [phonePrefix, setPhonePrefix] = useState('+33'); // Default phone prefix

  //effect
  useEffect(() => {
    const prime = data.map(() => true);
    setValueErreur(prime);
    const initialPasswordVisibility = {};
    data.forEach((item, index) => {
      if (item.inputType === 'password') initialPasswordVisibility[index] = false;
    });
    setPasswordVisible(initialPasswordVisibility);
  }, [data]);

  const [phoneData, setPhoneData] = useState({ prefix: '+33', number: '' });

  // Initialiser les valeurs au montage
  useEffect(() => {
    const phoneField = data.find((field) => field.inputType === 'tel_custom');
    if (phoneField && phoneField.defaultValue) {
      const { prefix, number } = splitPhoneNumber(phoneField.defaultValue);
      setPhoneData({ prefix, number });
    }
  }, [data]);

  // Fonction pour découper le numéro de téléphone en préfixe et numéro local
  const splitPhoneNumber = (phoneNumber) => {
    const prefixes = ['+33', '+596', '+696']; // Ajoutez d'autres préfixes si nécessaire
    for (const prefix of prefixes) {
      if (phoneNumber.startsWith(prefix)) {
        return {
          prefix,
          number: phoneNumber.replace(prefix, ''),
        };
      }
    }
    return { prefix: '+33', number: phoneNumber }; // Valeurs par défaut
  };

  const verif = (dataForm) => {
    let result = true;
    const tabKey = Object.keys(dataForm);
    const prime = [...valueErreur];

    tabKey.forEach((key, index) => {
      if (data[index].inputType === 'tel_custom') {
        // Combiner préfixe et numéro local pour le champ téléphone
        dataForm[key] = `${phoneData.prefix}${phoneData.number}`;
      }

      let instantResult = true;
      if (data[index].required === true) {
        if (data[index].regex && !dataForm[key].match(data[index].regex)) {
          result = false;
          instantResult = false;
        }
        if (!dataForm[key]) {
          result = false;
          instantResult = false;
        }
      } else {
        if (dataForm[key] && data[index].regex && !dataForm[key].match(data[index].regex)) {
          result = false;
          instantResult = false;
        }
      }

      prime[index] = instantResult;
    });

    setValueErreur(prime);
    resultSubmit({ status: result, values: result && dataForm });
  };

  //component form
  const formInput = (
    <form
      className="flex flex-wrap justify-between"
      ref={myForm}
    >
      {data.map((form, index) => {
        // Gestion des inputs de type "select"
        if (form.inputType === 'select') {
          return (
            <div
              key={index}
              className={twMerge(
                `mt-5 w-full flex flex-col ${form.halfWidth === 'full' ? 'md:w-full' : ''} ${
                  form.halfWidth === '1/2' || form.halfWidth === true ? 'md:w-[49%]' : ''
                } ${form.halfWidth === '1/2-full' ? 'md:w-full' : ''} ${containerStyle || ''}`
              )}
            >
              <label
                htmlFor={form.valueName}
                className="text-sm font-bold text-blue-admin"
              >
                {form.label}
              </label>
              <select
                name={form.valueName}
                className={twMerge(
                  `h-14 text-blue-admin mt-3 font-medium rounded-xl inputFocus w-full pl-3 border bg-white-admin ${
                    form.halfWidth === 'full' ? 'md:w-full' : ''
                  } ${valueErreur[index] ? '' : 'input_error'}`
                )}
                id={form.valueName}
                defaultValue={form.defaultValue || ''}
              >
                <option value="">{form.placeholder || '-'}</option>
                {form.options.map((ele, ix) => (
                  <option
                    key={ix}
                    value={ele[form.keyValues]}
                  >
                    {ele[form.keyOptions]}
                  </option>
                ))}
              </select>
              <p className="text-red-error">{valueErreur[index] ? '' : form.errorMsg}</p>
            </div>
          );
        }

        // Gestion des inputs de type "tel"
        if (form.inputType === 'tel_custom') {
          return (
            <div
              key={index}
              className={twMerge(`mt-5 w-full flex flex-col md:w-[49%] ${containerStyle || ''}`)}
            >
              <label className="text-sm font-bold text-blue-admin">{form.label}</label>
              <div className="flex items-center mt-3">
                <select
                  value={phoneData.prefix}
                  onChange={(e) => setPhoneData({ ...phoneData, prefix: e.target.value })}
                  className="h-14 border rounded-l-xl pl-3 bg-white-admin"
                >
                  <option value="+33">+33</option>
                  <option value="+596">+596</option>
                  <option value="+696">+696</option>
                </select>
                <input
                  type="tel"
                  name={form.valueName}
                  placeholder={form.placeholder}
                  value={phoneData.number}
                  onChange={(e) => setPhoneData({ ...phoneData, number: e.target.value })}
                  className={twMerge(`h-14 w-full border rounded-r-xl pl-3 ${valueErreur[index] ? '' : 'input_error'}`)}
                />
              </div>
              <p className="text-red-error">{valueErreur[index] ? '' : form.errorMsg}</p>
            </div>
          );
        }

        // Gestion des inputs de type "password"
        if (form.inputType === 'password') {
          return (
            <div
              key={index}
              className={twMerge(
                `mt-5 w-full flex flex-col ${form.halfWidth === true ? 'md:w-[49%]' : ''} ${containerStyle || ''}`
              )}
            >
              <label
                htmlFor={form.valueName}
                className="text-sm font-bold text-blue-admin"
              >
                {form.label}
              </label>
              <div className="relative mt-3">
                <input
                  type={passwordVisible[index] ? 'text' : 'password'}
                  name={form.valueName}
                  placeholder={form.placeholder}
                  className={twMerge(
                    `h-14 w-full border rounded-xl pl-3 pr-10 ${valueErreur[index] ? '' : 'input_error'}`
                  )}
                />
                <button
                  type="button"
                  onClick={() =>
                    setPasswordVisible({
                      ...passwordVisible,
                      [index]: !passwordVisible[index],
                    })
                  }
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-admin"
                >
                  {passwordVisible[index] ? (
                    <img
                      src={EyeSlash}
                      className="w-5"
                      alt="Masquer le mot de passe"
                    />
                  ) : (
                    <img
                      src={Eye}
                      className="w-5"
                      alt="Voir le mot de passe"
                    />
                  )}
                </button>
              </div>
              <p className="text-red-error">{valueErreur[index] ? '' : form.errorMsg}</p>
            </div>
          );
        }

        // Gestion des autres types d'input
        return (
          <div
            key={index}
            className={twMerge(
              `mt-5 w-full flex flex-col ${form.halfWidth ? 'md:w-[49%]' : ''} ${containerStyle || ''}`
            )}
          >
            <label
              htmlFor={form.valueName}
              className="text-sm font-bold text-blue-admin"
            >
              {form.label}
            </label>
            <PrimaryInput
              type={form.inputType}
              name={form.valueName}
              id={form.valueName}
              placeholder={form.placeholder}
              disabled={form.disabled}
              defaultValue={form.defaultValue}
              onChangeText={(e) => {
                if (form.onChange) {
                  onChange({ [form.valueName]: e });
                }
              }}
              styleInput={twMerge(
                `mt-3 ${form.style}  ${form.halfWidth === 'full' ? 'md:w-full' : ''} ${
                  form.halfWidth === '1/2-full' ? 'md:w-1/2' : ''
                } ${valueErreur[index] ? '' : 'input_error'}`
              )}
            />
            <p className="text-red-error">{valueErreur[index] ? '' : form.errorMsg}</p>
          </div>
        );
      })}
    </form>
  );

  //Render
  return formInput;
});

export default FormCustom;
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';

//import icons
import Check from '../../../assets/images/svg/check.svg';
import DatePicker_date from '../DatePicker_date';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const AddTraining = () => {
  // 🌱🌱  Init navigate  🌱🌱
  const navigate = useNavigate();

  // 🌱🌱  Init use Ref  🌱🌱
  const submitRef = useRef();

  // 🌱🌱 Init store(services)  🌱🌱
  const createTraining = useStoreActions((actions) => actions.middleware.TrainingMiddleware.createTraining);
  const getListCollaborators = useStoreActions(
    (actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators
  );

  // 🌱🌱  Etats (données)  🌱🌱
  const [collaborators, setCollaborators] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [expiration_date, setExpiration_date] = useState(new Date());
  const [msgErrorInput, setMsgErrorInput] = useState(false);

  const formAddTraining = [
    {
      label: 'Nom de la formation',
      inputType: 'text',
      valueName: 'name',
      placeholder: 'Formation',
      errorMsg: 'Veuillez entrer le nom de la formation',
      required: true,
      halfWidth: true,
    },
    {
      label: 'Attribution',
      inputType: 'select',
      valueName: 'userTrainingsId',
      options: collaborators,
      keyOptions: 'fullName',
      keyValues: 'id',
      halfWidth: true,
      required: true,
      style: 'max-w-[194px] flex',
      placeholder: '- Collaborateurs -',
      errorMsg: 'Veuillez sélectionner un collaborateur',
    },
  ];

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      callCreateTraining(value.values);
    }
  };

  //   🌱🌱  call create Training  🌱🌱
  const callCreateTraining = async (values) => {
    try {
      if (expiration_date < new Date()) {
        setMsgErrorInput(true);
        return;
      }
      const params = { ...values, expiration_date: expiration_date };
      const data = await createTraining(params);
      if (data) {
        setShowModal(true);
      }
    } catch (error) {
      toast.error("❗️ Une erreur est survenue lors de l'ajout de la formation");
    }
  };

  // 🌱🌱  Call get list collaborators  🌱🌱
  const callGetListCollaborators = async () => {
    try {
      const payload = {
        nameCollab: collaboratorName.toString(),
      };
      const data = await getListCollaborators(payload);
      console.log('data', data);

      data.sort((a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });

      setCollaborators(
        data.map((collab) => {
          return { ...collab, fullName: `${collab.first_name} ${collab.last_name}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  // 🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetListCollaborators();
  }, []);

  return (
    <section className=" h-full flex flex-col md:pt-14 overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Ajouter une nouvelle formation</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formAddTraining}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
        <div className="mt-4">
          <label>Date de validité</label>
          <DatePicker_date
            selected={expiration_date}
            onChange={setExpiration_date}
            onManual={true}
          />
          <p className="text-red-error">{msgErrorInput ? 'Veuillez ajouter une date de fin validité' : ''}</p>
        </div>
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer une nouvelle formation"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      {/* Modal Add Training */}
      <Modal
        title="Nouvelle formation enregistrée"
        text="Nous avons bien enregistré votre nouvelle formation. Vous le retrouverez dans la liste des formations et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddTraining;


import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationConstructionSite from '../shared/layout/ConstructionSiteNavigation/NavigationConstructionSite';

const ConstructionSites = () => {
  return (
    <div>
      <NavigationConstructionSite />
      <Outlet />
    </div>
  );
};

export default ConstructionSites;


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

// Import Components
import SecondaryButton from '../SecondaryButton';
import PrimaryInput from '../PrimaryInput';
import Table from '../Table';
import Modal from '../Modal_2';

// Import icons
import Search from '../../../assets/images/svg/search.svg';
import FolderIcon from '../../../assets/images/svg/folder.svg';
import EditIcon from '../../../assets/images/svg/edit-icon.svg';
import DeleteIcon from '../../../assets/images/svg/delete.svg';
import AttentionIcon from '../../../assets/images/svg/attention.svg';

//env value
const providerName = process.env.REACT_APP_PROVIDER_NAME;

const PrestatairesList = () => {
  //init navigation
  const navigate = useNavigate();

  //fun navigation
  const navigation = (id, state) => {
    navigate(`./worksite/${id}`, { state: state });
  };

  //  🌱🌱 init store(services)   🌱🌱
  const getListProviders = useStoreActions((actions) => actions.middleware.CompanyMiddleware.getlistCompanies);
  const deleteProvider = useStoreActions((actions) => actions.middleware.CompanyMiddleware.deleteCompany);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listProviders, setlistProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [providerSelected, setProviderSelected] = useState(null);
  const [textSearch, setTextSearch] = useState('');

  //  🌱🌱 Request call List Providers  🌱🌱
  const callListProviders = async () => {
    try {
      const res = await getListProviders(providerName);
      setlistProviders(res);
    } catch (error) {
      console.log('error', error);
      // toast.error('❌ Une erreur est survenue lors de la récupération de la liste des prestataires 🤯');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListProviders();
  }, []);

  //  🌱🌱  2de effect  for searchBar 🌱🌱
  useEffect(() => {
    if (textSearch === '') {
      setProviders(listProviders);
    } else {
      const tabFilter = listProviders.filter((element) =>
        element.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setProviders(tabFilter);
    }
  }, [textSearch, listProviders]);

  // fun click Provider
  const deleteAction = (provider) => {
    setShowModal(true);
    setProviderSelected(provider);
  };

  // fun delete Provider
  const callDeleteProvider = async () => {
    try {
      await deleteProvider(providerSelected.id);
      callListProviders();
      setShowModal(false);
    } catch (error) {
      console.log('error', error);
      toast.error('❌ Une erreur est survenue lors de la suppression du prestataire 🤯');
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <>
      <section className="px-4 md:pt-14 md:px-9">
        {/* div Title = button */}
        <div className="flex flex-col justify-between pb-4 mb-4 border-b  items center border-gray-admin pt-11 md:pt-0 md:flex-row">
          <h3 className="pb-5 text-xl font-bold md:pb-0">Liste des prestataires</h3>
          <SecondaryButton
            title=" + Ajouter un nouveau prestataire"
            link={'add'}
          />
        </div>
        {/* div Search bar */}
        <div className="relative ">
          <PrimaryInput
            styleInput={'pl-8 h-12 '}
            type="text"
            placeholder="Recherche prestataire"
            onChangeText={setTextSearch}
          />
          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3  top-4"
          />
        </div>
        {/* Table liste clients */}
        <div className="mt-9">
          <Table>
            <thead>
              <tr>
                <th>Nom du client</th>
                <th>N° de Siret</th>
                <th>Adresse de facturation</th>
                <th>Téléphone</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {providers.length ? (
                providers.map((provider) => (
                  <tr key={provider?.id}>
                    <td onClick={() => navigation(provider.id, provider)}>{provider?.name}</td>
                    <td>{provider?.siret}</td>
                    <td>{provider?.address}</td>
                    <td>{provider?.phone}</td>
                    <td className="flex items-center pt-4 space-x-4 justify-end">
                      <Link
                        to={`./worksite/${provider?.id}`}
                        state={provider}
                      >
                        <button className="w-8 h-8 p-2 border rounded-lg border-white-admin">
                          <img
                            src={FolderIcon}
                            alt="Worksite icon"
                          />
                        </button>
                      </Link>
                      <Link
                        to={`./edit/${provider?.id}`}
                        state={provider}
                      >
                        <button className="w-8 h-8 p-2 border rounded-lg border-white-admin">
                          <img
                            src={EditIcon}
                            alt="edit icon"
                          />
                        </button>
                      </Link>
                      <button
                        className="w-8 h-8 p-2 border rounded-lg border-white-admin"
                        onClick={() => deleteAction(provider)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete icon"
                        />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-lg text-center text-opacity-50"
                  >
                    {textSearch
                      ? "votre recherche n'a pas donné de résultats"
                      : 'La liste des prestataires est vide. Veuillez ajouter un prestataire.'}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du prestataire: {providerSelected?.name}</p>
            {providerSelected?.worksites?.items?.length ? (
              <>
                <p className="px-5 pt-2 text-gray-admin">
                  Merci de veiller à supprimer tous les chantiers de votre prestataire avant de le retirer de votre
                  liste
                </p>
                <div className="text-center pt-7">
                  <button
                    className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                    onClick={() => setShowModal(false)}
                  >
                    ok
                  </button>
                </div>
              </>
            ) : (
              <>
                <p className="px-5 pt-2 text-gray-admin">
                  Êtes-vous sûre de vouloir supprimer ce prestataire ? Cette action est définitive.
                </p>
                <div className="text-center pt-7">
                  <button
                    className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                    onClick={() => setShowModal(false)}
                  >
                    Annuler
                  </button>
                  <button
                    className="px-10 py-3 text-white bg-red-error rounded-xl"
                    onClick={callDeleteProvider}
                  >
                    Supprimer
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default PrestatairesList;


import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

//env value
const providerName = process.env.REACT_APP_PROVIDER_NAME;

const AddPrestataire = () => {
  //Global store
  const typeCompanies = useStoreState((state) => state.userData.typeCompanies);
  const providerId = typeCompanies.find((type) => type.name === providerName).id;

  //init navigation
  const navigate = useNavigate();

  //initState
  const [ctm, setCtm] = useState(false);

  // init store
  const createCompany = useStoreActions((actions) => actions.middleware.CompanyMiddleware.createCompany);
  const listCompaniesByName = useStoreActions((actions) => actions.middleware.CompanyMiddleware.listCompaniesByName);

  //Schema form
  const phoneRegExp = /^[0-9]{10}$|([+])([0-9]{11,12})$/;
  const siretRegExp = /^[0-9]{14}$|^(?!.*\S)/;
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    siret: yup.string(),
    address: yup.string().required('Ce champ est requis.'),
    mail: yup.string().email('Email au format: presataire@gmail.mq').required('Ce champ est requis.'),
    phone: yup.string(),
  });

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit fun
  const onSubmit = async (data) => {
    const newDatas = { ...data, id_type_Company: providerId, mail: [data.mail, 'test@live.fr'] };
    try {
      const existingCompany = await listCompaniesByName(data.name);
      if (existingCompany.length > 0) {
        toast.error('❗️ Ce prestataire existe déjà');
        return;
      }
      await createCompany(newDatas);
      navigate('/prestataires');
    } catch (error) {
      console.log('error', error);
      toast.error('❗️ Une erreur est survenue lors de la création du prestataire');
    }
  };
  return (
    <section className="flex flex-col h-screen">
      {/* Header */}
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Créer un nouveau prestataire</h3>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-grow"
      >
        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="name">Nom du prestataire</label>
          <input
            id="name"
            className={errors.name ? 'w-[318px] input_error' : 'w-[318px] input'}
            placeholder="Nom du prestataire"
            {...register('name')}
          />
          <p className="text-xs text-red-500">{errors.name?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="siret">Numéro de SIRET</label>
          <input
            id="siret"
            className={errors.siret ? 'w-[318px] input_error' : 'w-[318px] input'}
            placeholder="N° XXX XXX XXXXX XXX"
            {...register('siret')}
          />
          <p className="text-xs text-red-500">{errors.siret?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="address">Adresse postale</label>
          <input
            id="address"
            className={errors.address ? ' w-[318px] input_error' : 'w-[318px] input'}
            placeholder="22 rue du général de Gaulle 97236 Lamentin"
            {...register('address')}
          />
          <p className="text-xs text-red-500">{errors.address?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="mail">Adresse mail de facturation</label>
          <input
            id="mail"
            className={errors.mail ? ' w-[318px] input_error' : 'w-[318px] input'}
            placeholder="prestataire@exemple.mq"
            {...register('mail')}
          />
          <p className="text-xs text-red-500">{errors.mail?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="phone">Téléphone</label>
          <input
            id="phone"
            className={errors.phone ? 'w-[318px] input_error' : 'w-[318px] input'}
            placeholder="Téléphone"
            {...register('phone')}
          />
          <p className="text-xs text-red-500">{errors.phone?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label>Type de prestataire</label>
          <input
            type="radio"
            className="w-4 h-4 checkedFocus"
            id="normal"
            {...register('typeClient')}
            value="normal"
            checked={!ctm}
            onChange={() => setCtm(false)}
          />
          <label htmlFor="normal">Normal</label>
          <input
            type="radio"
            className="w-4 h-4 checkedFocus"
            id="ctm"
            {...register('typeClient')}
            value="CTM"
            checked={ctm}
            onChange={() => setCtm(true)}
          />
          <label htmlFor="ctm">CTM</label>
        </div>

        <div className="mt-auto py-4 flex justify-between items-center border-t border-white-admin px-14">
          <Link
            to="/prestataires"
            className="border rounded-lg cursor-pointer border-gray-admin"
          >
            <button className="px-5 py-3 text-blue-admin">Annuler</button>
          </Link>
          <div className="rounded-lg cursor-pointer bg-yellow-admin">
            <button
              type="submit"
              className="px-5 py-3 font-semibold text-blue-admin"
            >
              Créer un nouveau prestataire
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default AddPrestataire;


import { thunk } from 'easy-peasy';
import { Amplify, Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { awsAuthAdmin, awsAuthCollaborator } from './config/awsCognito';
import awsIAMConfigure from './config/awsIAMConfigure';

const middleware = {
  //✨✨===========================================✨✨
  //            CONNEXTION USER AWS COGNITO ADMIN
  //✨✨===========================================✨✨
  loginAws: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthAdmin);
    try {
      const user = await Auth.signIn({
        username: payload?.mail,
        password: payload?.password,
      });
      getStoreActions().userData.setCognitoData(user);
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨===========================================✨✨
  //            VERIF IS AUTH COGNITO ADMIN
  //✨✨===========================================✨✨
  isAuth: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    Amplify.configure(awsAuthAdmin);
    try {
      const user = await Auth.currentAuthenticatedUser();
      getStoreActions().userData.setCognitoData(user);
      return user;
    } catch (error) {
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            CREATE USER AWS COGNITO ADMIN
  //✨✨=============================================✨✨
  createAdminUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthAdmin);
    try {
      const user = await Auth.signUp({
        username: payload?.mail,
        password: payload?.password,
      });
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            LOGOUT USER COGNITO ADMIN
  //✨✨=============================================✨✨
  logOutAdminUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    Amplify.configure(awsAuthAdmin);
    try {
      await Auth.signOut();
      getStoreActions().route.setConnected(false);
    } catch (error) {
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            SET PASSWORD AWS SDK ADMIN
  //✨✨=============================================✨✨
  setAdminPassword: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Password: payload?.password,
      Permanent: true,
      Username: payload?.id_cognito,
      UserPoolId: awsAuthAdmin.userPoolId,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminSetUserPassword(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            SET EMAIL AWS SDK ADMIN
  //✨✨=============================================✨✨
  setAdminEmail: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'email',
          Value: payload?.mail,
        },
        {
          Name: 'email_verified',
          Value: 'true', // La valeur doit être une chaîne, pas un booléen
        },
      ],
      Username: payload?.id_cognito,
      UserPoolId: awsAuthAdmin.userPoolId,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            DELETE USER AWS SDK ADMIN COGNITO
  //✨✨=============================================✨✨
  deleteAdminUserCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Username: payload,
      UserPoolId: awsAuthAdmin.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminDeleteUser(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            CREATE USER AWS COGNITO COLLABORATOR
  //✨✨=============================================✨✨
  createCollaboratorUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    getStoreActions().global.setActivity(true);
    Amplify.configure(awsAuthCollaborator);
    try {
      const user = await Auth.signUp({
        username: payload.id_cognito,
        password: payload?.password,
      });
      return user;
    } catch (error) {
      getStoreActions().global.setActivity(false);
      throw error;
    }
  }),

  //✨✨=============================================✨✨
  //            SET PASSWORD AWS SDK COLLABORARTOR
  //✨✨=============================================✨✨
  setCollaboratorPassword: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Password: payload?.password,
      Permanent: true,
      Username: payload?.id_cognito,
      UserPoolId: awsAuthCollaborator.userPoolId,
    };

    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminSetUserPassword(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            SET USERNAME AWS SDK COLLABORATOR
  //✨✨=============================================✨✨
  setCollaboratorUsername: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      UserAttributes: [
        {
          Name: 'preferred_username',
          Value: payload.fullName,
        },
      ],
      UserPoolId: awsAuthCollaborator.userPoolId,
      Username: payload.id_cognito,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminUpdateUserAttributes(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),

  //✨✨=============================================✨✨
  //            SET PHONE NUMBER AWS SDK COLLABORATOR
  //✨✨=============================================✨✨
  setCollaboratorPhoneNumber: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // Vérification du format du numéro de téléphone (E.164)
    if (!/^\+\d{1,15}$/.test(payload?.phone)) {
      throw new Error('Le numéro de téléphone doit être au format E.164 (ex: +33612345678).');
    }

    const params = {
      UserAttributes: [
        { Name: 'phone_number', Value: payload?.phone },
        { Name: 'phone_number_verified', Value: 'true' }, // Valeur correcte en chaîne
      ],
      Username: payload?.id_cognito,
      UserPoolId: awsAuthCollaborator.userPoolId,
    };

    try {
      getStoreActions().global.setActivity(true);

      // Appel AWS SDK avec gestion des promesses
      await awsCognito.adminUpdateUserAttributes(params).promise();

      return { success: true, message: 'Numéro de téléphone mis à jour avec succès.' };
    } catch (error) {
      console.error('Erreur AWS Cognito :', error);
      throw new Error('Impossible de mettre à jour le numéro de téléphone.');
    } finally {
      // Désactive toujours l'indicateur d'activité
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨=============================================✨✨
  //            DELETE USER AWS SDK COLLABORATOR COGNITO
  //✨✨=============================================✨✨
  deleteCollaboratorUserCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    //config key
    const awsCognito = new AWS.CognitoIdentityServiceProvider(awsIAMConfigure);

    // props func Aws
    const data = {
      Username: payload,
      UserPoolId: awsAuthCollaborator.userPoolId,
    };
    getStoreActions().global.setActivity(true);
    // exe aws function
    const myPromesse = new Promise((resolve, reject) => {
      awsCognito.adminDeleteUser(data, (err) => {
        if (err) {
          getStoreActions().global.setActivity(false);
          reject(err);
        } else {
          resolve(data);
        }
      });
    });

    return myPromesse;
  }),
};

export default middleware;


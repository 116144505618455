import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../Modal';

//import icons
import Check from '../../../assets/images/svg/check.svg';

const AddAnomalie = () => {
  //  🌱🌱 Init store(services)  🌱🌱
  const getListTrucks = useStoreActions((actions) => actions.middleware.TruckMiddleware.getListTruck);
  const createProblem = useStoreActions((actions) => actions.middleware.ProblemsMiddleware.createProblem);

  //  🌱🌱  Etats (données)  🌱🌱
  const [trucks, setTrucks] = useState([]);
  const [showModal, setShowModal] = useState(false);

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱 Init use Ref  🌱🌱
  const submitRef = useRef();

  const optionStatus = [{status: "En cours"}, {status:"Anomalie non réglée"}, {status:"Anomalie réglée"} ]

  const formProblem = [
    {
      label: 'Description',
      inputType: 'textarea',
      valueName: 'description',
      placeholder: 'Desciption de l\'anomalie',
      errorMsg: 'Veuillez rentrer la Desciption de l\'anomalie',
      required: true,
      halfWidth: true,
    },
    {
      label: 'Status',
      inputType: 'select',
      valueName: 'status',
      placeholder: 'Status',
      options: optionStatus,
      keyOptions: 'status',
      keyValues: 'status',
      errorMsg: "Veuillez entrer un status",
      required: true,
      halfWidth: true,
    },
    {
      label: 'Véhicule',
      inputType: 'select',
      valueName: 'problemTruckId',
      options: trucks,
      keyOptions: 'registration',
      keyValues: 'id',
      halfWidth: true,
      required: true,
      placeholder: '- Plaque véhicule -',
      errorMsg: 'Veuillez Selectionner un véhicule',
    },
  ];

  const callListTrucks = async () => {
    try {
      const res = await getListTrucks();
      setTrucks(res);
    } catch (error) {
      console.log(error)
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des véhicules');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListTrucks();
  }, []);

  //  🌱🌱 Request create Problem  🌱🌱
  const callCreateProblem = async (data) => {
    try {
      const res = await createProblem(data);
      console.log(res)
      setShowModal(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau véhicule, veuillez réessayer.");
    }
  };


 

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        date: new Date(),
      };
      try {
        callCreateProblem(newData);
      } catch (error) {
        toast.error("❗️Une erreur est survenue durant la création d'un nouveau véhicule, veuillez réessayer.");
      }
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full overflow-y-auto md:pt-14">
      {/* div title */}
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-11 md:pt-0 md:mx-9">
        <h3 className="text-xl font-bold ">Ajouter une nouvelle anomalie</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formProblem}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <PrimaryButton
          title="Créer une nouvelle anomalie"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      {/* Modal Add Truck */}
      <Modal
        title="Nouvelle anomalie enregistrée"
        text="Nous avons bien enregistré votre nouvelle anomalie. Vous le retrouverez dans la liste des nouvelle anomalies et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddAnomalie;


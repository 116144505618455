import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import icons
import PDF from '../../../assets/images/svg/pdf.svg';
import editPdf from '../../../assets/images/png/edit_coupon.png';

// Import tools
import { dateFormat, sorted } from '../../tools/manager';

const WorkOrder = () => {
  // Etats (données)
  const [listCouponIntervention, setListCouponIntervention] = useState([]);
  const [listPreCouponIntervention, setListPreCouponIntervention] = useState([]);

  // Navigation
  const currentWorkSite = useLocation().state;

  //  🌱🌱 init store(services)   🌱🌱
  const listPreCouponByWorksite = useStoreActions(
    (actions) => actions.middleware.CouponPreInterMiddleware.listPreCouponByWorksite
  );
  const getWorkSiteListInterventionCoupon = useStoreActions(
    (actions) => actions.middleware.WorksiteMiddleware.getWorkSiteListInterventionCoupon
  );

  // call list bon pre-rempli
  const CallPreInterCoupon = async () => {
    try {
      const result = await listPreCouponByWorksite(currentWorkSite?.id);
      setListPreCouponIntervention([
        ...result.filter((el) => !el.dateSigned),
        ...result.filter((el) => !!el.dateSigned),
      ]);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ #1 Une erreur est survenue durant le chargement des bons');
    }
  };

  const callWorksitesListInterventionCoupon = async () => {
    try {
      const res = await getWorkSiteListInterventionCoupon(currentWorkSite?.id);
      setListCouponIntervention(res.filter((coupon) => !coupon.ctm));
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ #2 Une erreur est survenue durant le chargement des bons');
    }
  };

  useEffect(() => {
    CallPreInterCoupon();
    callWorksitesListInterventionCoupon();
  }, []);

  return (
    <section className="mx-14 mt-14 mb-5">
      <h4 className="font-semibold">Bons pré-remplis</h4> 
      <div className="flex mt-5 space-x-5">
        <ul className="flex flex-row flex-wrap gap-5 items-center mt-5 max-h-[400px] overflow-auto">
          <Link
            to="add"
            state={currentWorkSite}
          >
            <div className="w-[200px] flex flex-col items-center justify-center border border-gray-separate py-11 rounded-xl hover:bg-blue-50 transition-all">
              <div className="mb-5">
                <p className="px-2 border border-gray-separate rounded-lg">+</p>
              </div>
              <p className=" text-blue-admin text-center">Pré-remplir un bon</p>
            </div>
          </Link>
          {listPreCouponIntervention?.map((preCoupon) => {
            return (
              <Link
                to={preCoupon?.dateSigned ? `pre/${preCoupon.id}` : `edit/${preCoupon.id}`}
                key={preCoupon.id}
                state={currentWorkSite}
              >
                <li className="w-[181px] flex flex-col items-center border border-gray-separate  pt-7 pb-5 rounded-xl hover:bg-blue-50 transition-all">
                  <div className="mb-5">
                    <img
                      src={preCoupon?.dateSigned ? PDF : editPdf}
                      alt="pdf svg"
                      className="w-12 h-14"
                    />
                  </div>
                  <h5 className="text-blue-admin font-bold">N° {preCoupon.numero}</h5>
                  <p className="text-sm text-blue-admin">
                    {preCoupon?.dateSigned ? `Fait le : ${dateFormat(preCoupon?.dateSigned).full}` : 'En cours'}
                  </p>
                </li>
              </Link>
            );
          })}
        </ul>
      </div>
      <div className="border-t border-gray-separate mt-10 pt-7">
        <h4 className="font-semibold">Bons collaborateurs</h4>
        <ul className="flex flex-row flex-wrap gap-5 items-center mt-5 max-h-[400px] overflow-auto">
          {listCouponIntervention.length ? (
            listCouponIntervention
              ?.sort((a, b) => b.numero - a.numero)
              .map((couponIntervention) => {
                return (
                  <Link
                    key={couponIntervention.id}
                    to={`${couponIntervention.id}`}
                    state={currentWorkSite}
                  >
                    <li className="w-[200px] flex flex-col items-center border border-gray-separate pt-7 pb-5 rounded-xl hover:bg-blue-50 transition-all">
                      <div className="mb-5">
                        <img
                          src={PDF}
                          alt="pdf svg"
                        />
                      </div>
                      <h5 className="text-blue-admin font-bold">N° {couponIntervention.numero}</h5>
                      <p className="text-sm text-blue-admin">Fait : {couponIntervention.name}</p>
                    </li>
                  </Link>
                );
              })
          ) : (
            <div className="mx-auto my-20">
              <p>Aucun bon d'intervention n’a été enregistré pour l’instant</p>
            </div>
          )}
        </ul>
      </div>
    </section>
  );
};

export default WorkOrder;

import React, { useEffect, useState } from 'react';
import TrainingsList from '../shared/components/trainings/TrainingsList';

const Trainings = () => {
  return (
    <div>
      <TrainingsList />
    </div>
  );
};

export default Trainings;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const ContraventionMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST CONTRAVENTION
  //✨✨==========================================✨✨
  getListContraventions: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listContraventions(filter: {truckContraventionId: {eq: "${payload}"}}) {
        items {
          id
          document
          date
          status
          truckContraventionId
          driver {
            firstName
            id
            lastName
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listContraventions.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET CONTRAVENTION BY ID
  //✨✨==========================================✨✨
  getContravention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getContravention(id: "${payload}") {
        id
        date
        document
        status
        truckContraventionId
        driver {
          firstName
          lastName
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getContravention;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE CONTRAVENTION
  //✨✨==========================================✨✨
  createContravention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createContravention(input: {
        date: "${payload?.date}", 
        document: "${payload?.document}", 
        status: "${payload?.status}",
        truckContraventionId: "${payload?.idTruck}"
        driverContraventionId: "${payload?.idDriver}"
      }) 
      {
        id
      }
    }
    
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createContravention;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE CONTRAVENTION
  //✨✨==========================================✨✨
  updateContravention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateContravention(input: {
        id: "${payload.id}", 
        status: "${payload.status}",
        date: "${payload.date}",
        ${payload.document ? `document: "${payload.document}"` : ''}
        driverContraventionId: "${payload.idDriver}"
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateContravention;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE CONTRAVENTION
  //✨✨==========================================✨✨
  deleteContravention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteContravention(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteContravention;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default ContraventionMiddleware;


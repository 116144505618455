import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const WorksiteMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST WORKSITE
  //✨✨==========================================✨✨
  getlistWorkSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getCompany(id: "${payload}") {
        id
        name
        worksites {
          items {
            users {
              items {
                id
                user {
                  id
                  first_name
                  last_name
                }
              }
            }
            collaborators {
              items {
                user {
                  id
                  first_name
                  last_name
                  phone
                }
              }
            }
            address
            date_planned
            id
            type {
              name
              id
            }
            name
            updatedAt
            status
            audioUrl
            
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('DATA', query);

      const myData = query.data?.getCompany.worksites.items;
      console.log('DATA', myData);

      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST WORKSITE
  //✨✨==========================================✨✨
  listWorkSites: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listWorksites(limit: 500000) {
        items {
          id
          date_planned
          name
          status
          audioUrl
          
          company {
            id
            name
          }
          collaborators {
            items {
              user {
                id
                first_name
                last_name
                phone
              }
            }
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('DATA', query);

      const myData = query.data?.listWorksites.items;
      console.log('DATA', myData);

      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST WORKSITE BY NAME
  //✨✨==========================================✨✨
  listWorksitesByName: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listWorksites(filter: {name: {eq: "${payload}"}} limit: 500000) {
        items {
          id
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listWorksites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE STATUS WORKSITE
  //✨✨==========================================✨✨
  updateStatusWorkSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateWorksite(input: {id: "${payload.id}", status: "${payload.status}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateWorksite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE DATE WORKSITE
  //✨✨==========================================✨✨
  updateDateWorkSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateWorksite(input: {id: "${payload.id}", date_planned: "${payload.date}",}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateWorksite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE AUDIO WORKSITE
  //✨✨==========================================✨✨
  updateAudioWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateWorksite(input: {id: "${payload.id}", audioUrl: "${payload.audioUrl}",}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateWorksite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE WORKSITE
  //✨✨==========================================✨✨
  createWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createWorksite(input: {
        name: "${payload.name}",
        address: "${payload.address}",
        date_planned: "${payload.date}",
        typeWorksitesId: "${payload.typeClient}",
        companyWorksitesId: "${payload.id_customer}",
        status: "En cours",
      })
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createWorksite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE WORKSITE
  //✨✨==========================================✨✨
  updateWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateWorksite(input: {
        id: "${payload.id}", 
        name: "${payload.name}", 
        address: "${payload.address}", 
        date_planned: "${payload.date}", 
        typeWorksitesId: "${payload.typeClient}",
      })
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateWorksite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           CREATE WORKSITE USERS
  //✨✨==========================================✨✨
  createWorksiteUsers: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createWorksiteUsers(input: {userID: "${payload.id_user}", worksiteID: "${payload.id_worksite}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createWorksiteUsers;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           CREATE WORKSITE COLLABORATOR
  //✨✨==========================================✨✨
  createWorksiteCollaborators: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createWorksiteCollaborators(input: {userID: "${payload.id_user}", worksiteID: "${payload.id_worksite}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createWorksiteCollaborators;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           GET WORKSITE LIST ORDER COUPON
  //✨✨==========================================✨✨
  getWorkSiteListOrderCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getWorksite(id: "${payload}") {
        order_coupon {
          items {
            id
            createdAt
            name
            numero
            updatedAt
            ctm
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getWorksite?.order_coupon?.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //      GET WORKSITE LIST INTERVENTION COUPON
  //✨✨==========================================✨✨
  getWorkSiteListInterventionCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getWorksite(id: "${payload}") {
        intervention_coupon {
          items {
            createdAt
            id
            name
            numero
            ctm
            updatedAt
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getWorksite.intervention_coupon.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //    GET LIST WORKSITE USERS
  //✨✨==========================================✨✨
  getlistWorksiteUsers: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listWorksiteUsers(filter: {worksiteID: {eq: "${payload}"}} limit: 500000) {
        items {
          id
          
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listWorksiteUsers.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //    GET LIST WORKSITE COLLABORATORS
  //✨✨==========================================✨✨
  getlistWorksiteCollaborators: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listWorksiteCollaborators(filter: {worksiteID: {eq: "${payload}"}} limit: 500000) {
        items {
          id
          user {
            id
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listWorksiteCollaborators.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //    DELETE MULTIPLE WORKSITE USERS
  //✨✨==========================================✨✨
  deleteMultipleWorksiteUsers: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      ${payload.map(
        (WorksiteUser, index) => `
        task${index}: deleteWorksiteUsers(input: {id: "${WorksiteUser.id}"}) {
          id
        }
      `
      )}
    } `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteWorksiteUsers;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //    DELETE MULTIPLE WORKSITE COLLABORATORS
  //✨✨==========================================✨✨
  deleteMultipleWorksiteCollaborators: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      ${payload.map(
        (WorksiteCollaborator, index) => `
        task${index}: deleteWorksiteCollaborators(input: {id: "${WorksiteCollaborator}"}) {
          id
        }
      `
      )}
    } `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteWorksiteCollaborators;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           DELETE WORKSITE USERS
  //✨✨==========================================✨✨
  deleteWorksiteUsers: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteWorksiteUsers(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteWorksiteUsers;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //           DELETE WORKSITE COLLABORATOR
  //✨✨==========================================✨✨
  deleteWorksiteCollaborators: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteWorksiteCollaborators(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteWorksiteCollaborators;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE WORKSITE
  //✨✨==========================================✨✨
  deleteWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteWorksite(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteWorksite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default WorksiteMiddleware;


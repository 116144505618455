import { useState } from 'react';
import ArrowDownIcon from '../../assets/images/svg/down-arrow.svg'; // Assurez-vous que le chemin est correct

const CustomSelect = ({ options, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState('');

  // Fonction pour afficher ou masquer la liste déroulante
  const toggleDropdown = () => setIsOpen(!isOpen);

  // Gérer la sélection d'une option
  const handleSelect = (value, fullName) => {
    setSelected(fullName); // Utiliser fullName pour afficher le nom
    setIsOpen(false);
    onChange(value); // Passez la valeur au parent
  };

  // Option pour réinitialiser le filtre
  const handleClearFilter = () => {
    setSelected('');
    setIsOpen(false);
    onChange(''); // Passez une valeur vide pour réinitialiser
  };

  return (
    <div className="relative">
      <div
        className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown p-3 mb-4 rounded-lg border-none hover:outline-none hover:border-blue-admin hover:ring-1 hover:ring-blue-admin w-fit flex items-center justify-between"
        onClick={toggleDropdown}
      >
        <span>{selected || placeholder}</span>
        <img
          src={ArrowDownIcon}
          alt="arrow down"
          className="ml-2 w-4 h-4"
        />
      </div>
      {isOpen && (
        <div className="absolute mt-1 bg-blue-dropdown rounded-lg shadow-md">
          {/* Option pour réinitialiser le filtre */}
          <div
            className="cursor-pointer text-blue-admin py-2 pl-5 pr-10"
            onClick={handleClearFilter}
          >
            Aucun filtre
          </div>
          {options.map((option) => (
            <div
              key={option.id}
              className="cursor-pointer text-blue-admin py-2 pl-5 pr-10"
              onClick={() => handleSelect(option.id, option.fullName)} // Utiliser fullName pour l'affichage
            >
              {option.fullName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;


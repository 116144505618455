import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

// Import Components
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import Search from '../../../assets/images/svg/search.svg';

// format date
import { dateFormat, sorted } from '../../tools/manager';

const AllCtmCouponsInterventions = () => {
  const navigation = useNavigate();

    // init ref
    const refIntereventionlist = useRef([]);

  //  🌱🌱 init store(services)   🌱🌱
  const getListInterventionCtmOrders = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.getListInterventionCtmOrders
  );

  //Etats (données)
  const [interventionCtmCoupons, setInterventionCtmCoupons] = useState([]);
  const [listInterventionCtmCoupons, setListInterventionCtmCoupons] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setListInterventionCtmCoupons(interventionCtmCoupons);
    } else {
      const tabFilter = interventionCtmCoupons.filter(
        (element) =>
          element?.worksite?.company?.name.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.worksite?.name?.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.numero.includes(textSearch)
      );
      setListInterventionCtmCoupons(tabFilter);
    }

    
  }, [interventionCtmCoupons, textSearch]);

  //  🌱🌱 Request call List Intervention Ctm Coupons  🌱🌱
  const callListInterventionCtmOrders = async () => {
    try {
      const res = await getListInterventionCtmOrders();
      console.log('DATA', res);
      const myList = sorted(res, 'numero', true);
      setInterventionCtmCoupons(myList);
      refIntereventionlist.current = myList;
    } catch (error) {
      toast.error("❗️ Une erreur est survenue lors du chargement de la liste des bons d'intervention CTM");
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListInterventionCtmOrders();
  }, []);

  return (
    <section className="md:pt-8 px-4 md:px-9">
      {/* div search bar */}
      <div className="relative flex mt-6 border border-gray-admin rounded-xl">
        <input
          type="text"
          placeholder="Recherche bons"
          className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
          onInput={(e) => setTextSearch(e.target.value)}
        />

        <img
          src={Search}
          alt="search icon"
          className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
        />
      </div>
      {/* Table liste intervention ctm coupon */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Réalisé le :</th>
              <th>Numéro </th>
              <th>Type de chantier</th>
              <th>Nom du client</th>
              <th className="flex justify-end">Nom du chantier</th>
            </tr>
          </thead>
          <tbody>
            {listInterventionCtmCoupons?.length ? (
              listInterventionCtmCoupons
                ?.sort((a, b) => b.numero - a.numero)
                ?.map((interventionCoupon) => {
                  const dateCouponCreate = dateFormat(interventionCoupon?.dateSigned, '.').full;
                  return (
                    <tr
                      key={interventionCoupon.id}
                      className={`h-16 border-b border-white-admin hover:bg-blue-50 transition-all`}
                      onClick={() => {
                        navigation(`/pdf-preview/Pre-intervention/${interventionCoupon.id}`);
                      }}
                    >
                      <td>{dateCouponCreate}</td>
                      <td>{interventionCoupon?.numero}</td>
                      <td>{interventionCoupon?.worksites?.type?.name}</td>
                      <td>{interventionCoupon?.worksites?.company?.name}</td>
                      <td className="flex justify-end mt-5">{interventionCoupon?.worksites?.name}</td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td
                  className="text-center text-lg font-semibold pt-20"
                  colSpan={6}
                >
                  {textSearch ? "Oups, ce chantier ou client n'existe pas 🤭" : 'Ajouter un bon d\'intervention'}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default AllCtmCouponsInterventions;


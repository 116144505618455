import React from 'react';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

const EditTypeInterventionSettings = () => {
  // Navigation
  const currentTypeIntervention = useLocation().state;
  const navigate = useNavigate();

  // Etats (données)
  const [typeIntervention, setTypeIntervention] = useState(currentTypeIntervention.name);

  //  🌱🌱 init store(services)   🌱🌱
  const updateTypeIntervention = useStoreActions(
    (actions) => actions.middleware.SettingsMiddleware.updateTypeIntervention
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await updateTypeIntervention({
        id: currentTypeIntervention.id,
        typeIntervention: typeIntervention,
      });
      console.log('DATA', res);
      if (res) {
        navigate(-1);
      }
    } catch (error) {
      console.log('error', error);
      toast.error("❗️ Une erreur est survenue lors de la création de la nature d'intervention");
    }
  };
  return (
    <section className="flex flex-col h-screen">
      {/* Header */}
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Modifier la nature de l'intervention</h3>
      </div>

      {/* Form */}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col flex-grow"
      >
        <div className="flex mx-14 space-x-4 items-center py-5">
          <label htmlFor="name">Nature de l'intervention</label>
          <input
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            type="text"
            placeholder="Nom du matériel"
            onChange={(e) => setTypeIntervention(e.target.value)}
            value={typeIntervention}
          />
        </div>

        {/* Footer Buttons */}
        <div className="mt-auto py-4 flex justify-between items-center border-t border-white-admin px-14">
          <Link
            to="/settings/purchase-order"
            className="cursor-pointer border border-gray-admin rounded-lg"
          >
            <button className="py-3 px-5 text-blue-admin">Annuler</button>
          </Link>
          <div className="cursor-pointer bg-yellow-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-blue-admin"
            >
              Enregistrer les Modifications
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditTypeInterventionSettings;

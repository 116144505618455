import React from 'react';
import { useStoreState } from 'easy-peasy';
import './css/activity.css';

const Activity = () => {
  //init service
  const activity = useStoreState((state) => state.global.activity);

  //Render
  return (
    <>
      {activity ? (
        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0">
          <div className="loader">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Activity;

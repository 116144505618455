import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const CollaboratorMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST COLLABORATORS
  //✨✨==========================================✨✨

  getListCollaborators: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listRoleUsers(filter: {name: {contains: "${payload.nameCollab}"}} limit: 500000) {
        items {
          name
          id
          users {
            items {
              first_name
              id
              last_name
              mail
              phone
              role_user {
                name
                id
              }
              type {
                id
                name
              }
              isDriver
              docPermis
              datePermis
              docVm
              dateVm
            }
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('DATA', query);

      const myData = query.data?.listRoleUsers.items[0].users.items;
      console.log('DATA', myData);

      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET COLLABORATOR
  //✨✨==========================================✨✨
  getCollaborator: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getUser(id: "${payload}") {
        first_name
        id
        last_name
        phone
        mail
        role_user {
          name
          id
        }
        type {
          id
          name
        }
        isDriver
        docPermis
        datePermis
        docVm
        dateVm
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE COLLABORATOR
  //✨✨==========================================✨✨
  createCollaborator: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createUser(
        input: {
          id: "${payload.id_cognito}",
          first_name: "${payload.firstName}",
          roleUserUsersId: "${payload.id_role_user}",
          typeUsersId: "${payload.id_type}",
          last_name: "${payload.lastName}", 
          phone: "${payload.phone}",
          isDriver: ${payload.isDriver},
          ${payload?.docVm ? `docVm: "${payload?.docVm}",` : ``}
          ${payload?.dateVm ? `dateVm: "${payload?.dateVm}",` : ``}
        })
        {
          id
          first_name
          phone
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE COLLABORATOR
  //✨✨==========================================✨✨
  updateCollaborator: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateUser(input: {
          first_name: "${payload.firstName}", 
          id: "${payload.id}",
          last_name: "${payload.lastName}",
          phone:"${payload.phone}",
          typeUsersId: "${payload.id_type}",
          isDriver: ${payload.isDriver},
          ${payload?.docVm ? `docVm: "${payload?.docVm}",` : ``}
          ${payload?.dateVm ? `dateVm: "${payload?.dateVm}",` : ``}
        })
      {
        id
        last_name
        first_name
        phone
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //     UPDATE COLLABORATOR DRIVER DOCUMENTS
  //✨✨==========================================✨✨
  updateDriverCollaborator: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateUser(input: {
          id: "${payload.id}",
          ${payload?.docPermis ? `docPermis: "${payload?.docPermis}",` : ``}
          ${payload?.docPermis ? `datePermis: "${payload?.datePermis}",` : ``}
        })
      {
        id
        last_name
        first_name
        phone
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE COLLABORATOR (UPDATE ARCHIVED)
  //✨✨==========================================✨✨
  deleteCollaborator: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteUser(input: {id: "${payload.id}"}) 
      {
        id
        first_name
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default CollaboratorMiddleware;


import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import Modal from 'react-modal';

// react-router-dom
import { BrowserRouter } from 'react-router-dom';

// easy-peasy
import { StoreProvider } from 'easy-peasy';

// services (store)
import services from './services';

// Configuration pour React Modal
Modal.setAppElement('#root');

// Initialisation de l'application React
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <StoreProvider store={services}>
      <App />
    </StoreProvider>
  </BrowserRouter>
);

// Enregistrement du Service Worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {
      console.log('Service Worker registered:', registration);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const TrainingMiddleware = {
  //✨✨==========================================✨✨
  //            LIST TRAININGS
  //✨✨==========================================✨✨
  listTrainings: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listTrainings {
            items {
            collaborator {
                id
                first_name
                last_name
            }
            id
            name
            expiration_date
            }
        }
    }

    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('DATA', query);

      const myData = query.data?.listTrainings.items;
      console.log('DATA', myData);

      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST TRAININGS BY COLLABORATOR
  //✨✨==========================================✨✨
  listTrainingsByCollaborator: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listTrainings(filter: {userTrainingsId: {eq: "${payload}"}}) {
            items {
            collaborator {
                id
                first_name
                last_name
            }
            id
            name
            expiration_date
            }
        }
    }

    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('DATA', query);

      const myData = query.data?.listTrainings.items;
      console.log('DATA', myData);

      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET TRAINING
  //✨✨==========================================✨✨
  getTraining: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        getTraining(id: "${payload}") {
            id
            name
            expiration_date
            collaborator {
            id
            first_name
            last_name
            }
        }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getTraining;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE TRAINING
  //✨✨==========================================✨✨
  updateTraining: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateTraining(input: {id: "${payload.id}", expiration_date: "${payload.expiration_date}", name: "${payload.name}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateTraining;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE TRAINING
  //✨✨==========================================✨✨
  createTraining: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createTraining(input: {
        name: "${payload.name}",
        expiration_date: "${payload.expiration_date}",
        userTrainingsId: "${payload.userTrainingsId}"
      })
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createTraining;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE TRAINING
  //✨✨==========================================✨✨
  deleteTraining: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteTraining(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteTraining;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default TrainingMiddleware;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const DocumentMiddleware = {
  //✨✨==========================================✨✨
  //            CREATE DOCUMENT
  //✨✨==========================================✨✨
  createDocument: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        createDocument(input: {
          amountHT: 1.5, 
          amountTTC: 1.5, 
          billingAddress: "", 
          clientName: "", 
          documentNumber: "", 
          createdAt: "false",
          dueDate: "false", 
          importBatchID: "", 
          projectName: "", 
          status: EN_ATTENTE, 
          type: DEVIS
          }) {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createDocument;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST DOCUMENTS
  //✨✨==========================================✨✨
  listDocuments: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listDocuments(limit: 500000) {
          items {
            amountHT
            amountTTC
            billingAddress
            clientName
            documentNumber
            id
            importBatchID
            projectName
            status
            type
            date
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listDocuments.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default DocumentMiddleware;


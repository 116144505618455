import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const CouponPreInterMiddleware = {
  //✨✨==========================================✨✨
  //            GET PRE COUPON
  //✨✨==========================================✨✨
  getPreCoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getCouponPreInter(id: "${payload}") {
        id
        ctm
        createdAt
        materials
        numero
        signed
        dateSigned
        orderCoupon
        newMailClient
        worksites {
          address
          name
          date_planned
          company {
              name
              mail
              phone
              siret
              address
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getCouponPreInter;
      return { ...myData, materials: JSON.parse(myData?.materials) };
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          LIST PRE COUPON BY WORKSITE
  //✨✨==========================================✨✨
  listPreCouponByWorksite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listCouponPreInters(filter: {couponPreInterWorksitesId: {eq: "${payload}"}}) {
        items {
          id
          numero
          materials
          dateSigned
          worksites {
            date_planned
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listCouponPreInters?.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          LIST PRE COUPON DONE
  //✨✨==========================================✨✨
  listPreCouponDone: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listCouponPreInters(filter: {dateSigned: {attributeExists: true}}) {
        items {
          id
          numero
          dateSigned
          worksites {
            name
            type {
              name
            }
            company {
              name
            }
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listCouponPreInters.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          LAST NUMBER PRE INTERVENTION
  //✨✨==========================================✨✨
  lastNumberPreInterv: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listCouponPreInters {
        items {
          numero
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listCouponPreInters.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          CREATE PRE INTERVENTION COUPON
  //✨✨==========================================✨✨
  createPreInterv: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createCouponPreInter(input: {
        ctm: "${payload?.ctm}", 
        numero: "${payload?.numero}",
        couponPreInterWorksitesId: "${payload?.couponPreInterWorksitesId}",
        materials: "${JSON.stringify(payload?.materials).replace(/\"/g, '\\"')}",
      }) 
      {
          id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createCouponPreInter;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          UPDATE LIST MATERIAL
  //✨✨==========================================✨✨
  updateListMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateCouponPreInter(input: {
        id: "${payload.id}", 
        materials: "${JSON.stringify(payload?.materials).replace(/\"/g, '\\"')}"
      })
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateCouponPreInter;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE INTERVENTION COUPON
  //✨✨==========================================✨✨
  deleteInterventioncoupon: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteCouponPreInter(input: {
        id: "${payload}", 
      })
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteCouponPreInter;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default CouponPreInterMiddleware;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const TruckMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST TRUCK
  //✨✨==========================================✨✨
  getListTruck: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listTrucks(filter: {archived: {eq: false}}) {
        items {
          driver {
            first_name
            last_name
            id
          }
          id
          model
          registration
          year
          controlTechnique {
            items {
              id
              date
            }
          }
        }
      }
    }    
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTrucks.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET TRUCK
  //✨✨==========================================✨✨
  getTruck: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getTruck(id: "${payload}") {
        id
        model
        registration
        truckDriverId
        year
        document
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE TRUCK
  //✨✨==========================================✨✨
  updateTruck: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateTruck(input: {
        id: "${payload?.id}",
        model:"${payload?.model}",
        registration: "${payload?.registration}",
        truckDriverId: "${payload?.truckDriverId}",
        ${payload?.document ? `document: "${payload?.document}",` : ''}
        year: "${payload?.year}",
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE TRUCK
  //✨✨==========================================✨✨
  createTruck: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createTruck(input: {
            archived: false, 
            truckDriverId: "${payload?.truckDriverId}",
             model: "${payload?.model}",
             year: "${payload?.year}",
             registration:"${payload?.registration}",
             document: "${payload?.document}"
            }) {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE TRUCK(UPDATE ARCHIVE)
  //✨✨==========================================✨✨
  deleteTruck: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateTruck(input: {
        id: "${payload}",
        archived: true,
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateTruck;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default TruckMiddleware;


import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import { dateFormat } from '../../../tools/date';

const EditListConstructionSiteFinish = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idConstructionSite } = useParams();

  //  🌱🌱 init store(services)   🌱🌱
  const getConstructionSite = useStoreActions((actions) => actions.middleware.ConstructionSiteMiddleware.getConstructionSite);

  //  🌱🌱  Etats (données)  🌱🌱
  const [constructionSite, setConstructionSite] = useState({});

  const FormulaireCommande = [
    {
      label: 'Créée le :',
      inputType: 'text',
      valueName: 'date',
      placeholder: 'XX . XX . XX',
      errorMsg: 'erreur date',
      required: false,
      halfWidth: true,
      disabled: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: dateFormat(constructionSite?.date, '.').full,
    },
    {
      label: 'Type de commande',
      inputType: 'text',
      valueName: 'constructionSite',
      halfWidth: true,
      placeholder: 'Rotation',
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: constructionSite?.shipType,
    },
    {
      label: 'Lieu de livraison',
      inputType: 'text',
      valueName: 'adress',
      placeholder: 'Adresse de livraison du client / Adresse du chantier',
      errorMsg: 'Veuillez entrer une adresse de livraison valide',
      required: true,
      halfWidth: false,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: constructionSite?.address?.address,
    },
  ];

  const FormulaireShipInfos = [
    {
      label: 'Marchandises',
      inputType: 'text',
      valueName: 'Marchandise',
      placeholder: 'Nom de la marchandise',
      errorMsg: '',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: constructionSite?.marchandise?.name,
    },
    {
      label: 'Tonnage',
      inputType: 'text',
      valueName: 'tonnage',
      placeholder: '4 tonnes',
      errorMsg: '',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: constructionSite?.quantity,
    },
  ];
  const FormulaireShipInfos2 = [
    {
      label: 'Type de journée',
      inputType: 'text',
      valueName: 'Marchandise',
      placeholder: 'Nom de la marchandise',
      errorMsg: '',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: constructionSite?.modelDay?.name,
    },
  ];

  const FormPrice = [
    {
      label: 'Prix',
      inputType: 'number',
      valueName: 'price',
      placeholder: '€',
      errorMsg: 'Choisir un prix',
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: constructionSite?.price,
      disabled: true,
    },
  ];

  const FormulaireLivraison = [
    {
      label: 'Réaliser par',
      inputType: 'text',
      valueName: 'nameDriver',
      errorMsg: 'Veuillez sélectionner un chauffeur valide',
      halfWidth: '1/2-full',
      required: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: `${constructionSite?.driver?.firstName} ${constructionSite?.driver?.lastName}`,
    },
    {
      label: 'Date de livraison',
      inputType: 'text',
      valueName: 'dateLivraison',
      errorMsg: 'Veuillez sélectionner une date de livraison valide',
      halfWidth: true,
      required: true,
      style: ' placeholder:text-blue-admin',
      disabled: true,
      defaultValue: dateFormat(constructionSite?.datePlanned, '.').full,
    },
  ];

  //  🌱🌱 Request call  constructionSite  🌱🌱
  const callConstructionSite = async () => {
    try {
      const res = await getConstructionSite(idConstructionSite);
      setConstructionSite(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la commande');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callConstructionSite();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full px-4 pt-16  md:px-9">
      {/* div Title */}
      <div className="flex justify-between pb-4 mb-4 border-b  items center border-gray-admin pt-11 md:pt-0">
        <h3 className="text-xl font-bold text-blue-admin">
          {constructionSite?.id && constructionSite?.client?.company} : commande N° {constructionSite?.id && constructionSite?.numero}
        </h3>
      </div>
      <div className="flex justify-between pb-4 mb-4 border-b  items center border-gray-admin mt-11">
        <h2 className="text-lg font-bold ">Infos commande</h2>
      </div>
      {/* 2e formulaire  : Commande*/}
      <div className="max-w-[1100px] pb-5">{constructionSite?.id && <FormCustom data={FormulaireCommande} />}</div>
      {constructionSite.shipType === 'Tournée' ? (
        <div className="max-w-[1100px] pb-5">{constructionSite?.id && <FormCustom data={FormulaireShipInfos} />}</div>
      ) : (
        <div className="max-w-[1100px] pb-5">{constructionSite?.id && <FormCustom data={FormulaireShipInfos2} />}</div>
      )}
      <div className="max-w-[1100px] pb-5">{constructionSite?.id && <FormCustom data={FormPrice} />}</div>
      <div className="flex justify-between pb-4 mb-4 border-b  items center border-gray-admin pt-7">
        <h3 className="text-xl font-bold ">Infos livraison</h3>
      </div>

      {/* 3e formulaire: Livraison*/}
      <div className="max-w-[1100px] pb-5 flew-grow">{constructionSite?.id && <FormCustom data={FormulaireLivraison} />}</div>
      {/* Div des 1 boutons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
    </section>
  );
};

export default EditListConstructionSiteFinish;


import React, { useState, useEffect } from 'react';

const Modal = ({ title, text, image, showModal = false, children }) => {
  //init State
  const [hidden, sethidden] = useState(showModal);

  useEffect(() => {
    sethidden(showModal);
  }, [showModal]);

  //Render
  return (
    <>
      <div className={`absolute w-full h-full top-0 left-0 bg-gray-900 bg-opacity-10 ${hidden ? 'block' : 'hidden'}`} />
      <div
        className={`absolute w-full h-full top-0 left-0 flex items-center justify-center ${
          hidden ? 'block' : 'hidden'
        }`}
      >
        <div className=" bg-white px-5 py-6 rounded w-1/3 max-w-[321px] shadow-2xl">
          <div className="p-4 rounded-lg border border-gray-admin w-fit">
            <img
              className=" w-4 h-4"
              src={image}
              alt="icon"
            />
          </div>
          <h3 className="text-blue-admin font-bold mt-8">{title}</h3>
          <p className="text-blue-admin font-normal text-sm mt-2 mb-6">{text}</p>
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;


import React, { useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

const BillPreview = () => {
  const navigate = useNavigate();
  const { idBill } = useParams();
  const [billData, setBillData] = useState(null);
  const componentRef = useRef(null);
  const getBillById = useStoreActions((actions) => actions.middleware.BillMiddleware.getBillById);

  const handlePrint = useReactToPrint({
    contentRef: componentRef,
    content: () => componentRef.current,
    documentTitle: `Facture-${billData?.documentNumber}`,
    onAfterPrint: () => toast.success('PDF généré avec succès !'),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBillById(idBill);
        setBillData(data);
      } catch (error) {
        toast.error('Une erreur est survenue lors du chargement des données de la facture.');
      }
    };

    fetchData();
  }, [getBillById, idBill]);

  return (
    <section className="flex flex-col items-center mt-10 p-4">
      {!!billData && (
        <div className="shadow-lg w-full max-w-[850px]">
          <div
            ref={componentRef}
            className="bg-white p-6 md:p-10 rounded-lg border border-gray-300 flex flex-col justify-between"
            style={{ minHeight: '1100px' }} // Augmenter la hauteur du PDF
          >
            <div>
              <header className="flex flex-row justify-between items-start md:items-center mb-8">
                <div>
                  <h1 className="text-2xl font-bold text-blue-600">FACTURE</h1>
                  <p className="text-gray-600">N° {billData?.documentNumber}</p>
                </div>
                <div className="text-right mt-4 md:mt-0">
                  <h2 className="font-bold text-yellow-500">{billData?.worksite.company.name}</h2>
                  <p>{billData?.worksite.address}</p>
                  <p>Date : {new Date(billData?.date).toLocaleDateString()}</p>
                </div>
              </header>

              <main>
                <div className="mb-6">
                  <h3 className="text-lg font-bold">Chantier :</h3>
                  <p className="text-gray-700">{billData?.worksite.name}</p>
                  <p className="text-gray-500">Date prévue : {billData?.worksite.date_planned}</p>
                </div>

                <table className="w-full text-left border-collapse text-sm md:text-base">
                  <thead>
                    <tr className="bg-blue-600 text-white">
                      <th className="p-2">Description</th>
                      <th className="p-2">Quantité</th>
                      <th className="p-2">Prix HT</th>
                      <th className="p-2">Montant TTC</th>
                      <th className="p-2">Statut</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-t">
                      <td className="p-2">{billData?.type || '-'}</td>
                      <td className="p-2">1</td>
                      <td className="p-2">{billData?.amountHT.toFixed(2)} €</td>
                      <td className="p-2">{billData?.amountTTC.toFixed(2)} €</td>
                      <td className="p-2 text-yellow-500 font-bold">{billData?.status}</td>
                    </tr>
                  </tbody>
                </table>

                <div className="mt-8 flex justify-end">
                  <div>
                    <p className="text-gray-700">
                      Reste à payer :{' '}
                      <span className="font-bold text-black">
                        {billData?.amountRemaining ? billData?.amountRemaining.toFixed(2) : 0} €
                      </span>
                    </p>
                    <p className="text-lg font-bold text-blue-600">Montant TTC : {billData?.amountTTC.toFixed(2)} €</p>
                  </div>
                </div>
              </main>
            </div>

            <footer className="text-center mt-8 border-t pt-4 text-sm text-gray-600">
              <p>Merci pour votre confiance.</p>
              <p>Coordonnées de l'entreprise : {billData?.worksite.company.name}</p>
              <p>{billData?.worksite.company.address}</p>
              <p>{billData?.worksite.company.mail}</p>
              <p>{billData?.worksite.company.phone}</p>
              <p>{billData?.worksite.company.siret}</p>
            </footer>
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row justify-around items-center gap-4 mt-4 w-full max-w-[850px]">
        <button
          onClick={() => navigate(-1)}
          className="bg-primary-blue text-white px-6 py-2 rounded-lg hover:bg-blue-700"
        >
          Retour
        </button>
        <button
          onClick={() => handlePrint()}
          disabled={!billData}
          className={`px-6 py-2 rounded ${
            billData ? 'bg-yellow-admin text-blue-admin hover:bg-yellow-600' : 'bg-gray-400 text-gray-700 cursor-not-allowed'
          }`}
        >
          Télécharger au format PDF
        </button>
      </div>
    </section>
  );
};

export default BillPreview;

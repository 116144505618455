import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import { toast } from 'react-toastify';

const AddNewMaterialSettings = () => {
  const [values, setValues] = useState([]);
  const [material, setMaterial] = useState([]);
  const navigate = useNavigate();

  //  🌱🌱 init store(services)   🌱🌱
  const createMaterial = useStoreActions((actions) => actions.middleware.SettingsMiddleware.createMaterial);

  // add input
  const handleAdd = () => {
    const allValues = [...values, []];
    setValues(allValues);
  };

  // change value on input
  const handleChange = (onChangeValue, index) => {
    const inputdata = [...values];
    inputdata[index] = onChangeValue.target.value;
    setValues(inputdata);
  };

  // delete input
  const handleDelete = (index) => {
    const deleteValues = [...values];
    deleteValues.splice(index, 1);
    setValues(deleteValues);
  };

  // form on back
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await createMaterial({ material: material, values: values });
      console.log('DATA', res);
      if (res) {
        navigate('/settings/purchase-order');
      }
    } catch (error) {
      console.log('error', error);
      toast.error('❗️ Une erreur est survenue lors de la création du matériel');
    }
  };

  return (
    <section className="flex flex-col h-screen">
      {/* Header */}
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Créer un nouveau matériel</h3>
      </div>
  
      {/* Form Content */}
      <form onSubmit={handleSubmit} className="flex flex-col flex-grow">
        <div className="flex mx-14 space-x-4 items-center border-b border-white-admin py-5">
          <label htmlFor="name">Nom du matériel</label>
          <input
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm"
            type="text"
            placeholder="Nom du matériel"
            onChange={(e) => setMaterial(e.target.value)}
            value={material}
            required
          />
        </div>
        <div className="mx-14 flex-grow">
          <p className="pt-7">Références</p>
          <p className="text-gray-admin text-sm pt-4">
            Veuillez ajouter au moins une référence pour enregistrer ce nouveau matériel
          </p>
          {values.map((value, index) => (
            <label
              key={index}
              className="inline-flex relative items-center cursor-pointer pt-7 pl-2"
            >
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
              />
              <div className="w-11 h-5 peer-focus:outline-none rounded-full peer dark:bg-orange-clim peer-checked:after:translate-x-6  after:content-[''] after:absolute after:top-[45px] after:left-[10px] after:bg-white  after:border after:rounded-full after:h-4 after:w-4 after:transition-all  peer-checked:bg-blue-admin"></div>
              <div className="ml-4 flex">
                <input
                  className="border border-white-admin w-[318px] py-3 rounded-xl pl-4"
                  placeholder="Référence"
                  type="text"
                  value={value}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
                <button
                  className="py-4 px-4 border border-white-admin p-2 rounded-xl ml-3"
                  onClick={() => handleDelete(index)}
                >
                  <img
                    src={DeleteIcon}
                    alt="delete icon"
                  />
                </button>
              </div>
            </label>
          ))}
          <button
            onClick={() => handleAdd()}
            className="text-blue-admin text-sm underline underline-offset-1 pt-4"
          >
            + Ajouter une option
          </button>
        </div>
  
        {/* Footer Buttons */}
        <div className="mt-auto py-4 flex justify-between items-center border-t border-white-admin px-14">
          <Link
            to="/settings/purchase-order"
            className="cursor-pointer border border-white-admin rounded-lg"
          >
            <button className="py-3 px-5 text-blue-admin">Annuler</button>
          </Link>
          <div className="cursor-pointer bg-yellow-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-blue-admin" 
            >
              Ajouter un matériel
            </button>
          </div>
        </div>
      </form>
    </section>
  );  
};

export default AddNewMaterialSettings;

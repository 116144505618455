import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const BreakdownMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST BREAKDOWN
  //✨✨==========================================✨✨
  getListBreakdowns: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listBreakdowns(filter: {truckBreakdownId: {eq: "${payload}"}}) {
        items {
          id
          name
          document
          price
          status
          date
          truckBreakdownId
          createdAt
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listBreakdowns.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET BREAKDOWN BY ID
  //✨✨==========================================✨✨
  getBreakdown: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getBreakdown(id: "${payload}") {
        id
        name
        price
        status
        date
        document
        truckBreakdownId
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getBreakdown;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE BREAKDOWN
  //✨✨==========================================✨✨
  createBreakdown: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createBreakdown(input: {
        name: "${payload?.name}", 
        date: "${payload?.date}",
        document: "${payload?.document}", 
        price: "${payload?.price ? payload?.price : 'Non défini'}",
        status: "${payload?.status}",
        truckBreakdownId: "${payload?.idTruck}"
      }) 
      {
        id
      }
    }
    
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createBreakdown;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE BREAKDOWN
  //✨✨==========================================✨✨
  updateBreakdown: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateBreakdown(input: {
        id: "${payload?.id}", 
        date: "${payload?.date}",
        name: "${payload?.name}", 
        ${payload.document ? `document: "${payload.document}"` : ''}
        ${payload?.price ? `price: "${payload?.price}"` : ''}
        status: "${payload?.status}",
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateBreakdown;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE BREAKDOWN
  //✨✨==========================================✨✨

  deleteBreakdown: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteBreakdown(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteBreakdown;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default BreakdownMiddleware;


export const joursUS = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
export const mois = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];

export const referenceToTable = (reference = []) => {
  return reference[0].reference.split(',');
};

export const dateFormat = (date, separation = '-') => {
  const temps = new Date(date);

  return {
    year: temps.getFullYear().toString(),
    month: ('0' + (temps.getMonth() + 1)).slice(-2),
    day: ('0' + temps.getDate()).slice(-2),
    full: `${('0' + temps.getDate()).slice(-2)}${separation}${('0' + (temps.getMonth() + 1)).slice(
      -2
    )}${separation}${temps.getFullYear().toString()}`,
    stringFull: `${joursUS[temps.getDay()]} ${('0' + temps.getDate()).slice(-2)} ${
      mois[temps.getMonth()]
    } ${temps.getFullYear()}`,
  };
};

export const hourFormat = (date) => {
  const temps = new Date(date);
  return {
    hour: ('0' + temps.getHours()).slice(-2),
    minute: ('0' + temps.getMinutes()).slice(-2),
    second: ('0' + temps.getSeconds()).slice(-2),
    full: `${('0' + temps.getHours()).slice(-2)}:${('0' + temps.getMinutes()).slice(-2)}:${(
      '0' + temps.getSeconds()
    ).slice(-2)}`,
  };
};

export const maxText = (text, max = 21) => {
  if (text.length > max) {
    return text.slice(0, max - 3) + '...';
  } else {
    return text;
  }
};

export const sorted = (table, key, reverse = false) => {
  let newTable = JSON.parse(JSON.stringify(table));
  newTable.sort((a, b) => {
    if (a[key] < b[key]) return -1;
    if (a[key] > b[key]) return 1;
    return 0;
  });
  if (reverse) newTable.reverse();
  return newTable;
};

export const lastNumberCoupon = (table, key = null) => {
  const maxNumber = table.reduce((r, b) => Math.max(r, key ? b[key] : b), 0);
  const final = maxNumber + 1;
  return final;
};

export const filterMaterialData = (tab) => {
  return tab.map((el) => {
    return {
      nature: el.nature || '',
      material: el.material || '',
      refName: el.refName || '',
      quantity: el.quantity || '',
      pose: el.pose || '',
      depot: el.depot || '',
      surface: el.surface || '',
      statut: el.statut || false,
      comments: el.comments || '',
    };
  });
};


/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';
import Modal from '../Modal_2';

// Import icons
import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import AttentionIcon from '../../../assets/images/svg/attention.svg';

// Tools
import { hourFormat } from '../../tools/manager.js';

// @env
const S3url = process.env.REACT_APP_URL_S3_OBJECT;

const ListVoiceNotes = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const currentUser = useStoreState((state) => state.userData.currentUser);
  const getListVoiceNote = useStoreActions((actions) => actions.middleware.VoiceNoteMiddleware.getListVoiceNotes);
  const deleteVoiceNote = useStoreActions((actions) => actions.middleware.VoiceNoteMiddleware.deleteVoiceNote);
  const deleteFile = useStoreActions((actions) => actions.middleware.FileS3.deleteFile);

  //  🌱🌱  Etats (données)  🌱🌱
  const [voiceNotes, setVoiceNotes] = useState([]);
  const [voiceNoteSelected, setVoiceNoteSelected] = useState(null);
  const [showModal, setShowModal] = useState(false);

  //  🌱🌱 Request call List VoiceNotes  🌱🌱
  const callListVoiceNote = async (id) => {
    try {
      const res = await getListVoiceNote(id);
      //   Sort by date
      res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setVoiceNotes(res);
      console.log('DATA', res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des notes vocales');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListVoiceNote(currentUser?.id);
  }, []);

  //   Handle delete voice note
  const handleDeleteVoiceNote = async () => {
    try {
      await deleteFile({
        nameFile: voiceNoteSelected.audioUri,
      });
      await deleteVoiceNote(voiceNoteSelected.id);
      setVoiceNoteSelected(null);
      setShowModal(false);
      callListVoiceNote(currentUser?.id);
      toast.success('✅ La note vocale a été supprimée avec succès');
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la note vocale');
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="px-4 md:pt-14 md:px-9">
      {/* div Title +button */}
      <div className="flex flex-col justify-between pb-4 mb-4 space-y-4 border-b items center border-gray-admin pt-11 md:pt-0 md:flex-row md:space-y-0">
        <h3 className="text-xl font-bold ">Liste des notes vocales</h3>
        <SecondaryButton
          title=" + Ajouter une nouvelle note vocale"
          link={'add'}
        />
      </div>

      {/* Table list Trucks */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Date </th>
              <th>Heure</th>
              <th>Audio</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {voiceNotes.length ? (
              voiceNotes.map((voiceNote) => {
                const date = new Date(voiceNote.createdAt).toLocaleDateString();
                const time = hourFormat(voiceNote.createdAt).full;
                return (
                  <tr key={voiceNote.id}>
                    <td>{voiceNote.firstname}</td>
                    <td>{voiceNote.lastname}</td>
                    <td>{date}</td>
                    <td>{time}</td>
                    {/* Lecteur audio */}
                    <td className="flex items-center justify-end py-4 space-x-4">
                      <audio controls>
                        <source
                          src={S3url + voiceNote.audioUri}
                          type="audio/mpeg"
                        />
                      </audio>
                    </td>
                    <td className="w-fit text-right">
                      <button
                        className="px-2 py-2 border rounded-lg border-gray-separate"
                        onClick={() => {
                          setVoiceNoteSelected(voiceNote);
                          setShowModal(true);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt="icone delete"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  colSpan={5}
                  className="text-lg text-center"
                >
                  Vous n'avez reçu aucune note vocale
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="px-5 pt-2 text-gray-admin">
              Êtes-vous sûre de vouloir supprimer cette note vocale? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={handleDeleteVoiceNote}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default ListVoiceNotes;


import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PdfPreview = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(-1);
  }, []);

  return <></>;
};

export default PdfPreview;


import React from 'react';
import { Outlet } from 'react-router-dom';
import NavigationClassifyOrder from '../shared/layout/NavigationClassifyOrder';

const ClassifyOrder = () => {
  return (
    <div>
      <NavigationClassifyOrder />
      <Outlet />
    </div>
  );
};

export default ClassifyOrder;


import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import Table from '../../Table';
import Modal from '../../Modal_2';

// Import tools
import { dateFormat } from '../../../tools/date';

// import logo
import Search from '../../../../assets/images/svg/search.svg';
import Eye from '../../../../assets/images/svg/eye.svg';
import DeleteIcon from '../../../../assets/images/svg/delete-icon.svg';
import AttentionIcon from '../../../../assets/images/svg/attention.svg';
import More from '../../../../assets/images/svg/more.svg';

const Contraventions = () => {
  const { idTruck } = useParams();
  //  🌱🌱 Init store(services)   🌱🌱
  const getListContraventions = useStoreActions(
    (actions) => actions.middleware.ContraventionMiddleware.getListContraventions
  );
  const deleteContraventionById = useStoreActions(
    (actions) => actions.middleware.ContraventionMiddleware.deleteContravention
  );

  //  🌱🌱  Etats (données)  🌱🌱
  const [contraventions, setContraventions] = useState([]);
  const [contraventionsList, setContraventionsList] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [contraventionSelected, setContraventionSelected] = useState(null);

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setContraventionsList(contraventions);
    } else {
      const tabFilter = contraventions.filter((element) =>
        element.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setContraventionsList(tabFilter);
    }
  }, [textSearch, contraventions]);

  //  🌱🌱 Request call List Contraventions  🌱🌱
  const callListContraventions = async () => {
    try {
      const res = await getListContraventions(idTruck);
      setContraventions(res);
    } catch (error) {
      console.log('error', error);

      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des contraventions');
    }
  };

  //   🌱🌱 Request delete Contravention  🌱🌱
  const deleteContravention = async () => {
    try {
      await deleteContraventionById(contraventionSelected.id);
      callListContraventions();
      setShowModal(false);
      setContraventionSelected(null);
      toast.success('❗️ La contravention a bien été supprimée');
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la contravention');
    }
  };

  //  🌱🌱  Delete action  🌱🌱
  const callDeleteContravention = (contravention) => {
    setShowModal(true);
    setContraventionSelected(contravention);
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListContraventions();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <>
      <section className="px-4 md:px-9">
        {/* div search bar */}
        {/* <div className="relative mt-12">
          <input
            type="text"
            placeholder="Recherche contravention"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
            onInput={(e) => setTextSearch(e.target.value)}
          />
          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div> */}
        {/* Table list Contraventions */}
        <div className="mt-9">
          <Table>
            <thead>
              <tr>
                <th>Date de la contravention</th>
                <th>Nom du chauffeur</th>
                <th>Document</th>
                <th>Statut</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {contraventionsList.length ? (
                contraventionsList.map((contravention) => {
                  const createDate = dateFormat(contravention?.date).full;
                  return (
                    <tr key={contravention.id}>
                      <td>{createDate}</td>
                      <td>
                        {contravention?.driver?.firstName} {contravention?.driver?.lastName}
                      </td>
                      <td className="w-fit">
                        <Link
                          to={`contravention-preview/${contravention.id}`}
                          className="flex w-fit p-2 border rounded-lg"
                        >
                          <img
                            src={Eye}
                            alt="see icon"
                            className="w-4 h-4"
                          />
                        </Link>
                      </td>
                      <td>{contravention?.status}</td>
                      <td className="flex items-center pt-4 space-x-4 justify-end">
                        <button
                          className="w-8 p-2 border rounded-lg border-gray-separate"
                          onClick={() => callDeleteContravention(contravention)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                          />
                        </button>
                        <Link
                          to={`edit-contravention/${contravention.id}`}
                          className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1 "
                        >
                          <img
                            src={More}
                            alt="more icon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={5}
                    className="text-lg text-center"
                  >
                    La liste des contraventions est vide. Veuillez ajouter une contravention.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du contravention: {contraventionSelected?.id}</p>
            <p className="px-5 pt-2 text-gray-admin">
              Êtes-vous sûre de vouloir supprimer cette contravention ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteContravention}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contraventions;


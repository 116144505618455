import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const NavigationSettings = ({ currentWorkSite }) => {
  // Init state
  const [toggleActive, setToggleActive] = useState(false);

  // Init url params
  const urlParams = useParams();

  // Init effect
  useEffect(() => {
    console.log('urlParams:', urlParams); // Debugging
    const currentPath = urlParams['*'] ? urlParams['*'].split('/') : [];
    if (currentPath.includes('purchaseorder')) {
      setToggleActive(false);
    } else {
      setToggleActive(true);
    }
  }, [urlParams]);

  return (
    <div>
      <section className="md:pt-14 px-4 md:px-9">
        {/* div Title = button */}
        <div className="flex flex-col items-center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row">
          <h3 className="font-bold text-xl text-center md:text-left">{currentWorkSite?.name}</h3>
        </div>
        <div className="pb-4 border-b pt-11 border-white-admin">
          <ul className="flex space-x-8">
            <li className="text-blue-admin">
              <Link
                to="purchaseorder"
                state={currentWorkSite}
                className={toggleActive ? 'bg-yellow-admin text-blue-admin rounded-lg p-3' : 'border border-gray-admin bg-white rounded-lg p-3'}
              >
                Bons de commande
              </Link>
            </li>
            <li className="text-blue-admin">
              <Link
                to="workorder"
                state={currentWorkSite}
                className={toggleActive ? 'bg-yellow-admin text-blue-admin rounded-lg p-3' : 'border border-gray-admin bg-white rounded-lg p-3'}
              >
                Bons d’intervention
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default NavigationSettings;


import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import Component
import SecondaryButton from '../components/SecondaryButton';

const MatriculationNavigation = () => {
  //  🌱🌱 Init Init Location  🌱🌱
  const location = useLocation();

  //  🌱🌱 Init store(services)  🌱🌱
  const getTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.getTruck);

  //  🌱🌱 Init Init Params  🌱🌱
  const { idTruck } = useParams();

  //  🌱🌱  Etats (données)  🌱🌱
  const [truck, setTruck] = useState({});

  //  🌱🌱 Request call Get Truck  🌱🌱
  const callTruck = async () => {
    try {
      const res = await getTruck(idTruck);
      setTruck(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement du véhicule');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callTruck();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 md:px-9 px-4">
      <div className=" flex flex-col gap-y-6 items center justify-between border-b border-gray-admin pb-7 pt-11 md:pt-0 mb-4 md:flex-row md:pb-4 md:gap-y-3">
        <h3 className="font-bold text-xl ">
          {truck?.model} : {truck?.registration}
        </h3>
        <div className={location.pathname.includes('technical-control') ? 'block' : 'hidden'}>
          <SecondaryButton
            title="+ Ajouter un nouveau contrôle technique"
            link={`/trucks/add-technical-control/${idTruck}`}
          />
        </div>
        <div className={location.pathname.includes('list-revision') ? 'block' : 'hidden'}>
          <SecondaryButton
            title="+ Ajouter une révision"
            link={`/trucks/add-revision/${idTruck}`}
          />
        </div>
        <div className={location.pathname.includes('contraventions') ? 'block' : 'hidden'}>
          <SecondaryButton
            title="+ Ajouter une contravention"
            link={`/trucks/add-contravention/${idTruck}`}
          />
        </div>
        <div className={location.pathname.includes('breakdowns') ? 'block' : 'hidden'}>
          <SecondaryButton
            title="+ Ajouter une panne"
            link={`/trucks/add-breakdown/${idTruck}`}
          />
        </div>
        <div className={location.pathname.includes('expenses') ? 'block' : 'hidden'}>
          <SecondaryButton
            title="+ Ajouter une dépense"
            link={`/trucks/add-expense/${idTruck}`}
          />
        </div>
      </div>

      <div className="flex md:flex-row md:space-x-4 flex-col space-y-4 md:space-y-0">
        <NavLink
          to="vehicle-file"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Dossier du véhicule
        </NavLink>
        <NavLink
          to="technical-control"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Contrôle technique
        </NavLink>
        <NavLink
          to="list-revision"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Révisions
        </NavLink>
        <NavLink
          to="refueling"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Ravitaillement
        </NavLink>
        <NavLink
          to="contraventions"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Contraventions
        </NavLink>
        <NavLink
          to="breakdowns"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Toutes les pannes
        </NavLink>
        <NavLink
          to="expenses"
          className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
        >
          Dépenses
        </NavLink>
      </div>
    </section>
  );
};

export default MatriculationNavigation;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const CompanyMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST COMPANIES
  //✨✨==========================================✨✨
  getlistCompanies: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listTypeCompanies(filter: {name: {eq: "${payload}"}} limit: 500000) {
        items {
          id
          name
          companies {
            items {
              address
              ctm
              id
              mail
              name
              phone
              siret
              worksites {
                items {
                  id
                }
              }
            }
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTypeCompanies.items[0].companies.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST COMPANIES BY NAME
  //✨✨==========================================✨✨
  listCompaniesByName: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listCompanies(filter: {name: {eq: "${payload}"}} limit: 500000) {
        items {
          id
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listCompanies.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET COMPANY
  //✨✨==========================================✨✨
  getCompany: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        getCompany(id: "${payload}") {
          address
          ctm
          id
          mail
          name
          phone
          siret
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getCompany;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET SACA COMPANY
  //✨✨==========================================✨✨
  getSacaCompany: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listCompanies(filter: {name: {eq: "Sacarabany SARL"}}) {
        items {
          id
          name
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listCompanies.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE COMPANY
  //✨✨==========================================✨✨
  createCompany: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createCompany(input: {
        address: "${payload.address}", 
        mail: "${payload.mail}", 
        name: "${payload.name}", 
        phone: "${payload.phone}", 
        siret: "${payload.siret}",
        typeCompanyCompaniesId: "${payload.id_type_Company}",
        ${payload.typeClient === 'CTM' ? 'ctm: true' : 'ctm: false'}
      })
      {
          id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createCompany;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE COMPANY
  //✨✨==========================================✨✨
  updateCompany: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateCompany(input: {
        id: "${payload.id}", 
        name: "${payload.name}",
        siret: "${payload.siret || ''}",
        address: "${payload.address}", 
        mail: "${payload.mail}", 
        phone: "${payload.phone}",
        ${payload.typeClient === 'CTM' ? 'ctm: true' : 'ctm: false'}
      })
      {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateCompany;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //          DELETE COMPANY
  //✨✨==========================================✨✨
  deleteCompany: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteCompany(input: {id: "${payload}"}) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteCompany;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default CompanyMiddleware;


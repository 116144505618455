import React, { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useStoreActions } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';

// import icons
import AttentionIcon from '../../../assets/images/svg/attention.svg';
import Modal from '../Modal_2';
import Search from '../../../assets/images/svg/search.svg';
import Table from '../Table';

const ListClassifyInterventionCoupon = () => {
  //Navigation
  const navigation = useNavigate();
  // initial state
  const [showModalMove, setShowModalMove] = useState(false);
  const [interventionCoupons, setInterventionCoupons] = useState([]);
  const [listInterventionCoupons, setListInterventionCoupons] = useState(interventionCoupons);
  const [textSearch, setTextSearch] = useState('');
  const [idCoupon, setIdCoupon] = useState(null);
  const [namesWorkSites, setNamesWorksites] = useState([]);
  const [namesCompagny, setNamesCompagny] = useState([]);
  const [valuesWorksites, setValuesWorksites] = useState([]);
  const [selectWorksite, setSelectWorksite] = useState(null);

  //  🌱🌱 init store(services)   🌱🌱
  const getListInterventionCoupons = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.getListInterventionCoupon
  );
  const getListWorksitesClassify = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.getListWorksitesClassify
  );
  const getListCompanyClassify = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.getListCompanyClassify
  );
  const moveInterventionCouponToWorksite = useStoreActions(
    (actions) => actions.middleware.OrdersMiddleware.moveInterventionCouponToWorksite
  );

  // format date
  const dateFormat = (date) => {
    const temps = new Date(date);
    return {
      year: temps.getFullYear().toString(),
      month: ('0' + (temps.getMonth() + 1)).slice(-2),
      day: ('0' + temps.getDate()).slice(-2),
    };
  };

  // funcion filter search
  useEffect(() => {
    if (textSearch === '') {
      setListInterventionCoupons(interventionCoupons);
    } else {
      const tabFilter = interventionCoupons.filter(
        (element) =>
          element.nameClient.toLowerCase().includes(textSearch.toLowerCase()) ||
          element.nameWorksite.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.numero.includes(textSearch)
      );
      setListInterventionCoupons(tabFilter);
    }
  }, [interventionCoupons, textSearch]);

  useEffect(() => {
    getListInterventionCoupon();
    getNameListWorksites();
    getNameListCompany();
  }, []);

  const getListInterventionCoupon = async () => {
    try {
      const res = await getListInterventionCoupons();
      console.log('getListInterventionCoupon', res);
      setInterventionCoupons(res);
    } catch (error) {
      toast.error("Erreur lors de la récupération des bons d'intervention");
    }
  };

  // func fetch list name worksites
  const getNameListWorksites = async () => {
    try {
      const res = await getListWorksitesClassify();
      console.log('getListWorksitesClassify', res);
      setNamesWorksites(res);
    } catch (error) {
      toast.error('Erreur lors de la récupération des chantiers');
    }
  };

  // func fetch list company
  const getNameListCompany = async () => {
    try {
      const res = await getListCompanyClassify();
      console.log('getListCompanyClassify', res);
      setNamesCompagny(res[0].companies.items);
    } catch (error) {
      toast.error('Erreur lors de la récupération des clients');
    }
  };

  // func onClick move coupon coupon
  const handleMove = (id) => {
    setIdCoupon(id);
    setShowModalMove(true);
  };

  const handleChange = (e) => {
    const listWorksite = JSON.parse(e.target.value)?.worksites?.items;
    setValuesWorksites(listWorksite);
    setSelectWorksite('');
  };

  const handleChangeWorksite = (e) => {
    setSelectWorksite(e.target.value);
  };

  // func move orderCoupon
  const callMoveInterventionCouponToWorksite = async () => {
    try {
      const res = await moveInterventionCouponToWorksite({ id: idCoupon, idWorksite: selectWorksite });
      console.log('callMoveInterventionCouponToWorksite', res);
      getListInterventionCoupon();
    } catch (error) {
      toast.error("Erreur lors du déplacement du bon d'intervention");
    }
  };

  return (
    <section className="md:pt-8 px-4 md:px-9">
      {/* div main */}
      <div className="h-[500px]">
        {/* div search bar */}
        <div className="relative flex mt-6 border border-gray-admin rounded-xl">
          <input
            type="text"
            placeholder="Recherche bons"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
            onInput={(e) => setTextSearch(e.target.value)}
          />

          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div>
        {/* table list */}
        <div className="overflow-auto pt-14 h-full">
          <Table>
            <thead className="h-10">
              <tr>
                <th>Réalisé le : </th>
                <th className="pl-10 xl:pl-20">Numéro</th>
                <th className="pl-10 xl:pl-20">Type de chantier</th>
                <th className="pl-10 xl:pl-20">Nom du client</th>
                <th className="pl-10 xl:pl-20">Nom du chantier</th>
                <th className="pl-10 xl:pl-20">Déplacer vers</th>
              </tr>
            </thead>
            <tbody>
              {listInterventionCoupons.length ? (
                listInterventionCoupons
                  ?.sort((a, b) => b.numero - a.numero)
                  ?.map((interventionCoupon) => {
                    const dateCouponCreate = dateFormat(interventionCoupon.createdAt);
                    return (
                      <tr
                        key={interventionCoupon.id}
                        className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all cursor-pointer"
                      >
                        <td
                          onClick={() => {
                            navigation(`/pdf-preview/intervention/${interventionCoupon.id}`);
                          }}
                        >
                          {dateCouponCreate.day}.{dateCouponCreate.month}.{dateCouponCreate.year}
                        </td>
                        <td
                          className="pl-10 xl:pl-20"
                          onClick={() => {
                            navigation(`/pdf-preview/intervention/${interventionCoupon.id}`);
                          }}
                        >
                          {interventionCoupon?.numero}
                        </td>
                        <td
                          className="pl-10 xl:pl-20"
                          onClick={() => {
                            navigation(`/pdf-preview/intervention/${interventionCoupon.id}`);
                          }}
                        >
                          {interventionCoupon?.orderCoupon?.collaborator?.type?.name}
                        </td>
                        <td
                          className="pl-10 xl:pl-20"
                          onClick={() => {
                            navigation(`/pdf-preview/intervention/${interventionCoupon.id}`);
                          }}
                        >
                          {interventionCoupon?.nameClient}
                        </td>
                        <td
                          className="pl-10 xl:pl-20"
                          onClick={() => {
                            navigation(`/pdf-preview/intervention/${interventionCoupon.id}`);
                          }}
                        >
                          {interventionCoupon?.nameWorksite}
                        </td>
                        <td className="pl-10 xl:pl-20">
                          <button
                            className="text-blue-admin border border-l-gray-separate py-2 px-3 rounded-lg"
                            onClick={() => handleMove(interventionCoupon.id)}
                          >
                            Déplacer votre bon
                          </button>
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td
                    className="text-center text-lg font-semibold pt-20"
                    colSpan={6}
                  >
                    {textSearch ? "Oups, ce chantier ou client n'existe pas 🤭" : "Ajouter un bon d'intervention"}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>

      <Modal
        isVisible={showModalMove}
        styleModal="max-w-fit"
      >
        <div>
          <div className="pt-5 pl-5">
            <img
              className="pl-5"
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Déplacer le bon de commande</p>
            <div className="flex space-x-6 pl-5 py-5">
              <select
                name="antho"
                id=""
                onChange={handleChange}
                className="cursor-pointer w-[200px] text-blue-admin text-sm bg-blue-dropdown py-3 pl-5 pr-10 rounded-lg border-none inputFocus"
              >
                <option
                  disabled
                  selected
                >
                  Client
                </option>
                {namesCompagny?.map((namesCompagny) => {
                  return (
                    <option
                      key={namesCompagny.id}
                      value={JSON.stringify(namesCompagny)}
                    >
                      {namesCompagny?.name}
                    </option>
                  );
                })}
              </select>
              <select
                onChange={handleChangeWorksite}
                className="cursor-pointer w-[200px] text-blue-admin text-sm bg-blue-dropdown py-3 pl-5 pr-10 rounded-lg border-none inputFocus"
              >
                <option
                  selected={!selectWorksite}
                  disabled
                >
                  Chantier
                </option>
                {valuesWorksites?.map((nameWorkSite) => {
                  return (
                    <option
                      key={nameWorkSite?.id}
                      value={nameWorkSite?.id}
                    >
                      {nameWorkSite?.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <p className="px-5 pt-2 text-gray-admin">
              {' '}
              Êtes-vous sûre de vouloir déplacer ce bon ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModalMove(false)}
              >
                Annuler
              </button>

              <button
                disabled={selectWorksite ? false : true}
                onClick={() => {
                  setShowModalMove(false);
                  callMoveInterventionCouponToWorksite();
                }}
                className={`px-10 py-3 text-white ${
                  selectWorksite ? 'bg-red-error' : 'bg-gray-placeholder'
                } bg-red-error rounded-xl`}
              >
                Valider
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default ListClassifyInterventionCoupon;

import React, { useEffect, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';
import DatePicker_date from '../DatePicker_date';

//Import icons
import Check from '../../../assets/images/svg/check.svg';
import Warning from '../../../assets/images/svg/warning.svg';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const EditTraining = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTraining } = useParams();

  //  🌱🌱  Init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱  Etats (données)  🌱🌱
  const [collaborators, setCollaborators] = useState([]);
  const [training, setTraining] = useState([]);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [expiration_date, setExpiration_date] = useState(new Date());
  const [msgErrorInput, setmsgErrorInput] = useState(false);

  //  🌱🌱  Init store(services)  🌱🌱
  const getListCollaborators = useStoreActions(
    (actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators
  );
  const getTraining = useStoreActions((actions) => actions.middleware.TrainingMiddleware.getTraining);
  const updateTraining = useStoreActions((actions) => actions.middleware.TrainingMiddleware.updateTraining);
  const deleteTraining = useStoreActions((actions) => actions.middleware.TrainingMiddleware.deleteTraining);

  //  🌱🌱  Data Form Edit  🌱🌱
  const formEditTraining = [
    {
      label: 'Nom de la formation',
      inputType: 'text',
      valueName: 'name',
      placeholder: 'Formation',
      errorMsg: 'Veuillez entrer le nom de la formation',
      required: true,
      halfWidth: true,
      defaultValue: training?.name || '',
    },
    {
      label: 'Attribution',
      inputType: 'select',
      valueName: 'userTrainingsId',
      options: collaborators,
      keyOptions: 'fullName',
      keyValues: 'id',
      defaultValue: training.collaborator?.id,
      halfWidth: true,
      required: true,
      style: 'max-w-[194px] flex',
      placeholder: '- Collaborateurs -',
      errorMsg: 'Veuillez sélectionner un collaborateur',
    },
  ];

  //  🌱🌱  call get training  🌱🌱
  const callGetTraining = async () => {
    try {
      const res = await getTraining(idTraining);
      console.log('TRAINING', res);

      setTraining(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des formations');
    }
  };

  // 🌱🌱  Call get list collaborators  🌱🌱
  const callGetListCollaborators = async () => {
    try {
      const payload = {
        nameCollab: collaboratorName.toString(),
      };
      const data = await getListCollaborators(payload);
      console.log('data', data);

      //   Sort by name
      data.sort((a, b) => {
        if (a.first_name < b.first_name) {
          return -1;
        }
        if (a.first_name > b.first_name) {
          return 1;
        }
        return 0;
      });

      setCollaborators(
        data.map((collab) => {
          return { ...collab, fullName: `${collab.first_name} ${collab.last_name}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetTraining();
    callGetListCollaborators();
  }, []);

  //  🌱🌱  function update profile training   🌱🌱
  const callUpdateTraining = async (data) => {
    try {
      if (expiration_date < new Date()) {
        setmsgErrorInput(true);
        return;
      }
      const params = { ...data, id: idTraining, expiration_date: expiration_date };
      await updateTraining(params);
      setShowModalUpdate(true);
    } catch (error) {
      console.log('updateProfileTraining', error);
      toast.error('❗️ Une erreur est survenue durant la modification de votre formation, veuillez réessayer.');
    }
  };

  //  🌱🌱  function delete training   🌱🌱
  const callDeleteTraining = async () => {
    setShowModalDelete(false);
    try {
      await deleteTraining(idTraining);
      navigate(-1);
    } catch (error) {
      console.log('deleteTraining', error);
      toast.error('❗️ Une erreur est survenue durant la suppression de votre formation, veuillez réessayer.');
    }
  };

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      callUpdateTraining(value.values);
    }
  };

  return (
    <section className="flex flex-col h-full md:pt-14">
      {/* div title */}
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-11 md:pt-0 md:mx-9">
        <h3 className="text-xl font-bold ">Modifier un collaborateur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {training?.collaborator?.id && (
          <FormCustom
            data={formEditTraining}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
        {training?.id && (
          <div className="mt-4">
            <label>Date de validité</label>
            <DatePicker_date
              selected={expiration_date}
              onChange={setExpiration_date}
              onManual={true}
            />
            <p className="text-red-error">{msgErrorInput ? 'Veuillez ajouter une date de fin validité' : ''}</p>
          </div>
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer"
            onClick={() => {
              setShowModalDelete(true);
            }}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-yellow-admin text-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur la formation ${training?.name}`}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>

      {/* Modal delete */}
      <Modal
        title="Supprimer une formation"
        text="Êtes-vous sûre de vouloir supprimer cette formation ? 
      Cette action est définitive."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              callDeleteTraining();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditTraining;


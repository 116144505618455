import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

// Import tools
import { timeToMs, dateFormat } from '../../tools/date';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import Search from '../../../assets/images/svg/search.svg';
import Eye from '../../../assets/images/svg/eye.svg';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const ListCollaborators = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListCollaborators = useStoreActions(
    (actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators
  );

  //Etats (données)
  const [collabs, setCollabs] = useState([]);
  const [listCollabs, setListCollabs] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setListCollabs(collabs);
    } else {
      const tabFilter = collabs.filter(
        (element) =>
          element.first_name.toLowerCase().includes(textSearch.toLowerCase()) ||
          element.last_name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setListCollabs(tabFilter);
    }
  }, [textSearch, collabs]);

  //  🌱🌱 Request call List Collaborators  🌱🌱
  const callListCollaborators = async () => {
    try {
      const payload = {
        nameCollab: collaboratorName.toString(),
      };
      const res = await getListCollaborators(payload);
      console.log('DATA', res);
      setCollabs(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListCollaborators();
  }, []);

  return (
    <section className="md:pt-14 px-4 md:px-9">
      {/* div Title = button */}
      <div className=" flex flex-col space-y-4 items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row md:space-y-0">
        <h3 className="font-bold text-xl ">Liste des collaborateurs</h3>
        <SecondaryButton
          title=" + Ajouter un nouveau collaborateur"
          link={'add'}
        />
      </div>
      {/* div search bar */}
      <div className="relative flex mt-6 border border-gray-admin rounded-xl">
        <input
          type="text"
          placeholder="Recherche collaborateur"
          className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
          onInput={(e) => setTextSearch(e.target.value)}
        />

        <img
          src={Search}
          alt="search icon"
          className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
        />
      </div>
      {/* Table liste collaborators */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Prénom / Nom</th>
              <th>Téléphone</th>
              <th>Catégorie</th>
              <th>Visite Médicale</th>
              <th>Documents</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listCollabs.map((collab) => {
              const dateVm = new Date(collab?.dateVm);
              return (
                <tr key={collab.id}>
                  <td>
                    {collab.first_name} {collab.last_name}
                  </td>
                  <td>{collab.phone}</td>
                  <td>{collab.type?.name}</td>
                  <td>
                    {collab.docVm ? (
                      <>
                        {dateVm.getTime() < Date.now() + timeToMs.mounth && (
                          <p className="text-red-error text-xs">
                            Visite Médical valide jusqu' au {dateFormat(dateVm, '/').full}
                          </p>
                        )}
                        {dateVm.getTime() >= Date.now() + timeToMs.mounth && (
                          <p className="text-green-700 text-xs">
                            Visite Médical valide jusqu' au {dateFormat(dateVm, '/').full}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="text-red-error text-xs">Pas de visite médicale</p>
                    )}
                  </td>
                  <td>
                    {collab?.docVm && (
                      <Link
                        to={`preview/${collab.id}`}
                        className="flex items-center space-x-2 border border-gray-admin w-20 py-1 px-2 rounded-md cursor-pointer"
                      >
                        <p>Ouvrir</p>
                        <img
                          src={Eye}
                          alt="icone tick circle"
                        />
                      </Link>
                    )}
                  </td>
                  <td>
                    <Link
                      to={`edit/${collab.id}`}
                      className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                    >
                      <img
                        src={More}
                        alt="more icon"
                      />
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListCollaborators;


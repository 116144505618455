import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Auth } from 'aws-amplify';

//components custom
import Container from '../shared/layout/Container';

//Pages and components
import Dashboard from '../pages/Dashboard';
import Administrators from '../pages/Administrators';
import Drivers from '../pages/Drivers';
import Trucks from '../pages/Trucks';
import Clients from '../pages/Clients';
import NameCompany from '../pages/NameCompany';
import AddClients from '../shared/components/clients/AddClients';
import EditClient from '../shared/components/clients/clientFolder/EditClient';
import ConstructionSites from '../pages/ConstructionsSites';
import EditConstructionSiteFinish from '../shared/components/ConstructionSites/ConstructionSitesFinish/EditListConstructionSitesFinish';
import EditConstructionSiteNotFinish from '../shared/components/ConstructionSites/ConstructionSitesNotFinish/EditListConstructionSitesNotFinish';
import ListConstructionSiteNotFinish from '../shared/components/ConstructionSites/ConstructionSitesNotFinish/ListConstructionSitesNotFinish';
import ListConstructionSiteFinish from '../shared/components/ConstructionSites/ConstructionSitesFinish/ListConstructionSitesFinish';
import Planning from '../pages/Planning';
import Settings from '../pages/Settings';
import Anomalie from '../pages/Anomalie';
import AddAdministrators from '../shared/components/admin/AddAdministrators';
import EditAdministrators from '../shared/components/admin/EditAdministrators';
import Collaborators from '../pages/Collaborator';
import AddCollaborators from '../shared/components/collaborators/AddCollaborators';
import EditCollaborators from '../shared/components/collaborators/EditCollaborators';
import ClassifyOrder from '../pages/ClassifyOrder';
import ListClassifyOrderCoupon from '../shared/components/classifyOrders/ListClassifyOrderCoupon';
import ListClassifyInterventionCoupon from '../shared/components/classifyOrders/ListClassifyInterventionCoupon';
import PurchaseOrderSettings from '../shared/components/settings/PurchaseOrderSettings';
import AddNewMaterialSettings from '../shared/components/settings/AddNewMaterialSettings';
import EditMaterialSettings from '../shared/components/settings/EditMaterialSettings';
import WorkOrderSettings from '../shared/components/settings/WorkOrderSettings';
import AddTypeInterventionSettings from '../shared/components/settings/AddTypeInterventionSettings';
import EditTypeInterventionSettings from '../shared/components/settings/EditTypeInterventionSettings';
import AddDrivers from '../shared/components/drivers/AddDrivers';
import EditDrivers from '../shared/components/drivers/EditDrivers';
import AddTrucs from '../shared/components/trucks/AddTrucs';
import Matriculation from '../pages/Matriculation';
import VehicleFile from '../shared/components/trucks/matriculation/VehicleFile';
import Refueling from '../shared/components/trucks/matriculation/Refueling';
import TechnicalControl from '../shared/components/trucks/matriculation/TechnicalControl';
import AddTechnicalControl from '../shared/components/trucks/matriculation/AddTechnicalControl';
import AddConstructionSite from '../shared/components/ConstructionSites/AddConstructionSites';
import EditConstructionSite from '../shared/components/ConstructionSites/ConstructionSitesNotFinish/EditConstructionSites';
import PreviewConstructionSite from '../shared/components/ConstructionSites/ConstructionSitesFinish/PreviewConstructionSites';
import PreviewDoc from '../shared/components/ConstructionSites/ConstructionSitesFinish/PreviewDoc';
import Materials from '../shared/components/settings/Materials';
import AddMaterials from '../shared/components/settings/materials/AddMaterials';
import EditMaterials from '../shared/components/settings/materials/EditMaterials';
import Preview from '../shared/components/drivers/Preview';
import ListRevision from '../shared/components/trucks/matriculation/ListRevision';
// import ListFollow from '../shared/components/trucks/matriculation/ListFollow';
import TruckPreview from '../shared/components/trucks/matriculation/Preview';
import AddRevision from '../shared/components/trucks/matriculation/AddRevision';
import AddFollow from '../shared/components/trucks/matriculation/AddFollow';
import ListAdress from '../shared/components/clients/address/ListAdress';
import EditAddress from '../shared/components/clients/address/EditAddress';
import AddAddress from '../shared/components/clients/address/AddAddress';
import ListPricesDay from '../shared/components/settings/ListPricesDay';
import AddPicesDay from '../shared/components/settings/PricesDay/AddPicesDay';
import EditPricesDay from '../shared/components/settings/PricesDay/EditPricesDay';
import Analyse from '../shared/components/clients/Analyse';

import Cgu from '../pages/Cgu';
import AddAnomalie from '../shared/components/anomalie/AddAnomalie';
import Notfound from '../pages/NotFound';
import Order from '../pages/Order';
import { MiddlewareType } from '../services/middlewares/middlewareType';
import { user } from '../services/middlewares/user.js';
import Customers from '../shared/components/Customers/Customers.jsx';
import EditCustomer from '../shared/components/Customers/EditCustomer.jsx';
import AddCustomer from '../shared/components/Customers/AddCustomer.jsx';
import WorkSite from '../pages/WorkSite.jsx';
import AddWorksite from '../shared/components/worksite/AddWorksite.jsx';
import EditWorksite from '../shared/components/worksite/EditWorksite.jsx';
import PurchaseOrder from '../shared/components/order/PurchaseOrder.jsx';
import PrefillPurchaseOrder from '../shared/components/order/PrefillPurchaseOrder.jsx';
import EditPrefillPurshaseOrder from '../shared/components/order/EditPrefillPurshaseOrder.jsx';
import WorkOrder from '../shared/components/order/WorkOrder.jsx';
import PrefillOrder from '../shared/components/order/PrefillOrder.jsx';
import Suppliers from '../pages/Suppliers.jsx';
import EditSuppliers from '../shared/components/suppliers/EditSuppliers.jsx';
import AddSuppliers from '../shared/components/suppliers/AddSuppliers.jsx';
import AllCoupons from '../pages/AllCoupons.jsx';
import AllCouponsOrders from '../shared/components/allCoupons/AllCouponsOrders.jsx';
import AllCouponsInterventions from '../shared/components/allCoupons/AllCouponsInterventions.jsx';
import AllCtmCouponsInterventions from '../shared/components/allCoupons/AllCtmCouponsInterventions.jsx';
import PdfPreview from '../shared/components/order/PdfPreview.jsx';
import PdfPreIntervCoupon from '../shared/components/order/PdfPreIntervCoupon.jsx';
import EditPrefillWorkOrder from '../shared/components/order/EditPrefillWorkOrder.jsx';
import PdfPurchaseOrders from '../shared/components/order/PdfPurchaseOrders.jsx';
import PdfWorkOrders from '../shared/components/order/PdfWorkOrders.jsx';
import CallForTenders from '../pages/CallsForTenders.jsx';
import Trainings from '../pages/Trainings.jsx';
import AddTraining from '../shared/components/trainings/AddTraining.jsx';
import EditTraining from '../shared/components/trainings/EditTraining.jsx';
import Prestataires from '../pages/Prestataires.jsx';
import AddPrestataire from '../shared/components/providers/AddPrestataire.jsx';
import EditPrestataire from '../shared/components/providers/EditPrestataire.jsx';
import Bills from '../pages/Bills.jsx';
import BillPreview from '../shared/components/bills/BillPreview.jsx';
import Contraventions from '../shared/components/trucks/matriculation/Contraventions.jsx';
import Expenses from '../shared/components/trucks/matriculation/Expenses.jsx';
import Breakdowns from '../shared/components/trucks/matriculation/BreakDowns.jsx';
import AddContravention from '../shared/components/trucks/matriculation/AddContravention.jsx';
import EditContravention from '../shared/components/trucks/matriculation/EditContravention.jsx';
import ContraventionPreview from '../shared/components/trucks/matriculation/ContraventionPreview.jsx';
import AddBreakdown from '../shared/components/trucks/matriculation/AddBreakdown.jsx';
import EditBreakdown from '../shared/components/trucks/matriculation/EditBreakdown.jsx';
import AddExpenses from '../shared/components/trucks/matriculation/AddExpenses.jsx';
import EditExpenses from '../shared/components/trucks/matriculation/EditExpenses.jsx';
import BreakdownPreview from '../shared/components/trucks/matriculation/BreakdownPreview.jsx';
import ListVoiceNotes from '../shared/components/voicenote/ListVoiceNotes.jsx';
import AddVoiceNote from '../shared/components/voicenote/AddVoiceNote.jsx';

const Registed = () => {
  //Global store
  const setRoleUsers = useStoreActions((state) => state.userData.setRoleUsers);
  const setCategories = useStoreActions((state) => state.userData.setCategories);
  const setTypeCompanies = useStoreActions((state) => state.userData.setTypeCompanies);
  const setcurrentUser = useStoreActions((state) => state.userData.setcurrentUser);

  useEffect(() => {
    MiddlewareType.getListRoleUser().then((res) => {
      res.statut && setRoleUsers(res.data);
    });
    MiddlewareType.getListCategory().then((res) => {
      res.statut && setCategories(res.data);
    });
    MiddlewareType.getListTypeCompanies().then((res) => {
      res.statut && setTypeCompanies(res.data);
    });
    Auth.currentAuthenticatedUser().then((res) => {
      user.getUser(res.username).then((res) => {
        if (res.statut) {
          setcurrentUser(res.data);
        }
      });
    });
  }, []);

  return (
    <>
      <Routes>
        <Route
          path="/politique-de-confidentialite&mentions-legales"
          element={<Cgu />}
        />
        <Route
          path="/"
          element={<Container />}
        >
          <Route
            path="/"
            element={<Dashboard />}
          />
          <Route path="bills">
            <Route
              index
              element={<Bills />}
            />
            <Route
              path="preview/:idBill"
              element={<BillPreview />}
            />
          </Route>
          <Route path="administrators">
            <Route
              index
              element={<Administrators />}
            />
            <Route
              path="edit/:idAdmin"
              element={<EditAdministrators />}
            />
            <Route
              path="add"
              element={<AddAdministrators />}
            />
          </Route>
          <Route path="collaborators">
            <Route
              index
              element={<Collaborators />}
            />
            <Route
              path="edit/:idCollaborator"
              element={<EditCollaborators />}
            />
            <Route
              path="add"
              element={<AddCollaborators />}
            />
            <Route
              path="preview/:idDriver"
              element={<Preview />}
            />
          </Route>
          <Route path="clients">
            <Route
              index
              element={<Customers />}
            />
            <Route
              path="edit/:id"
              element={<EditCustomer />}
            />
            <Route
              path="add"
              element={<AddCustomer />}
            />
            <Route path="worksite/:idCustomer/*">
              <Route
                index
                element={<WorkSite />}
              />
              <Route
                path="add"
                element={<AddWorksite />}
              />
              <Route
                path="edit/:idWorksite"
                element={<EditWorksite />}
              />
              <Route
                path="order/:idWorksite"
                element={<Order />}
              >
                <Route
                  path="purchaseorder"
                  element={<PurchaseOrder />}
                />
                <Route
                  path="purchaseorder/add"
                  element={<PrefillPurchaseOrder />}
                />
                <Route
                  path="purchaseorder/edit/:id"
                  element={<EditPrefillPurshaseOrder />}
                />
                <Route
                  path="purchaseorder/:idpurchaseorder"
                  element={<PdfPurchaseOrders />}
                />
                <Route
                  path="workorder"
                  element={<WorkOrder />}
                />
                <Route
                  path="workorder/add"
                  element={<PrefillOrder />}
                />
                <Route
                  path="workorder/edit/:idworkorder"
                  element={<EditPrefillWorkOrder />}
                />
                <Route
                  path="workorder/:idworkorder"
                  element={<PdfWorkOrders />}
                />
                <Route
                  path="workorder/pre/:idworkorder"
                  element={<PdfPreIntervCoupon />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="suppliers">
            <Route
              index
              element={<Suppliers />}
            />
            <Route
              path="edit/:idSupplier"
              element={<EditSuppliers />}
            />
            <Route
              path="add"
              element={<AddSuppliers />}
            />
          </Route>
          <Route path="/drivers">
            <Route
              index
              element={<Drivers />}
            />
            <Route
              path="add"
              element={<AddDrivers />}
            />
            <Route
              path="edit/:idDriver"
              element={<EditDrivers />}
            />
            <Route
              path="preview/:idDriver"
              element={<Preview />}
            />
          </Route>
          <Route
            path="/all-coupons"
            element={<AllCoupons />}
          >
            <Route
              path="purchaseOrders"
              element={<AllCouponsOrders />}
            />
            <Route
              path="intervention"
              element={<AllCouponsInterventions />}
            />
            <Route
              path="interventionCtm"
              element={<AllCtmCouponsInterventions />}
            />
          </Route>
          <Route
            path="/classify-order"
            element={<ClassifyOrder />}
          >
            <Route
              path="orderCoupon"
              element={<ListClassifyOrderCoupon />}
            />
            <Route
              path="interventioncoupon"
              element={<ListClassifyInterventionCoupon />}
            />
          </Route>
          <Route
            path="/notes-vocales"
            element={<ListVoiceNotes />}
          />
          <Route
            path="/notes-vocales/add"
            element={<AddVoiceNote />}
          />
          <Route
            path="/settings"
            element={<Settings />}
          >
            <Route
              path="purchase-order"
              element={<PurchaseOrderSettings />}
            />
            <Route
              path="purchase-order/add"
              element={<AddNewMaterialSettings />}
            />
            <Route
              path="purchase-order/edit/:id"
              element={<EditMaterialSettings />}
            />
            <Route
              path="work-order"
              element={<WorkOrderSettings />}
            />
            <Route
              path="work-order/add"
              element={<AddTypeInterventionSettings />}
            />
            <Route
              path="work-order/edit/:id"
              element={<EditTypeInterventionSettings />}
            />
          </Route>
          <Route path="pdf-preview">
            <Route
              index
              element={<PdfPreview />}
            />
            <Route
              path="order/:idpurchaseorder"
              element={<PdfPurchaseOrders />}
            />
            <Route
              path="intervention/:idworkorder"
              element={<PdfWorkOrders />}
            />
            <Route
              path="Pre-intervention/:idworkorder"
              element={<PdfPreIntervCoupon />}
            />
          </Route>
          <Route path="trainings">
            <Route
              index
              element={<Trainings />}
            />
            <Route
              path="add"
              element={<AddTraining />}
            />
            <Route
              path="edit/:idTraining"
              element={<EditTraining />}
            />
          </Route>
          <Route path="prestataires">
            <Route
              index
              element={<Prestataires />}
            />
            <Route
              path="add"
              element={<AddPrestataire />}
            />
            <Route
              path="edit/:idPrestataire"
              element={<EditPrestataire />}
            />
            <Route path="worksite/:idPrestataire/*">
              <Route
                index
                element={<WorkSite />}
              />
              <Route
                path="add"
                element={<AddWorksite />}
              />
              <Route
                path="edit/:idWorksite"
                element={<EditWorksite />}
              />
              <Route
                path="order/:idWorksite"
                element={<Order />}
              >
                <Route
                  path="purchaseorder"
                  element={<PurchaseOrder />}
                />
                <Route
                  path="purchaseorder/add"
                  element={<PrefillPurchaseOrder />}
                />
                <Route
                  path="purchaseorder/edit/:id"
                  element={<EditPrefillPurshaseOrder />}
                />
                <Route
                  path="purchaseorder/:idpurchaseorder"
                  element={<PdfPurchaseOrders />}
                />
                <Route
                  path="workorder"
                  element={<WorkOrder />}
                />
                <Route
                  path="workorder/add"
                  element={<PrefillOrder />}
                />
                <Route
                  path="workorder/edit/:idworkorder"
                  element={<EditPrefillWorkOrder />}
                />
                <Route
                  path="workorder/:idworkorder"
                  element={<PdfWorkOrders />}
                />
                <Route
                  path="workorder/pre/:idworkorder"
                  element={<PdfPreIntervCoupon />}
                />
              </Route>
            </Route>
          </Route>
          <Route path="trucks">
            <Route
              index
              element={<Trucks />}
            />
            <Route
              path="add-technical-control/:idTruck"
              element={<AddTechnicalControl />}
            />
            <Route
              path="add-revision/:idTruck"
              element={<AddRevision />}
            />
            <Route
              path="add-follow/:idTruck"
              element={<AddFollow />}
            />
            <Route
              path="add-contravention/:idTruck"
              element={<AddContravention />}
            />
            <Route
              path="add-breakdown/:idTruck"
              element={<AddBreakdown />}
            />
            <Route
              path="add-expense/:idTruck"
              element={<AddExpenses />}
            />
            <Route
              path="preview/:idTruck"
              element={<TruckPreview />}
            />
            <Route
              path="add"
              element={<AddTrucs />}
            />
            <Route
              path="matriculation/:idTruck"
              element={<Matriculation />}
            >
              <Route
                index
                element={
                  <Navigate
                    replace
                    to="vehicle-file"
                  />
                }
              />
              <Route
                path="vehicle-file"
                element={<VehicleFile />}
              />
              <Route
                path="refueling"
                element={<Refueling />}
              />
              <Route
                path="technical-control"
                element={<TechnicalControl />}
              />
              <Route
                path="list-revision"
                element={<ListRevision />}
              />
              <Route path="contraventions">
                <Route
                  index
                  element={<Contraventions />}
                />
                <Route
                  path="contravention-preview/:idContravention"
                  element={<ContraventionPreview />}
                />
                <Route
                  path="edit-contravention/:idContravention"
                  element={<EditContravention />}
                />
              </Route>
              <Route path="breakdowns">
                <Route
                  index
                  element={<Breakdowns />}
                />
                <Route
                  path="breakdown-preview/:idBreakdown"
                  element={<BreakdownPreview />}
                />
                <Route
                  path="edit-breakdown/:idBreakdown"
                  element={<EditBreakdown />}
                />
              </Route>
              <Route path="expenses">
                <Route
                  index
                  element={<Expenses />}
                />
                <Route
                  path="edit-expenses/:idExpense"
                  element={<EditExpenses />}
                />
              </Route>
              <Route />
            </Route>
          </Route>
          <Route path="clients">
            <Route
              index
              element={<Clients />}
            />
            <Route
              path="add"
              element={<AddClients />}
            />
            <Route
              path="editNotFinish/:idConstructionSite"
              element={<EditConstructionSiteNotFinish />}
            />
            <Route
              path="editFinish/:idConstructionSite"
              element={<EditConstructionSiteFinish />}
            />
            <Route
              path="add-address/:idClient"
              element={<AddAddress />}
            />
            <Route
              path="edith-address/:idAddress"
              element={<EditAddress />}
            />
            <Route
              path="name-company/:idClient"
              element={<NameCompany />}
            >
              <Route
                index
                element={
                  <Navigate
                    replace
                    to="analyse"
                  />
                }
              />
              <Route
                path="analyse"
                element={<Analyse />}
              />
              <Route
                path="list-construction-sites-not-finish"
                element={<ListConstructionSiteNotFinish />}
              />

              <Route
                path="list-construction-sites-finish"
                element={<ListConstructionSiteFinish />}
              />

              <Route
                path="edit-client"
                element={<EditClient />}
              />

              <Route
                path="list-address"
                element={<ListAdress />}
              />
            </Route>
          </Route>
          <Route path="constructionSites">
            <Route
              index
              element={
                <Navigate
                  replace
                  to="constructionSites/list-construction-sites-not-finish"
                />
              }
            />
            <Route
              path="add-constructionSite"
              element={<AddConstructionSite />}
            />
            <Route
              path="edit-constructionSite/:idConstructionSite"
              element={<EditConstructionSite />}
            />
            <Route
              path="preview-constructionSite/:idConstructionSite"
              element={<PreviewConstructionSite />}
            />
            <Route
              path="preview/:idConstructionSite"
              element={<PreviewDoc />}
            />
            <Route
              path="constructionSites"
              element={<ConstructionSites />}
            >
              <Route
                path="list-construction-sites-not-finish"
                element={<ListConstructionSiteNotFinish />}
              />
              <Route
                path="list-construction-sites-finish"
                element={<ListConstructionSiteFinish />}
              />
            </Route>
          </Route>

          <Route
            path="/planning"
            element={<Planning />}
          />
          <Route path="settings">
            <Route
              index
              element={
                <Navigate
                  replace
                  to="settings/materials"
                />
              }
            />

            <Route
              path="add-materials"
              element={<AddMaterials />}
            />
            <Route
              path="edit-materials/:idMaterial"
              element={<EditMaterials />}
            />

            <Route
              path="add-prices-day"
              element={<AddPicesDay />}
            />
            <Route
              path="edit-prices-day/:idPricesDay"
              element={<EditPricesDay />}
            />

            <Route
              path="settings"
              element={<Settings />}
            >
              <Route
                path="materials"
                element={<Materials />}
              />
              <Route
                path="list-prices-day"
                element={<ListPricesDay />}
              />
            </Route>
          </Route>
          <Route path="/appels-d-offres">
            <Route
              index
              element={<CallForTenders />}
            />
          </Route>
          <Route path="/problems">
            <Route
              index
              element={<Anomalie />}
            />
            <Route
              path="add"
              element={<AddAnomalie />}
            />
          </Route>
        </Route>
        <Route
          path="/*"
          element={<Notfound />}
        />
      </Routes>
    </>
  );
};

export default Registed;

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import NavigationOrder from '../shared/layout/NavigationOrder';

const Order = () => {
  const currentWorkSite = useLocation().state;
  return (
    <div>
      <NavigationOrder currentWorkSite={currentWorkSite} />
      <Outlet currentWorkSite={currentWorkSite} />
    </div>
  );
};

export default Order;

import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const AdminMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST ADMIN
  //✨✨==========================================✨✨
  getListAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listRoleUsers(filter: {name: {contains: "Admin"}}) {
        items {
          name
          id
          users {
            items {
              first_name
              id
              last_name
              mail
              role_user {
                name
                id
              }
            }
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const listAdmin = query.data.listRoleUsers.items[0].users.items;
      const listSuperAdmin = query.data.listRoleUsers.items[1].users.items;
      const newList = [...listAdmin, ...listSuperAdmin];
      return {
        statut: true,
        data: newList,
      };
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ADMIN
  //✨✨==========================================✨✨
  getAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getUser(id: "${payload}") {
        first_name
        id
        last_name
        mail
        phone
        role_user {
          name
          id
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET ADMIN BY COGNITO
  //✨✨==========================================✨✨
  getAdminCognito: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getUser(id: "${payload}") {
        first_name
        id
        last_name
        mail
        phone
        role_user {
          name
          id
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getUser;
      getStoreActions().userData.setUserAdmin(myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE ADMIN
  //✨✨==========================================✨✨
  createAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createUser(
        input: {
          id: "${payload.id_cognito}",
          first_name: "${payload.firstName}",
          roleUserUsersId: "${payload.id_role_user}",
          last_name: "${payload.lastName}", 
          mail: "${payload.mail}", 
        })
        {
          id
          first_name
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE ADMIN
  //✨✨==========================================✨✨
  updateAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateUser(input: 
        {
          first_name: "${payload.firstName}", 
          id: "${payload.id}",
          roleUserUsersId: "${payload.id_role_user}",
          last_name: "${payload.lastName}",
          mail: "${payload.mail}"
        })  
        {
          id
          last_name
          first_name
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE ADMIN
  //✨✨==========================================✨✨
  deleteAdmin: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteUser(input: 
        {id: "${payload}"}) 
        {
        id
        first_name
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteUser;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default AdminMiddleware;


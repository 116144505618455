import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreActions } from 'easy-peasy';

// Import Components Chart JS
import LineChartJs from '../DashBoard/LineChartJs';
import FileUpload from '../FileUpload';

const DashboardHome = () => {
  // 🌱🌱  Etats (données)  🌱🌱
  const [clientNumber, setClientNumber] = useState(0);
  const [listBills, setListBills] = useState([]);
  const [totalPrices, setTotalPrices] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [filteredRevenue, setFilteredRevenue] = useState(0);

  // 🌱🌱  Init store(services)   🌱🌱
  const getBills = useStoreActions((actions) => actions.middleware.BillMiddleware.listBills);

  // 🌱🌱  Request call List Bills  🌱🌱
  const callListBills = async () => {
    try {
      const res = await getBills();
      console.log('res', res);
      // Trier les documents par date (du plus ancien au plus récent)
      res.sort((a, b) => new Date(a.date) - new Date(b.date));
      const clientName = res.map((data) => data?.worksite?.company?.name);
      const uniqueClientName = [...new Set(clientName)];
      setTotalPrices(res.reduce((total, data) => total + (data.amountTTC - data.amountRemaining), 0).toFixed(2));
      setTotalRemaining(res.reduce((total, data) => total + data.amountRemaining, 0).toFixed(2));
      setClientNumber(uniqueClientName.length);
      setListBills(res);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors de la récupération des documents 🤯');
    }
  };

  // 🌱🌱  Calcul du chiffre d'affaires selon le filtre  🌱🌱
  const calculateFilteredRevenue = ({ type, date }) => {
    if (!listBills.length) return 0;

    const targetDate = new Date(date);
    let revenue = 0;

    listBills.forEach((doc) => {
      const docDate = new Date(doc.date);
      if (
        (type === 'year' && docDate.getFullYear() === targetDate.getFullYear()) ||
        (type === 'month' &&
          docDate.getFullYear() === targetDate.getFullYear() &&
          docDate.getMonth() === targetDate.getMonth()) ||
        (type === 'day' &&
          docDate.getFullYear() === targetDate.getFullYear() &&
          docDate.getMonth() === targetDate.getMonth() &&
          docDate.getDate() === targetDate.getDate())
      ) {
        revenue += doc.amountTTC - doc.amountRemaining || 0;
        console.log('revenue', revenue);
      }
    });

    return revenue.toFixed(2);
  };

  // 🌱🌱  Gestion des filtres  🌱🌱
  const handleDateChange = (filter) => {
    const revenue = calculateFilteredRevenue(filter);
    setFilteredRevenue(revenue);
  };

  // 🌱🌱  Initialisation des données fictives  🌱🌱
  useEffect(() => {
    callListBills();
  }, []);

  // 🎉🎉  Render 🎉🎉
  return (
    <section className="flex flex-col h-full px-4 pt-4 md:pt-14 md:px-9 pb-9">
      {/* div Title  */}
      <div className="flex flex-col justify-between mb-4 border-b items-center border-gray-admin lg:flex-row">
        <h3 className="font-bold text-xl pb-[2.125rem]">Tableau de bord</h3>
        <FileUpload />
      </div>
      {/* div avec les 4 cards */}
      <div className="flex flex-wrap items-center">
        <div className="card-dashboard">
          <p className="text-sm font-bold">Chiffre d’affaires</p>
          <p className="text-4xl font-bold">{totalPrices} €</p>
        </div>
        <div className="card-dashboard">
          <p className="text-sm font-bold">Reste à payer</p>
          <p className="text-4xl font-bold">{totalRemaining} €</p>
        </div>
        <div className="card-dashboard">
          <p className="text-sm font-bold">Clients</p>
          <p className="text-4xl font-bold">{clientNumber}</p>
        </div>
        <div className="card-dashboard">
          <p className="text-sm font-bold">CA filtré</p>
          <p className="text-4xl font-bold">{filteredRevenue} €</p>
        </div>
        {/* Link to bills page */}
        <div className="flex justify-end w-full mt-4">
          <a href="/bills" className="text-primary-blue">Voir les factures</a>
        </div>
      </div>
      {/* Div Suivi des chantiers */}
      <div className="p-4 pb-12 mt-6 space-y-5 border rounded-lg border-1 border-gray-admin">
        <LineChartJs
          data={listBills}
          onChangeDate={handleDateChange}
        />
      </div>
    </section>
  );
};

export default DashboardHome;


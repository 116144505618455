import React from "react";
import ListCollaborators from "../shared/components/collaborators/ListCollaborators";

const Collaborator = () => {
  return (
    <div>
      <ListCollaborators />
    </div>
  );
};

export default Collaborator;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useEffect } from 'react';
import Routes from './Routes';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Provider = () => {
  console.log('ENV:', process.env.REACT_APP_ENV);

  //  🌱🌱 init store(services)   🌱🌱
  const connected = useStoreState((state) => state.route.connected);
  const userAdmin = useStoreState((state) => state.userData.userAdmin);
  const isAuth = useStoreActions((action) => action.middleware.Cognito.isAuth);
  const getAdminCognito = useStoreActions((actions) => actions.middleware.AdminMiddleware.getAdminCognito);
  const setConnected = useStoreActions((actions) => actions.route.setConnected);

  //  🌱🌱  verification auth user  🌱🌱
  const verifAuth = async () => {
    try {
      const res = await isAuth();
      getAdminCognito(res.username);
      setConnected(true);
    } catch (error) {
      setConnected(false);
      toast.info('Bienvenue');
    }
  };

  //  🌱🌱  post first effect    🌱🌱
  useLayoutEffect(() => {
    verifAuth();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <>
      {connected !== null && <Routes />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Provider;

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

const AddTypeInterventionSettings = () => {
  // Etats (données)
  const [typeIntervention, setTypeIntervention] = useState('');

  // Navigation
  const navigate = useNavigate();

  //  🌱🌱 init store(services)   🌱🌱
  const createTypeIntervention = useStoreActions(
    (actions) => actions.middleware.SettingsMiddleware.createTypeIntervention
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await createTypeIntervention(typeIntervention);
      console.log('DATA', res);
      if (res) {
        navigate('/settings/work-order');
      }
    } catch (error) {
      console.log('error', error);
      toast.error("❗️ Une erreur est survenue lors de la création de la nature d'intervention");
    }
  };
  return (
    <div className="flex flex-col h-screen">
      {/* Header */}
      <div className="pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Ajouter une autre nature d'intervention</h3>
      </div>

      {/* Form */}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col flex-grow"
      >
        <div className="flex mx-14 space-x-4 items-center py-5">
          <label htmlFor="name">Nature d'intervention</label>
          <input
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm"
            type="text"
            placeholder="Nature d'intervention"
            onChange={(e) => setTypeIntervention(e.target.value)}
          />
        </div>

        {/* Footer Buttons */}
        <div className="mt-auto py-4 flex justify-between items-center border-t border-white-admin px-14">
          <Link
            to="/settings/work-order"
            className="cursor-pointer border border-white-admin rounded-lg"
          >
            <button className="py-3 px-5 text-blue-admin">Annuler</button>
          </Link>
          <div className="cursor-pointer bg-yellow-admin rounded-lg">
            <button
              type="submit"
              className="py-3 px-5 text-blue-admin"
            >
              Ajouter une nature d'intervention
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTypeInterventionSettings;

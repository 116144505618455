import { thunk } from 'easy-peasy';
import AWS from 'aws-sdk';
import AWSIAM from './config/awsIAMConfigure';

//Config AWS SDK S3
const S3_BUCKET = AWSIAM.bucketName;
const REGION = AWSIAM.region;

AWS.config.update(AWSIAM);

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const FileS3 = {
  //✨✨==========================================✨✨
  //            UPLOAD FILE S3
  //✨✨==========================================✨✨

  uploadFile: thunk(async (actions, payload, { getStoreActions }) => {
    const params = {
      Body: payload?.file, // Contenu du fichier
      Bucket: S3_BUCKET, // Nom du bucket
      Key: payload?.nameFile, // Chemin et nom du fichier dans le bucket
      ContentType: payload?.contentType, // Type MIME du fichier
    };

    try {
      getStoreActions().global.setActivity(true);

      // Utiliser Promises au lieu du callback
      const result = await myBucket.putObject(params).promise();

      console.log('Upload réussi :', result);
      return true;
    } catch (err) {
      console.error("Erreur lors de l'upload S3 :", err);
      throw new Error(`Erreur d'upload : ${err.message}`);
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE FILE S3
  //✨✨==========================================✨✨
  deleteFile: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const params = {
      Bucket: S3_BUCKET,
      Key: payload?.nameFile,
    };
    getStoreActions().global.setActivity(true);
    return myBucket.deleteObject(params).send((err) => {
      if (err) {
        throw err;
      } else {
        return true;
      }
    });
  }),
};

export default FileS3;


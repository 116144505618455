import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const ExpensesMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST EXPENSES
  //✨✨==========================================✨✨
  getListExpenses: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listExpenses(filter: {truckExpenseId: {eq: "${payload}"}}) {
        items {
          id
          controlTechnique
          revision
          fuel
          breakdown
          contravention
          other
          truckExpenseId
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listExpenses.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET EXPENSES BY ID
  //✨✨==========================================✨✨
  getExpenses: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getExpense(id: "${payload}") {
        id
        controlTechnique
        revision
        fuel
        breakdown
        contravention
        other
        truckExpenseId
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getExpense;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE EXPENSES
  //✨✨==========================================✨✨
  createExpenses: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createExpense(input: {
        controlTechnique: "${payload?.controlTechnique ? payload?.controlTechnique : '0'}",
        revision: "${payload?.revision ? payload?.revision : '0'}", 
        fuel: "${payload?.fuel ? payload?.fuel : '0'}", 
        breakdown: "${payload?.breakdown ? payload?.breakdown : '0'}", 
        contravention: "${payload?.contravention ? payload?.contravention : '0'}", 
        other: "${payload?.other ? payload?.other : '0'}",
        truckExpenseId: "${payload?.idTruck}"
      }) 
      {
        id
      }
    }
    
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createExpense;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  // ✨✨==========================================✨✨
  //            UPDATE EXPENSES
  // ✨✨==========================================✨✨
  updateExpenses: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateExpense(input: {
        id: "${payload?.id}",
        controlTechnique: "${payload?.controlTechnique ? payload?.controlTechnique : '0'}",
        revision: "${payload?.revision ? payload?.revision : '0'}", 
        fuel: "${payload?.fuel ? payload?.fuel : '0'}", 
        breakdown: "${payload?.breakdown ? payload?.breakdown : '0'}", 
        contravention: "${payload?.contravention ? payload?.contravention : '0'}", 
        other: "${payload?.other ? payload?.other : '0'}",
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateExpense;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE EXPENSES
  //✨✨==========================================✨✨
  deleteExpenses: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteExpense(input: {id: "${payload}"}) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteExpense;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};
export default ExpensesMiddleware;


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

import DeleteIcon from '../../../assets/images/svg/delete-icon.svg';
import EditIcon from '../../../assets/images/svg/edit-icon.svg';
import Modal from '../Modal_2';
import WarningModal from '../../../assets/images/svg/warning-modal.svg';
import Table from '../Table';

const TypeInterventionSettings = () => {
  //  Etats (données)
  const [typesInterventions, setTypesInterventions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [idTypeIntervention, setIdTypeIntervention] = useState(null);

  //  🌱🌱 init store(services)   🌱🌱
  const getListTypeIntervention = useStoreActions(
    (actions) => actions.middleware.SettingsMiddleware.getListTypeIntervention
  );
  const deleteIntervention = useStoreActions((actions) => actions.middleware.SettingsMiddleware.deleteTypeIntervention);

  useEffect(() => {
    getTypeIntervention();
  }, []);

  const getTypeIntervention = async () => {
    try {
      const res = await getListTypeIntervention();
      setTypesInterventions(res);
    } catch (error) {
      console.log('error', error);
      toast.error("❗️ Une erreur est survenue lors du chargement de la liste des natures d'intervention");
    }
  };

  const handleDelete = (id) => {
    setIdTypeIntervention(id);
    setShowModal(true);
  };

  const deleteTypeIntervention = async () => {
    try {
      const res = await deleteIntervention(idTypeIntervention);
      setShowModal(false);
      const newArrayTypesInterventions = typesInterventions.filter(
        (typeIntervention) => typeIntervention.id !== res.id
      );
      setTypesInterventions(newArrayTypesInterventions);
    } catch (error) {
      console.log('error', error);
      toast.error("❗️ Une erreur est survenue lors de la suppression de la nature d'intervention");
    }
  };

  return (
    <>
      <section className="md:pt-8 px-4 md:px-9">
        <div>
          <div className=" pb-4 border-b border-white-admin flex items-center justify-between ">
            <h3 className="font-semibold">Nature de l'intervention</h3>
            <PrimaryButton
              title="+ Ajouter une autre nature d'intervention"
              style={'bg-white border border-gray-admin text-blue-admin'}
              link="add"
            />
          </div>
        </div>
        <div className="overflow-auto pt-14">
          <Table>
            <thead className="h-10">
              <tr>
                <th>Nature d'intervention</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {typesInterventions?.map((typeIntervention) => {
                return (
                  <tr
                    key={typeIntervention.id}
                    className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all"
                  >
                    <td>{typeIntervention.name}</td>
                    <td className="flex justify-end items-center pt-4 space-x-4">
                      <Link
                        to={`./edit/${typeIntervention.id} `}
                        state={typeIntervention}
                      >
                        <button className="w-8 p-2 border rounded-lg border-white-admin">
                          <img
                            src={EditIcon}
                            alt="edit icon"
                          />
                        </button>
                      </Link>
                      <button
                        className="w-8 p-2 border rounded-lg border-white-admin "
                        onClick={() => handleDelete(typeIntervention.id)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete icon"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={WarningModal}
              alt="warning icon"
            />
          </div>
          <h4 className="pl-5 pt-7">Suppresion de la nature d'intervention</h4>
          <p className="px-5 pt-2 text-sm text-gray-admin">
            Voulez-vous vraiment supprimer cette nature d'intervention de façon permanente ?
          </p>
          <div className="text-center pt-7">
            <button
              className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
              onClick={() => setShowModal(false)}
            >
              Annuler
            </button>
            <button
              className="px-10 py-3 text-white bg-red-error rounded-xl"
              onClick={() => {
                setShowModal(false);
                deleteTypeIntervention();
              }}
            >
              Supprimer
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TypeInterventionSettings;

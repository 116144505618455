import React, { useEffect, useState } from 'react';
import PrestatairesList from '../shared/components/providers/PrestatairesList';


const Prestataires = () => {
  return (
    <div>
      <PrestatairesList />
    </div>
  );
};

export default Prestataires;
import { useRef } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// data format
import { dateFormat } from '../../tools/manager';

const EditWorksite = () => {
  //init navigation
  const navigate = useNavigate();

  //url params
  const { idCustomer } = useParams();

  //Global store
  const categories = useStoreState((state) => state.userData.categories);
  const updateWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.updateWorksite);

  //init default values chantier
  const currentWorkSite = useLocation().state;

  //no data navigate to Marie©
  if (!currentWorkSite) {
    if (idCustomer) {
      window.location.href = `/clients/worksite/${idCustomer}`;
    } else {
      window.location.href = '/clients';
    }
  }

  //init ref
  const currentDate = useRef(dateFormat(currentWorkSite.date_planned));

  //Schema form
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    address: yup.string().required('Ce champ est requis.'),
    date: yup.date().required('Ce champ est requis.'),
  });

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit fun
  const onSubmit = async (data) => {
    const newDatas = { ...data, id: currentWorkSite.id };
    try {
      await updateWorksite(newDatas);
      navigate(-1);
    } catch (error) {
      console.log('error', error);
      toast.error("❌ Une erreur est survenue lors de la modification du chantier 🤯");
    }
  };

  //Render
  return (
    <section className="flex flex-col h-screen">
      {/* Header */}
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Modifier un chantier</h3>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-grow"
      >
        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="name">Nom du chantier</label>
          <input
            defaultValue={currentWorkSite.name}
            id="name"
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            placeholder="Nom du chantier"
            {...register('name')}
          />
          <p className="text-xs text-red-500">{errors.name?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label>Type de client</label>
          {categories.length &&
            categories.map((cat) => (
              <div key={cat.id}>
                <input
                  className="w-4 h-4 checkedFocus"
                  type="radio"
                  id={cat.name}
                  {...register('typeClient')}
                  value={cat.id}
                  defaultChecked={currentWorkSite.type.id === cat.id}
                />
                <label
                  className="ml-2"
                  htmlFor={cat.name}
                >
                  {cat.name}
                </label>
              </div>
            ))}
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="address">Adresse postale du chantier</label>
          <input
            defaultValue={currentWorkSite.address}
            id="address"
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            placeholder="22 rue du général de Gaulle 97236 Lamentin"
            {...register('address')}
          />
          <p className="text-xs text-red-500">{errors.address?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="date">Date de début du chantier</label>
          <input
            defaultValue={`${currentDate.current.year}-${currentDate.current.month}-${currentDate.current.day}`}
            id="date"
            type="date"
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            placeholder="22 rue du général de Gaulle 97236 Lamentin"
            {...register('date')}
          />
          <p className="text-xs text-red-500">{errors.address?.message}</p>
        </div>

        <div className="mt-auto py-4 flex justify-between items-center border-t border-white-admin px-14">
          <div
            onClick={() => navigate(-1)}
            className="px-5 py-3 border rounded-lg cursor-pointer text-blue-admin border-gray-admin"
          >
            Annuler
          </div>
          <div className="rounded-lg cursor-pointer bg-yellow-admin">
            <button
              type="submit"
              className="px-5 py-3 text-blue-admin"
            >
              Enregistrer les modifications
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default EditWorksite;

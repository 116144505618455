import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './css/datePickers.css';
import { twMerge } from 'tailwind-merge';
import iconArrow from '../../assets/images/svg/down-arrow.svg';
import DatePicker, { CalendarContainer, registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import { parse, isValid, format } from 'date-fns';

registerLocale('fr', fr);

const DatePicker_date = ({
  selected = new Date(),
  minDate = undefined,
  onChange,
  styleInput = '',
  onManual = false,
}) => {
  const [myDate, setMyDate] = useState(selected);
  const [manualDate, setManualDate] = useState(format(new Date(), 'dd/MM/yyyy'));
  const [useManualInput, setUseManualInput] = useState(false);
  const [isInvalidFormat, setIsInvalidFormat] = useState(false);

  // Vérifie si la date est valide et renvoie une date par défaut si elle ne l'est pas
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  // Composant CustomInput pour l'affichage de la saisie manuelle
  const CustomInput = ({ value, onClick }) => (
    <button
      className={twMerge(`inputCt w-32 h-10 ${styleInput}`)}
      onClick={onClick}
    >
      {value}
      <img
        className="imgInput"
        src={iconArrow}
        alt="icon"
      />
    </button>
  );

  // Composant MyContainer pour la personnalisation du calendrier
  const MyContainer = ({ className, children }) => {
    return (
      <div className="boxCalendar">
        <CalendarContainer className={className}>
          <div style={{ position: 'relative' }}>{children}</div>
        </CalendarContainer>
      </div>
    );
  };

  // Met à jour la date avec la sélection du calendrier
  const handleChangeDate = (date) => {
    if (isValidDate(date)) {
      setMyDate(date);
      setManualDate(format(date, 'dd/MM/yyyy'));
      onChange(date);
    } else {
      setIsInvalidFormat(true);
    }
  };

  // Gère le changement de date via la saisie manuelle
  const handleManualDateChange = (e) => {
    const inputDate = e.target.value;
    setManualDate(inputDate);

    // Essaye de parser la date avec le format attendu 'dd/MM/yyyy'
    const parsedDate = parse(inputDate, 'dd/MM/yyyy', new Date());

    if (inputDate.length === 10 && isValid(parsedDate)) {
      setMyDate(parsedDate);
      onChange(parsedDate);
      setIsInvalidFormat(false);
    } else {
      setIsInvalidFormat(true);
    }
  };

  // Met à jour myDate lorsque selected change
  useEffect(() => {
    if (isValidDate(selected)) {
      setMyDate(selected);
      setManualDate(format(selected, 'dd/MM/yyyy'));
    } else {
      // Si selected est invalide, utilise la date actuelle ou une valeur par défaut
      const defaultDate = new Date();
      setMyDate(defaultDate);
      setManualDate(format(defaultDate, 'dd/MM/yyyy'));
    }
  }, [selected]);

  return (
    <>
      <div className="superBox mt-4">
        {onManual && (
          <div className="flex items-center mb-4">
            <label className="mr-2">Utiliser l'entrée manuelle</label>
            <input
              type="checkbox"
              checked={useManualInput}
              onChange={() => setUseManualInput(!useManualInput)}
            />
          </div>
        )}
        {useManualInput ? (
          <div>
            <input
              type="text"
              value={manualDate}
              onChange={handleManualDateChange}
              placeholder="12/12/2025"
              className="rounded-md border border-gray-300 p-2 text-sm"
            />
            {isInvalidFormat && (
              <p className="text-red-500 text-sm mt-1">Format de date invalide. Utilisez le format jj/mm/aaaa.</p>
            )}
          </div>
        ) : (
          <DatePicker
            selected={myDate}
            minDate={minDate}
            onChange={handleChangeDate}
            calendarContainer={MyContainer}
            dateFormat="dd/MM/yyyy"
            locale="fr"
            customInput={<CustomInput />}
          />
        )}
      </div>
    </>
  );
};

export default DatePicker_date;


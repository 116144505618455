import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

//import env
const urlServerFirebase = process.env.REACT_APP_FIREBASE_URL_SERVER;
// const apiKeyFirebase = process.env.REACT_APP_FIREBASE_API_KEY;

const middleware = {
  // ✨✨===============================================✨✨
  //            GET SECRET
  //✨✨===============================================✨✨
  getSecret: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query {
      getSecret(secretName: "${payload.secretName}") {
        code
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getSecret;
      return myData;
    } catch (error) {
      console.log('error getSecret:', error);
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨===============================================✨✨
  //            SET TOKEN NOTIFICATION
  //✨✨===============================================✨✨
  setTokenNotification: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {        
    // body request
    const graphqlQuery = `
      mutation {
        setTokenNotification(
          input: {
            token: "${payload}",
            user: "${getStoreState().userData.cognitoData.username}"
          }) {
          code
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.setTokenNotification;
      return myData;
    } catch (error) {
      console.log('error setTokenNotification:', error);
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  // ✨✨===============================================✨✨
  //            GET TOKEN NOTIFICATION
  //✨✨===============================================✨✨
  getTokenNotification: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query {
      getTokenNotification(input: "${payload}") {
        id
        token
        user
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getTokenNotification;
      console.log('myData', myData);
      
      return myData[0];
    } catch (error) {
      console.log('error getTokenNotification:', error);
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨===============================================✨✨
  //            SEND NOTIFICATION PUSH
  //✨✨===============================================✨✨
  sendNotificationPush: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    const { token, title, message } = payload;

    try {
      // Générer le token d'accès
      const accessToken = await actions.getSecret({
        secretName: process.env.REACT_APP_FIREBASE_SECRET_NAME,
      });      

      // Préparer les headers et le payload
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken.code}`,
      };

      const notificationPayload = {
        message: {
          token: token,
          notification: {
            title: `Sacarabany SARL - ${title}`,
            body: message,
          },
          webpush: {
            headers: {
              Urgency: 'high',
            },
          },
        },
      };

      // Effectuer la requête POST
      const response = await fetch(urlServerFirebase, {
        method: 'POST',
        headers,
        body: JSON.stringify(notificationPayload),
      });

      if (response.ok) {
        const result = await response.json();
        console.info('Notification envoyée :', result);
        return result;
      } else {
        const errorData = await response.json();
        console.error('Erreur d’envoi de notification :', errorData);
        throw new Error(errorData);
      }
    } catch (error) {
      console.error('Erreur lors de l’envoi de la notification :', error);
    }
  }),

};

export default middleware;


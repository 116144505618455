/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/style-prop-object */
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Modal from '../../Modal';
import DatePicker_date from '../../DatePicker_date';
import SoloInputForm from '../../SoloInputForm';
import { dateFormat } from '../../../tools/date';

//import icons
import Check from '../../../../assets/images/svg/check.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

const refShip = [{ name: 'Tournée' }, { name: 'Journée' }];

const EditListConstructionSiteNotFinish = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idConstructionSite } = useParams();

  //  🌱🌱 Init store(services)  🌱🌱
  const getListClients = useStoreActions((actions) => actions.middleware.ClientsMiddleware.getListClients);
  const getListMaterials = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.getListMaterials);
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);
  const getListPricesDays = useStoreActions((actions) => actions.middleware.PricesDayMiddleware.getListPricesDays);
  const getConstructionSite = useStoreActions((actions) => actions.middleware.ConstructionSiteMiddleware.getConstructionSite);
  const updateConstructionSite = useStoreActions((actions) => actions.middleware.ConstructionSiteMiddleware.updateConstructionSite);
  const deleteConstructionSite = useStoreActions((actions) => actions.middleware.ConstructionSiteMiddleware.deleteConstructionSite);
  const addPriceAddress = useStoreActions((actions) => actions.middleware.AddressMiddleware.addPriceAddress);
  const sendNotificationPush = useStoreActions((actions) => actions.middleware.Notification.sendNotificationPush);
  const createNotif = useStoreActions((actions) => actions.middleware.Notification.createNotif);

  //  🌱🌱  Init use Ref  🌱🌱
  const submitRefLivraison = useRef();
  const submitRefCommande = useRef();
  const submitRefCommande2 = useRef();
  const submitRefCommande3 = useRef();
  const formFull = useRef({});

  //  🌱🌱  Etats (données)  🌱🌱
  const [listClients, setListClients] = useState([]);
  const [listShipTypes] = useState(refShip);
  const [listMaterials, setListMaterials] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectClient, setselectClient] = useState('');
  const [selectAddress, setselectAddress] = useState('');
  const [listPricesDay, setListPricesDay] = useState([]);
  const [msgErrStaddres, setmsgErrStaddres] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [listeAddressSelect, setlisteAddressSelect] = useState([]);
  const [datePlanned, setdatePlanned] = useState(new Date());
  const [constructionSite, setConstructionSite] = useState({});
  const [inputPrice, setInputPrice] = useState(0);
  const [tonnage, setTonnage] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [shipType, setshipType] = useState('');

  //  🌱🌱  formulaire commande  🌱🌱
  const FormConstructionSiteShip = [
    {
      label: 'Créée le :',
      inputType: 'text',
      valueName: 'date',
      placeholder: 'XX . XX . XX',
      errorMsg: 'erreur date',
      required: false,
      halfWidth: true,
      disabled: true,
      defaultValue: dateFormat(constructionSite?.date).full,
      style: ' placeholder:text-blue-admin',
    },
    {
      label: 'Type de commande',
      inputType: 'select',
      valueName: 'shipType',
      options: listShipTypes,
      keyOptions: 'name',
      keyValues: 'name',
      errorMsg: 'Veuillez sélectionner un type de commande',
      halfWidth: true,
      required: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: constructionSite?.shipType,
      placeholder: '- Type -',
      onChange: true,
    },
  ];

  //  🌱🌱  formulaire Marchandises  🌱🌱
  const FormConstructionSiteMaterial = [
    {
      label: 'Marchandises',
      inputType: 'select',
      valueName: 'constructionSiteMarchandiseId',
      options: listMaterials,
      keyOptions: 'name',
      keyValues: 'id',
      errorMsg: 'choisir une marchandise',
      required: true,
      halfWidth: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: constructionSite?.marchandise?.id,
      placeholder: '- Marchandises -',
    },
  ];

  //  🌱🌱  formulaire livraison  🌱🌱
  const FormShip = [
    {
      label: 'Assigné à',
      inputType: 'select',
      valueName: 'driverConstructionSiteId',
      options: drivers,
      keyOptions: 'fullName',
      keyValues: 'id',
      errorMsg: 'Veuillez sélectionner un chauffeur',
      halfWidth: '1/2-full',
      required: true,
      style: ' placeholder:text-blue-admin',
      defaultValue: constructionSite?.driver?.id,
    },
  ];

  //  🌱🌱  formulaire type journée  🌱🌱
  const FormShipDay = [
    {
      label: 'Type de journée',
      inputType: 'select',
      valueName: 'constructionSiteModelDayId',
      options: listPricesDay,
      keyOptions: 'name',
      keyValues: 'price',
      errorMsg: 'Veuillez sélectionner un type de Journée',
      halfWidth: true,
      required: true,
      placeholder: '- Mode -',
      style: ' placeholder:text-blue-admin',
      onChange: true,
      defaultValue: constructionSite?.modelDay?.price,
    },
  ];

  //  🌱🌱  Request call List Admins  🌱🌱
  const callListClients = async () => {
    try {
      const res = await getListClients();
      const listClientData = res.map((el) => {
        return { ...el, fullName: `${el.firstName} ${el.lastName}` };
      });
      setListClients(listClientData);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des clients');
    }
  };

  //  🌱🌱  Request call List Material  🌱🌱
  const callListMaterials = async () => {
    try {
      const res = await getListMaterials();
      setListMaterials(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des marchandises');
    }
  };

  //  🌱🌱  Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      setDrivers(
        res.map((truck) => {
          return { ...truck, fullName: `${truck.firstName} ${truck.lastName}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱  Request call List prices day  🌱🌱
  const callListPricesDay = async () => {
    try {
      const res = await getListPricesDays();
      setListPricesDay(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des prix a la journée');
    }
  };

  //  🌱🌱  Request add price adress client  🌱🌱
  const updatePriceAdress = async (data) => {
    try {
      await addPriceAddress(data);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue lors de l'ajout du nouveau prix à l'adresse.");
    }
  };

  //  🌱🌱  Request call  constructionSite  🌱🌱
  const callConstructionSite = async () => {
    try {
      const res = await getConstructionSite(idConstructionSite);
      setConstructionSite(res);
      setselectClient(res?.client.id);
      setlisteAddressSelect(res?.client?.address?.items?.filter((ad) => ad.archived === false));
      setselectAddress(res?.address?.id);
      setdatePlanned(new Date(res?.datePlanned));
      setshipType(res?.shipType);
      setTotalPrice(res?.price);
      setInputPrice(res?.client?.address?.items.find((ad) => ad.id === res.constructionSiteAddressId)?.price);
      setTonnage(res?.quantity);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la commande');
    }
  };

  //  🌱🌱  Request update ConstructionSite  🌱🌱
  const updateProfileConstructionSite = async (data) => {
    try {
      await updateConstructionSite(data);
      sendDriverPush();
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la modification de la commade.');
    }
  };

  //  🌱🌱  Request delete ConstructionSite  🌱🌱
  const deleteProfileConstructionSite = async () => {
    try {
      await deleteConstructionSite(idConstructionSite);
      navigate(-1);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la commade.');
    }
  };

  //  🌱🌱 Request create notification (history)  🌱🌱
  const callcreateNotif = async (data) => {
    try {
      await createNotif(data);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la mise à jours de l'historique");
    }
  };

  //  🌱🌱  send notification push   🌱🌱
  const sendDriverPush = () => {
    const driverSelected = drivers.find((el) => el.id === formFull.current.driverConstructionSiteId);
    if (driverSelected.tokenFcm) {
      if (constructionSite.driver.id === undefined) {
        const newData = {
          title: 'Sacarabany SARL',
          message: `La commande n°${constructionSite.numero} vient d'être ajouté à votre liste de commade`,
          tokenFcm: driverSelected.tokenFcm,
        };
        sendNotificationPush(newData);
        callcreateNotif({
          ...newData,
          idUser: driverSelected.id,
          date: Date.now(),
          title: 'Commande',
        });
      } else {
        const currentDatePl = new Date(constructionSite.datePlanned).getTime();
        const DateFormPl = new Date(formFull.current.datePlanned).getTime();
        if (DateFormPl !== currentDatePl) {
          const newData = {
            title: 'Sacarabany SARL',
            message: `La commande n°${constructionSite.numero} prévu le ${
              dateFormat(constructionSite.datePlanned).stringFull
            } est déplacer au ${dateFormat(formFull.current.datePlanned).stringFull}`,
            tokenFcm: driverSelected.tokenFcm,
          };
          sendNotificationPush(newData);
          callcreateNotif({
            ...newData,
            idUser: driverSelected.id,
            date: Date.now(),
            title: 'Commande',
          });
        }
      }
    }
  };

  //  🌱🌱  manage select address  🌱🌱
  const handleChangeSelectAddress = (e) => {
    setselectAddress(e.target.value);
    setmsgErrStaddres(false);

    setInputPrice(
      listClients.find((cl) => cl?.id === selectClient)?.address?.items?.find((ad) => ad?.id === e.target.value)?.price
    );
  };

  //   🌱🌱  manage on change input FormConstructionSiteShip   🌱🌱
  const handleChangeFormConstructionSiteShip = (data) => {
    if (data?.shipType) {
      setshipType(data.shipType);
    }
    if (data?.shipType === '') {
      setshipType('');
    }
    setTonnage(0);
    formFull.current = {};
  };

  //   🌱🌱  manage on change input FormShipDay   🌱🌱
  const handleChangeFormShipDay = (e) => {
    setTotalPrice(e.constructionSiteModelDayId);
  };

  //   🌱🌱  Submit  🌱🌱
  const onSubmit = (value) => {
    formFull.current = { ...formFull.current, clientConstructionSiteId: selectClient };
    if (selectAddress === '') {
      setmsgErrStaddres(true);
    } else {
      formFull.current = { ...formFull.current, constructionSiteAddressId: selectAddress };
    }
    formFull.current = { ...formFull.current, price: totalPrice, quantity: tonnage };
    if (value.status) {
      formFull.current = {
        ...formFull.current,
        ...value.values,
        datePlanned: datePlanned,
        id: idConstructionSite,
      };
    }
  };

  //  🌱🌱  action btn submit  🌱🌱
  const submitForms = () => {
    listShipTypes.length && submitRefCommande.current.submit();
    shipType === refShip[0].name && listMaterials.length && submitRefCommande2.current.submit();
    shipType === refShip[1].name && listMaterials.length && submitRefCommande3.current.submit();
    drivers.length && submitRefLivraison.current.submit();

    if (shipType === refShip[1].name && formFull.current.constructionSiteModelDayId) {
      formFull.current = {
        ...formFull.current,
        constructionSiteModelDayId: listPricesDay.find((pr) => pr.price === formFull.current.constructionSiteModelDayId).id,
      };
    }

    if (Object.keys(formFull.current).length === 10) {
      if (
        listClients.find((cl) => cl?.id === selectClient)?.address?.items?.find((ad) => ad?.id === selectAddress)
          ?.price === undefined &&
        !!selectAddress &&
        shipType === refShip[0].name
      ) {
        if (inputPrice) {
          updateProfileConstructionSite(formFull.current);
          updatePriceAdress({
            id: selectAddress,
            price: inputPrice,
          });
        } else {
          formFull.current = {};
        }
      } else {
        updateProfileConstructionSite(formFull.current);
      }
    } else {
      formFull.current = {};
    }
  };

  //  🌱🌱  effect watch input price  🌱🌱
  useEffect(() => {
    if (shipType === refShip[0].name)
      if (inputPrice && tonnage >= 0) {
        setTotalPrice(inputPrice * tonnage);
      } else {
        setTotalPrice(0);
      }
  }, [inputPrice, tonnage, shipType]);

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListClients();
    callListMaterials();
    callListDriver();
    callConstructionSite();
    callListPricesDay();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="flex flex-col h-full px-4 pt-16 md:px-9">
      {/* div Title */}
      <div className="flex justify-between pb-4 mb-4 border-b items center border-gray-admin pt-11 md:pt-0">
        <h3 className="text-xl font-bold text-blue-admin">
          {constructionSite?.id && constructionSite?.client?.company} : commande N° {constructionSite?.id && constructionSite?.numero}
        </h3>
      </div>
      <div className="flex justify-between pb-4 mb-4 border-b items center border-gray-admin mt-11">
        <h2 className="text-lg font-bold ">Infos commande</h2>
      </div>
      {constructionSite.id && (
        <>
          {/* 2e formulaire  : Commande*/}
          <div className="max-w-[1100px] mx-4 md:mx-9">
            {!!listShipTypes.length && (
              <FormCustom
                data={FormConstructionSiteShip}
                resultSubmit={onSubmit}
                ref={submitRefCommande}
                onChange={handleChangeFormConstructionSiteShip}
              />
            )}
            <div className="mt-4">
              <label
                htmlFor="idadd"
                className="text-sm font-bold text-blue-admin"
              >
                Lieu de livraison
              </label>
              <select
                id="idadd"
                className={`h-14 rounded-xl inputFocus w-full pl-3 border mt-3 ${!msgErrStaddres ? '' : 'input_error'}`}
                onChange={handleChangeSelectAddress}
                defaultValue={constructionSite?.address?.id}
              >
                <option value="">-- selectionner une adresse --</option>
                {listeAddressSelect.map((add) => (
                  <option
                    key={add?.id}
                    value={add?.id}
                  >
                    {add?.address}
                  </option>
                ))}
              </select>
              <p className="text-red-error">{msgErrStaddres ? 'Veuillez sélectionner une adresse' : ''}</p>
              {listClients.find((cl) => cl?.id === selectClient)?.address?.items?.find((ad) => ad?.id === selectAddress)
                ?.price === undefined &&
                !!selectAddress &&
                shipType === refShip[0].name && (
                  <SoloInputForm
                    label="Ajouter un prix"
                    type="number"
                    placeholder="Ajouter tarification € / Tonne"
                    styleContainer={'md:w-[49%] mt-4'}
                    styleInput={'w-full mt-2'}
                    onChangeText={setInputPrice}
                    isError={!inputPrice}
                    msgError="Ajouter une tarification à cette nouvelle adresse"
                  />
                )}
            </div>
            {shipType === refShip[0].name && !!listMaterials.length && (
              <FormCustom
                data={FormConstructionSiteMaterial}
                resultSubmit={onSubmit}
                ref={submitRefCommande2}
              />
            )}
            {shipType === refShip[1].name && !!listPricesDay.length && (
              <FormCustom
                data={FormShipDay}
                resultSubmit={onSubmit}
                ref={submitRefCommande3}
                onChange={handleChangeFormShipDay}
              />
            )}
            <div className="flex flex-col md:flex-row md:w-[49%] justify-between">
              {shipType === refShip[0].name && (
                <>
                  <SoloInputForm
                    label="Tonnage"
                    type="number"
                    placeholder="T"
                    styleContainer={'md:w-[49%] mt-4'}
                    styleInput={'w-full mt-2'}
                    onChangeText={setTonnage}
                    isError={false}
                    defaultValue={constructionSite?.quantity}
                  />

                  <div className="mt-5  md:w-[49%] flex flex-col">
                    <label
                      className="text-sm font-bold text-blue-admin"
                      htmlFor="idprice"
                    >
                      Prix / Tonne
                    </label>
                    <input
                      id="idprice"
                      className="w-full pl-3 mt-2 border h-14 rounded-xl inputFocus "
                      type="number"
                      placeholder="€"
                      disabled={true}
                      value={parseInt(inputPrice)}
                    />
                  </div>
                </>
              )}
            </div>
            {totalPrice >= 0 && (
              <div className="flex flex-col w-1/4 mt-4">
                <label
                  htmlFor="idttprice"
                  className="text-sm font-bold text-blue-admin"
                >
                  Prix total
                </label>
                <input
                  id="idttprice"
                  className="p-2 mt-4 rounded-lg"
                  type="text"
                  disabled
                  value={`${totalPrice} €`}
                />
              </div>
            )}
          </div>
          <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-7 md:mx-9">
            <h3 className="text-xl font-bold ">Infos livraison</h3>
          </div>

          {/* 3e formulaire: Livraison*/}
          <div className="max-w-[1100px] pb-5 grow px-4 md:px-9">
            {!!drivers.length && (
              <FormCustom
                data={FormShip}
                resultSubmit={onSubmit}
                ref={submitRefLivraison}
              />
            )}

            <div className="w-full md:w-[49%] mt-4">
              <label className="text-sm font-bold text-blue-admin">Date de livraison souhaité</label>
              <DatePicker_date
                selected={datePlanned}
                minDate={new Date()}
                onChange={setdatePlanned}
                styleInput="inputFocus w-full h-14 rounded-xl inputFocus mt-3 mb-4 md:mb-0 pl-3 border"
              />
            </div>
          </div>
        </>
      )}
      {/* Div 2 boutons */}
      <div className="flex flex-col pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-4">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex-col space-y-3 md:space-x-3 fle lg:flex-row md:space-y-0">
          <SecondaryButton
            title="Annuler cette commande"
            onClick={() => setShowModalDelete(true)}
            style="w-full md:w-auto"
          />

          <PrimaryButton
            title="Enregister les modification"
            style="bg-blue-admin w-full md:w-auto"
            onClick={submitForms}
          />
        </div>
      </div>
      <Modal
        title="Modification commande enregistré"
        text={`Nous avons bien enregistré la modification de la commande N°${constructionSite?.numero}`}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>
      <Modal
        title="Annuler cette commande"
        text="Êtes-vous sûre de vouloir annuler cette commande? 
        Cette action est définitive. Vous n’aurez plus accès à cet commande."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileConstructionSite();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditListConstructionSiteNotFinish;


import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';

//import icons
import Check from '../../../assets/images/svg/check.svg';

const formAddSupplier = [
  {
    label: 'Nom du fournisseur',
    inputType: 'text',
    valueName: 'name',
    placeholder: 'Nom du fournisseur',
    errorMsg: 'Veuillez entrer le nom du fournisseur',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Siret',
    inputType: 'text',
    valueName: 'siret',
    placeholder: 'Siret',
    errorMsg: 'Veuillez entrer un numéro de Siret',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Adresse postale',
    inputType: 'text',
    valueName: 'address',
    placeholder: 'Adresse postale',
    errorMsg: 'Veuillez entrer une adresse postale',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Adresse mail de facturation',
    inputType: 'text',
    valueName: 'mail',
    placeholder: 'fournisseur@example.mq',
    errorMsg: 'Veuillez entrer une adresse mail de facturation',
    required: true,
    halfWidth: true,
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  },
  {
    label: 'Téléphone',
    inputType: 'tel',
    valueName: 'phone',
    placeholder: 'Téléphone',
    errorMsg: 'Veuillez entrer un numéro de téléphone valide',
    required: true,
    regex: /^[0-9]{10}$/,
  },
];

const AddSuppliers = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const createSupplier = useStoreActions((actions) => actions.middleware.SupplierMiddleware.createSupplier);
  const listCompaniesByName = useStoreActions((actions) => actions.middleware.CompanyMiddleware.listCompaniesByName);
  const getListTypeCompanies = useStoreActions((actions) => actions.middleware.TypeMiddleware.getListTypeCompanies);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [listTypeCompanies, setListTypeCompanies] = useState([]);

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      createProfileSupplier(value.values);
    }
  };

  //  🌱🌱  function getListTypeCompanies   🌱🌱
  const callGetListTypeCompanies = async () => {
    try {
      const res = await getListTypeCompanies();
      console.log('callGetListTypeCompanies', res);
      setListTypeCompanies(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des catégories de type de société');
    }
  };

  useEffect(() => {
    callGetListTypeCompanies();
  }, []);

  //  🌱🌱 Request create Suppliers Table  🌱🌱
  const createProfileSupplier = async (data) => {
    try {
      // Map type company array to get id
      const typeCompanySelected = listTypeCompanies.find((role) => role.name === 'fournisseur');
      const newData = { ...data, id_type_Company: typeCompanySelected?.id };
      const existingCompany = await listCompaniesByName(data.name);
      if (existingCompany.length > 0) {
        toast.error('❗️ Ce fournisseur existe déjà');
        return;
      }
      await createSupplier(newData);
      setShowModal(true);
    } catch (error) {
      console.log('createProfileSupplier', error);
      toast.error("❗️ Une erreur est survenue durant la création d'un nouvel fournisseur, veuillez réessayer.");
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9 ">
        <h3 className="font-bold text-xl ">Ajouter un nouveau fournisseur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formAddSupplier}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un nouveau fournisseur"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => {
            submitRef.current.submit();
          }}
        />
      </div>

      {/* Modal Add Supplier */}
      <Modal
        title="Nouveau fournisseur enregistré"
        text="Nous avons bien enregistré votre nouveau fournisseur. Vous le retrouverez dans la liste des fournisseurs et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddSuppliers;


import React, { useEffect, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';

//Import icons
import Check from '../../../assets/images/svg/check.svg';
import Warning from '../../../assets/images/svg/warning.svg';

const EditSuppliers = () => {
  //  🌱🌱  Etats (données)  🌱🌱
  const [supplier, setSupplier] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  Data Form Edit  🌱🌱
  const formEditSupplier = [
    {
      label: 'Nom du fournisseur',
      inputType: 'text',
      valueName: 'name',
      placeholder: 'Nom du fournisseur',
      errorMsg: 'Veuillez entrer le nom du fournisseur',
      required: true,
      halfWidth: true,
      defaultValue: supplier?.name,
    },
    {
      label: 'Siret',
      inputType: 'text',
      valueName: 'siret',
      placeholder: 'Siret',
      errorMsg: 'Veuillez entrer un numéro de Siret',
      required: true,
      halfWidth: true,
      defaultValue: supplier?.siret,
    },
    {
      label: 'Adresse postale',
      inputType: 'text',
      valueName: 'address',
      placeholder: 'Adresse postale',
      errorMsg: 'Veuillez entrer une adresse postale',
      required: true,
      halfWidth: true,
      defaultValue: supplier?.address,
    },
    {
      label: 'Adresse mail de facturation',
      inputType: 'text',
      valueName: 'mail',
      placeholder: 'fournisseur@example.mq',
      errorMsg: 'Veuillez entrer une adresse mail de facturation',
      required: true,
      halfWidth: true,
      regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
      defaultValue: supplier?.mail,
    },
    {
      label: 'Téléphone',
      inputType: 'tel',
      valueName: 'phone',
      placeholder: 'Téléphone',
      errorMsg: 'Veuillez entrer un numéro de téléphone valide',
      required: true,
      regex: /^[0-9]{10}$/,
      defaultValue: supplier?.phone,
    },
  ];

  //  🌱🌱 init store(services)  🌱🌱
  const getSupplier = useStoreActions((actions) => actions.middleware.SupplierMiddleware.getSupplier);
  const updateSupplier = useStoreActions((actions) => actions.middleware.SupplierMiddleware.updateSupplier);
  const deleteSupplier = useStoreActions((actions) => actions.middleware.SupplierMiddleware.deleteSupplier);
  const userAdmin = useStoreState((state) => state.userData.userAdmin);

  //  🌱🌱  Init Navigation
  const navigate = useNavigate();
  const { idSupplier } = useParams();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 Request call get Supplier  🌱🌱
  const callGetSupplier = async () => {
    try {
      const res = await getSupplier(idSupplier);
      setSupplier(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des fournisseurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetSupplier();
  }, []);

  //  🌱🌱  function updateProfileSupplier   🌱🌱
  const updateProfileSupplier = async (data) => {
    try {
      await updateSupplier(data);
      setShowModalUpdate(true);
    } catch (error) {
      console.log('updateProfileSupplier', error);

      toast.error('❗️ Une erreur est survenue durant la modification de votre supplierorateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function DeleteProfileSupplier   🌱🌱
  const deleteProfileSupplier = async () => {
    setShowModalDelete(false);
    try {
      await deleteSupplier(supplier);
      navigate(-1);
    } catch (error) {
      console.log('deleteSupplier', error);
      toast.error('❗️ Une erreur est survenue durant la suppression de votre fournisseur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function FormCustom   🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idSupplier,
      };
      updateProfileSupplier(newData);
    }
  };

  return (
    <section className="flex flex-col h-full md:pt-14">
      {/* div title */}
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-11 md:pt-0 md:mx-9">
        <h3 className="text-xl font-bold ">Modifier un fournisseur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {supplier?.id && (
          <FormCustom
            data={formEditSupplier}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          {supplier?.id && userAdmin.id !== supplier?.id && (
            <SecondaryButton
              title="Supprimer ce fournisseur"
              onClick={() => {
                setShowModalDelete(true);
              }}
            />
          )}
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-yellow-admin text-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur le profil du fournisseur ${supplier?.name}`}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>

      {/* Modal delete */}
      <Modal
        title="Supprimer un fournisseur"
        text="Êtes-vous sûre de vouloir supprimer ce profil ? 
        Cette action est définitive. Vous n’aurez plus accès à ce profil."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileSupplier();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditSuppliers;


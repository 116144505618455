import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import icons
import PDF from '../../../assets/images/svg/pdf.svg';


const PurchaseOrder = () => {
  // Etats (données)
  const [listCouponOrder, setListCouponOrder] = useState([]);
  const [listPreCouponOrder, setListPreCouponOrder] = useState([]);

  // Navigation
  const currentWorkSite = useLocation().state;

  //  🌱🌱 init store(services)   🌱🌱
  const getWorkSiteListOrderCoupon = useStoreActions(
    (actions) => actions.middleware.WorksiteMiddleware.getWorkSiteListOrderCoupon 
  );

  useEffect(() => {
    callWorksitesListOrderCoupon();
  }, []);

  const callWorksitesListOrderCoupon = async () => {
    try {
      const res = await getWorkSiteListOrderCoupon(currentWorkSite?.id);
      setListCouponOrder(res.filter((coupon) => !coupon.ctm));
      setListPreCouponOrder(res.filter((coupon) => coupon.ctm));
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors de la récupération de la liste des bons de commande 🤯');
    }
  };

  return (
    <section className="mx-14 mt-12 mb-5">
      <div>
        <h4 className="font-semibold">Bons pré-remplis</h4>
        <div className="flex mt-5 space-x-5">
          <ul className="flex flex-row flex-wrap gap-5 items-center mt-5 max-h-[400px] overflow-auto">
            <Link
              to="add"
              state={currentWorkSite}
            >
              <div className="w-[200px] flex flex-col items-center justify-center border border-gray-separate py-11 rounded-xl hover:bg-blue-50 transition-all">
                <div className="mb-5">
                  <p className="px-2 border border-gray-separate rounded-lg">+</p>
                </div>
                <p className=" text-blue-admin text-center">Pré-remplir un bon</p>
              </div>
            </Link>

            {listPreCouponOrder?.map((preCoupon) => {
              return (
                <Link
                  to={`edit/${preCoupon.id}`}
                  key={preCoupon.id}
                  state={currentWorkSite}
                >
                  <li className="w-[181px] flex flex-col items-center border border-gray-separate  pt-7 pb-5 rounded-xl hover:bg-blue-50 transition-all">
                    <div className="mb-5">
                      <img
                        src={PDF}
                        alt="pdf svg"
                      />
                    </div>
                    <h5 className="text-blue-admin font-bold">N° {preCoupon?.numero}</h5>
                    <p className="text-sm text-blue-admin">Fait par : {preCoupon?.name}</p>
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="border-t border-gray-separate my-10 pt-7">
        <h4 className="font-semibold">Bons collaborateurs</h4>
        <ul className="flex flex-row flex-wrap gap-5 items-center mt-5 max-h-[400px] overflow-auto">
          {listCouponOrder.length ? (
            listCouponOrder
              ?.sort((a, b) => b.numero - a.numero)
              .map((couponOrder) => {
                return (
                  <Link
                    to={`${couponOrder.id}`}
                    state={currentWorkSite}
                    key={couponOrder.id}
                  >
                    <li className="w-[200px] flex flex-col items-center border border-gray-separate pt-6 pb-5 rounded-xl hover:bg-blue-50 transition-all">
                      <div className="mb-5">
                        <img
                          src={PDF}
                          alt="pdf svg"
                        />
                      </div>
                      <h5 className="text-blue-admin font-bold">N° {couponOrder.numero}</h5>
                      <p className="text-sm text-blue-admin">Fait par : {couponOrder.name}</p>
                    </li>
                  </Link>
                );
              })
          ) : (
            <div className="mx-auto my-20">
              <p>Aucun bon de commande n’a été enregistré pour l’instant</p>
            </div>
          )}
        </ul>
      </div>
    </section>
  );
};

export default PurchaseOrder;

import AdminMiddleware from './AdminMiddleware';
import ClientsMiddleware from './ClientsMiddleware';
import DriverMiddleware from './DriverMiddleware';
import TruckMiddleware from './TruckMiddleware';
import MaterialsMiddleware from './MaterialsMiddleware';
import ControlTechniqueMiddleware from './ControlTechniqueMiddleware';
import FuelMiddleware from './FuelMiddleware';
import ConstructionSiteMiddleware from './ConstructionSiteMiddleware';
import RevisionMiddleware from './RevisionMiddleware';
import FollowMiddleware from './FollowMiddleware';
import AddressMiddleware from './AddressMiddleware';
import PricesDayMiddleware from './PricesDayMiddleware';
import MonitoringMiddleware from './MonitoringMiddleware';
import ProblemsMiddleware from './ProblemsMiddleware'
import Cognito from './Cognito';
import FileS3 from './FileS3';
import Notification from './Notification';
import CollaboratorMiddleware from './CollaboratorMiddleware';
import TypeMiddleware from './TypeMiddleware';
import SupplierMiddleware from './SupplierMiddleware';
import OrdersMiddleware from './OrdersMiddleware';
import CouponPreInterMiddleware from './CouponPreInterMiddleware';
import SettingsMiddleware from './SettingsMiddleware';
import CompanyMiddleware from './CompanyMiddleware';
import WorksiteMiddleware from './WorksiteMiddleware';
import DocumentMiddleware from './DocumentMiddleware';
import TrainingMiddleware from './TrainingMiddleware';
import BillMiddleware from './BillMiddleware';
import BreakdownMiddleware from './BreakdownMidlleware';
import ExpensesMiddleware from './ExpensesMidlleware';
import ContraventionMiddleware from './ContraventionMiddleware';
import VoiceNoteMiddleware from './VoiceNoteMiddleware';


const middleware = {
  AdminMiddleware,
  TypeMiddleware,
  CollaboratorMiddleware,
  SupplierMiddleware,
  OrdersMiddleware,
  CouponPreInterMiddleware,
  SettingsMiddleware,
  CompanyMiddleware,
  WorksiteMiddleware,
  BillMiddleware,
  BreakdownMiddleware,
  ExpensesMiddleware,
  ContraventionMiddleware,
  DocumentMiddleware,
  TrainingMiddleware,
  ClientsMiddleware,
  DriverMiddleware,
  TruckMiddleware,
  MaterialsMiddleware,
  ControlTechniqueMiddleware,
  FuelMiddleware,
  ConstructionSiteMiddleware,
  RevisionMiddleware,
  FollowMiddleware,
  AddressMiddleware,
  PricesDayMiddleware,
  MonitoringMiddleware,
  ProblemsMiddleware,
  Cognito,
  FileS3,
  Notification,
  VoiceNoteMiddleware
};
export default middleware;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const ConstructionSiteMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST ALL CONSTRUCTION SITE
  //✨✨==========================================✨✨
  getListAllConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listConstructionSites {
          items {
            numero
            id
            quantity
            price
            marchandise {
              id
              name
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST CONSTRUCTION SITE FINISH
  //✨✨==========================================✨✨
  getListConstructionSiteFinish: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listConstructionSites(filter: {statut: {eq: "done"}}) {
          items {
            id
            date
            numero
            shipType
            document
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST CONSTRUCTION SITE NOT FINISH
  //✨✨==========================================✨✨
  getListConstructionSiteNotFinish: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listConstructionSites(filter: {statut: {notContains: "done"}}) {
          items {
            id
            date
            numero
            shipType
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST CONSTRUCTION SITE FINISH BY CLIENT
  //✨✨============================================✨✨
  getListConstructionSiteFinishByClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listConstructionSites(filter: {statut: {eq: "done"}, clientConstructionSiteId: {eq: "${payload}"}}) {
          items {
            id
            date
            numero
            shipType
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST CONSTRUCTION SITE NOT FINISH BY CLIENT
  //✨✨============================================✨✨
  getListConstructionSiteNotFinishByClient: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
        listConstructionSites(filter: {statut: {notContains: "done"}, clientConstructionSiteId: {eq: "${payload}"}}) {
          items {
            id
            date
            numero
            shipType
            address {
              address
            }
            client {
              id
              firstName
              lastName
              company
            }
            marchandise {
              id
              name
            }
            datePlanned
            driver {
              id
              firstName
              lastName
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST CONSTRUCTION SITE BY DATE
  //✨✨============================================✨✨
  getListConstructionSiteByDate: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listConstructionSites(filter: {datePlanned: {contains: "${payload?.month} ${payload?.day} ${payload?.year}"}}) {
        items {
          id
          date
          numero
          shipType
          statut
          address {
            address
          }
          client {
            id
            firstName
            lastName
            company
            tokenFcm
          }
          marchandise {
            id
            name
          }
          datePlanned
          driver {
            id
            firstName
            lastName
            tokenFcm
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET LIST CONSTRUCTION SITE BY DATE BY DRIVER
  //✨✨============================================✨✨
  getListConstructionSiteByDateByDriver: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listConstructionSites(filter: {datePlanned: {contains: "${payload?.month} ${payload?.day} ${payload?.year}"}, and: {driverConstructionSiteId: {eq: "${payload?.idDriver}"}}}) {
        items {
          id
          date
          numero
          shipType
          address {
            address
          }
          client {
            id
            firstName
            lastName
            company
            tokenFcm
          }
          marchandise {
            id
            name
          }
          datePlanned
          driver {
            id
            firstName
            lastName
            tokenFcm
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listConstructionSites.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            GET CONSTRUCTION SITE
  //✨✨============================================✨✨
  getConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getConstructionSite(id: "${payload}") {
        id
        date
        name
        numero
        price
        document
        constructionSiteAddressId
        constructionSiteModelDayId
        modelDay {
          id
          price
          name
        }
        client {
          id
          firstName
          lastName
          company
          address {
            items {
              id
              address
              price
              archived
            }
          }
        }
        marchandise {
          id
          name
        }
        datePlanned
        driver {
          id
          firstName
          lastName
          tokenFcm
        }
        statut
        shipType
        quantity
        address {
          id
          address
          price
        }
        driver {
          id
          firstName
          lastName
        }
        datePlanned
        client {
          id
          firstName
          lastName
          company
          address {
            items {
              id
              address
            }
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getConstructionSite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            CREATE CONSTRUCTION SITE
  //✨✨============================================✨✨

  creatConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    console.log("test");
    const graphqlQuery = `
    mutation MyMutation {
        createConstructionSite(input: {
            clientConstructionSiteId: "${payload?.clientConstructionSiteId}", 
            name: "${payload?.name}",
            date: "${payload?.date}", 
            datePlanned: "${payload?.datePlanned}", 
            numero: "${payload?.numero}", 
            constructionSiteAddressId: "${payload?.constructionSiteAddressId}", 
            constructionSiteMarchandiseId: "${payload?.constructionSiteMarchandiseId || ''}", 
            shipType: "${payload?.shipType}", 
            price: "${payload?.price}", 
            quantity: "${payload?.quantity}", 
            driverConstructionSiteId: "${payload?.driverConstructionSiteId || ''}",
            constructionSiteModelDayId: "${payload?.constructionSiteModelDayId || ''}",
            statut: "en cours",
            step: "0"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createConstructionSite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            UPDATE CONSTRUCTION SITE
  //✨✨============================================✨✨

  updateConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateConstructionSite(input: {
            id: "${payload?.id}", 
            name: "${payload?.name}",
            clientConstructionSiteId: "${payload?.clientConstructionSiteId}", 
            datePlanned: "${payload?.datePlanned}", 
            constructionSiteAddressId: "${payload?.constructionSiteAddressId}", 
            constructionSiteMarchandiseId: "${payload?.constructionSiteMarchandiseId || ''}",
            shipType: "${payload?.shipType}", 
            price: "${payload?.price}", 
            quantity: "${payload?.quantity}", 
            driverConstructionSiteId: "${payload?.driverConstructionSiteId}",
            constructionSiteModelDayId: "${payload?.constructionSiteModelDayId || ''}",
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateConstructionSite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            UPDATE MOVE CONSTRUCTION SITE
  //✨✨============================================✨✨

  updateMoveConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateConstructionSite(input: {
            id: "${payload?.id}", 
            datePlanned: "${payload?.datePlanned}"
        }) 
        {
          id
        }
      }
    `;
    // getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateConstructionSite;
      return myData;
    } finally {
      // getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            UPDATE DRIVER CONSTRUCTION SITE
  //✨✨============================================✨✨

  updateDriverConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        updateConstructionSite(input: {
            id: "${payload?.id}", 
            driverConstructionSiteId: "${payload?.driverConstructionSiteId}"
        }) 
        {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateConstructionSite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨============================================✨✨
  //            DELETE CONSTRUCTION SITE
  //✨✨============================================✨✨

  deleteConstructionSite: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        deleteConstructionSite(input: {id: "${payload}"}) {
          id
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteConstructionSite;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default ConstructionSiteMiddleware;


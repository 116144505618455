import React from 'react';
import ListTypeInterventionSettings from './ListTypeInterventionSettings';

const WorkOrderSettings = () => {
  return (
    <div>
      <ListTypeInterventionSettings />
    </div>
  );
};

export default WorkOrderSettings;

import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { typeFile } from '../../tools/file';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';
import DatePicker_date from '../../components/DatePicker_date';

//import icons
import Check from '../../../assets/images/svg/check.svg';
import FileUpload from '../../../assets/images/svg/file-upload.svg';
import Download from '../../../assets/images/svg/download.svg';

//Data option
const statutOptions = [{ name: 'Electricité' }, { name: 'Climatisation' }, { name: 'Electricité / Climatisation' }];
const isDriverOptions = [{ name: 'Oui' }, { name: 'Non' }];

const formAddCollaborator = [
  {
    label: 'Prénom',
    inputType: 'text',
    valueName: 'firstName',
    placeholder: 'Prénom',
    errorMsg: 'Veuillez entrer un prénom',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Nom',
    inputType: 'text',
    valueName: 'lastName',
    placeholder: 'Nom',
    errorMsg: 'Veuillez entrer un nom',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Téléphone',
    inputType: 'tel_custom',
    valueName: 'phone',
    placeholder: 'Téléphone',
    errorMsg: 'Veuillez entrer un numéro de téléphone valide',
    required: true,
    regex: /^\+?[0-9]{1,4}[0-9]{9,10}$/,
  },

  {
    label: 'Mot de passe',
    inputType: 'password',
    valueName: 'password',
    placeholder: 'Mot de passe',
    errorMsg:
      'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.',
    regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/,
    required: true,
    halfWidth: true,
  },

  {
    label: 'Domaine de compétence',
    inputType: 'select',
    valueName: 'statut',
    options: statutOptions,
    keyOptions: 'name',
    keyValues: 'name',
    errorMsg: 'Veuillez choisir le statut',
    halfWidth: true,
    required: true,
    style: 'max-w-[318px] flex',
  },
  {
    label: 'Est-ce que ce collaborateur est chauffeur ?',
    inputType: 'select',
    valueName: 'isDriver',
    options: isDriverOptions,
    keyOptions: 'name',
    keyValues: 'name',
    errorMsg: 'Veuillez choisir si ce collaborateur est chauffeur',
    halfWidth: true,
    required: true,
    style: 'max-w-[318px] flex',
  },
];

const AddCollaborators = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const createCollaborator = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.createCollaborator);
  const getListCategory = useStoreActions((actions) => actions.middleware.TypeMiddleware.getListCategory);
  const getListRoleUser = useStoreActions((actions) => actions.middleware.TypeMiddleware.getListRoleUser);
  const createCollaboratorUser = useStoreActions((action) => action.middleware.Cognito.createCollaboratorUser);
  const setCollaboratorUsername = useStoreActions((action) => action.middleware.Cognito.setCollaboratorUsername);
  const setCollaboratorPhoneNumber = useStoreActions((action) => action.middleware.Cognito.setCollaboratorPhoneNumber);
  const setCollaboratorPassword = useStoreActions((action) => action.middleware.Cognito.setCollaboratorPassword);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState([]);
  const [roleUser, setRoleUser] = useState([]);
  const [selectedFileVm, setSelectedFileVm] = useState(null);
  const [msgErrorDocVm, setMsgErrorDocVm] = useState(false);
  const [msgErrorInputVm, setMsgErrorInputVm] = useState(false);
  const [dateVm, setDateVm] = useState('');

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`vm-${Date.now()}`).current;

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      createCognitoCollaborator(value.values);
    }
  };

  //  🌱🌱  function getListRoleUser   🌱🌱
  const callGetListRoleUser = async () => {
    try {
      const res = await getListRoleUser();
      console.log('callGetListRoleUser', res);
      setRoleUser(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des rôles');
    }
  };

  //  🌱🌱  function getListCategory   🌱🌱
  const callGetListCategory = async () => {
    try {
      const res = await getListCategory();
      console.log('callGetListCategory', res);
      setCategory(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des catégories');
    }
  };

  useEffect(() => {
    callGetListRoleUser();
    callGetListCategory();
  }, []);

  //  🌱🌱 Request create Collaborators Table  🌱🌱
  const createProfileCollaborator = async (data) => {
    try {
      // Map roles user array and category array to get id
      const roleUserSelected = roleUser.find((role) => role.name === 'collaborateur');
      const categorySelected = category.find((type) => type.name === data.statut);
      const newData = { ...data, id_role_user: roleUserSelected?.id, id_type: categorySelected?.id };
      await createCollaborator(newData);
      setShowModal(true);
    } catch (error) {
      console.log('createProfileCollaborator', error);

      toast.error("❗️ Une erreur est survenue durant la création d'un nouvel collaborateur, veuillez réessayer.");
    }
  };

  //  🌱🌱 Request Collaborators confirme password 🌱🌱
  const confirmPasswordCollaborator = async (data) => {
    try {
      await setCollaboratorPassword(data);
      createProfileCollaborator(data);
    } catch (error) {
      console.log('confirmPasswordCollaborator', error);

      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau collaborateur, veuillez réessayer.");
    }
  };

  //  🌱🌱 Function  handleFileInput vm  🌱🌱
  const handleFileVmInput = (e) => {
    setSelectedFileVm(e.target.files[0]);
    setMsgErrorDocVm(false);
  };

  useEffect(() => {
    if (dateVm) {
      setMsgErrorInputVm(false);
    }
  }, [dateVm]);

  //  🌱🌱 Request create Collaborators cognito  🌱🌱
  const createCognitoCollaborator = async (data) => {
    try {
      if (!dateVm || !selectedFileVm) {
        setMsgErrorInputVm(true);
      }
      if (!dateVm || !selectedFileVm) return;

      const isDriver = data.isDriver === 'Oui' ? true : false;
      const fullName = `${data.firstName.trim()}${data.lastName.trim()}`.toLowerCase();
      const dataCollab = {
        ...data,
        fullName: fullName,
        id_cognito: uuidv4(),
        isDriver: isDriver,
        docVm: selectedFileVm ? `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}` : null,
        dateVm: selectedFileVm ? dateVm : null,
      };
      await createCollaboratorUser(dataCollab);
      await setCollaboratorUsername(dataCollab);
      await setCollaboratorPhoneNumber(dataCollab);
      await confirmPasswordCollaborator(dataCollab);
      await uploadFile({
        file: selectedFileVm,
        nameFile: `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}`,
        contentType: selectedFileVm.type,
      });
    } catch (error) {
      console.log('createCognitoCollaborator', error);

      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau collaborateur, veuillez réessayer.");
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9 ">
        <h3 className="font-bold text-xl ">Ajouter un nouveau collaborateur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formAddCollaborator}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
      </div>
      {/* div Vm */}
      <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6 px-8">
        <label>Visite Médicale</label>
        <label
          htmlFor="dropzone-file-vm"
          className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
        >
          <img
            className="w-1/6"
            src={selectedFileVm ? FileUpload : Download}
            alt="icon"
          />
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <p className="mb-2 text-sm text-gray-file">
              <span className="font-semibold">
                {selectedFileVm ? selectedFileVm.name : ' Cliquez pour ajouter la visite médical'}
              </span>
            </p>
            <p className="text-xs text-gray-file font-normal">{selectedFileVm ? '' : ' PNG, JPG, JPEG, PDF'}</p>
          </div>
          <input
            id="dropzone-file-vm"
            type="file"
            className="hidden"
            onChange={handleFileVmInput}
            accept=".jpeg,.jpg,.png,.pdf"
          />
        </label>
        <p className="text-red-error">{msgErrorDocVm ? 'Veuillez ajouter une visite médical' : ''}</p>
        <div>
          <label>Date de validité</label>
          <DatePicker_date
            onChange={setDateVm}
            selected={dateVm}
            onManual={true}
          />
          <p className="text-red-error">{msgErrorInputVm ? 'Veuillez ajouter la date de fin validité' : ''}</p>
        </div>
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un nouveau collaborateur"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => {
            submitRef.current.submit();
          }}
        />
      </div>

      {/* Modal Add Collaborator */}
      <Modal
        title="Nouveau collaborateur enregistré"
        text="Nous avons bien enregistré votre nouveau collaborateur. Vous le retrouverez dans la liste des collaborateurs et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddCollaborators;


import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import { typeFile } from '../../tools/file';
import { dateFormat } from '../../tools/date';

// import logo
import FilePdf from '../../../assets/images/svg/file-pdf.svg';

//env
const S3url = process.env.REACT_APP_URL_S3_OBJECT;

const Preview = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idDriver } = useParams();

  //  🌱🌱 Init store(services)  🌱🌱
  const getDriver = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.getCollaborator);

  //  🌱🌱  Etats (données)  🌱🌱
  const [driver, setDriver] = useState({});
  const [typeDocPermis, setTypeDocPermis] = useState(null);
  const [typeDocVm, setTypeDocVm] = useState(null);

  //  🌱🌱 Request call get Driver  🌱🌱
  const callGetDriver = async () => {
    try {
      const res = await getDriver(idDriver);
      setDriver(res);
      if (res?.docPermis) {
        setTypeDocPermis(typeFile(res?.docPermis));
      }
      if (res?.docVm) {
        setTypeDocVm(typeFile(res?.docVm));
      }
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetDriver();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-4 md:pt-14 px-4 md:px-9 h-full flex flex-col">
      <h3 className="font-bold text-xl border-b border-gray-admin pb-[2.125rem]">
        Documents collaborateur {driver?.id && `${driver.first_name} ${driver.last_name}`}
      </h3>

      {typeDocPermis && (
        <div>
          <label className="text-bleu-admin mt-4">Permis de conduire </label>
          <p className="text-sm">Valide jusqu'au : {dateFormat(driver.datePermis, '/').full}</p>
          <div className="flex flex-col md:flex-row justify-center">
            {driver?.docPermis && typeDocPermis === 'pdf' && (
              <div className="border p-4 rounded-lg m-4 flex flex-col items-center justify-center w-full aspect-square">
                <p>PDF</p>
                <img
                  className="h-40 px-10"
                  src={FilePdf}
                  alt="file"
                />
                <iframe
                  src={`${S3url}${driver.docPermis}`}
                  width="100%"
                  height="100%"
                ></iframe>
                <a
                  className="px-4 py-3 text-blue-admin  rounded-lg cursor-pointer border border-gray-admin font-medium text-sm text-center bg-white-admin  mt-8"
                  href={`${S3url}${driver.docPermis}`}
                >
                  Télécharger
                </a>
              </div>
            )}
            {driver?.docPermis && typeDocPermis !== 'pdf' && (
              <div className="border p-4 rounded-lg m-4 flex flex-col items-center justify-center w-full ">
                <img
                  className="w-full"
                  src={`${S3url}${driver.docPermis}`}
                  alt="file"
                />
                <a
                  className="px-4 py-3 text-blue-admin  rounded-lg cursor-pointer border border-gray-admin font-medium text-sm text-center bg-white-admin mt-8"
                  href={`${S3url}${driver.docPermis}`}
                >
                  Télécharger
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      {typeDocVm && (
        <div>
          <label className="text-bleu-admin mt-4">Visite Médical </label>
          <p className="text-sm">Valide jusqu'au : {dateFormat(driver.dateVm, '/').full}</p>
          <div className="flex flex-col md:flex-row grow justify-center">
            {driver?.docVm && typeDocVm === 'pdf' && (
              <div className="border p-4 rounded-lg m-4 flex flex-col items-center justify-center w-full aspect-square">
                <p>PDF</p>
                <img
                  className="h-40 px-10"
                  src={FilePdf}
                  alt="file"
                />
                <iframe
                  src={`${S3url}${driver.docVm}#toolbar=0`}
                  width="100%"
                  height="100%"
                ></iframe>
                <a
                  className="px-4 py-3 text-blue-admin  rounded-lg cursor-pointer border border-gray-admin font-medium text-sm text-center bg-white-admin  mt-8"
                  href={`${S3url}${driver.docVm}`}
                >
                  Télécharger
                </a>
              </div>
            )}
            {driver?.docVm && typeDocVm !== 'pdf' && (
              <div className="border p-4 rounded-lg m-4 flex flex-col items-center justify-center w-full">
                <img
                  className="w-full"
                  src={`${S3url}${driver.docVm}`}
                  alt="file"
                />
                <a
                  className="px-4 py-3 text-blue-admin  rounded-lg cursor-pointer border border-gray-admin font-medium text-sm text-center bg-white-admin mt-8"
                  href={`${S3url}${driver.docVm}`}
                >
                  Télécharger
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col-reverse gap-y-4  pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Retour"
          onClick={() => navigate(-1)}
        />
      </div>
    </section>
  );
};

export default Preview;


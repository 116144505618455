import React, { useEffect, useRef, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { typeFile } from '../../tools/file';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';
import DatePicker_date from '../../components/DatePicker_date';

//Import icons
import Check from '../../../assets/images/svg/check.svg';
import Warning from '../../../assets/images/svg/warning.svg';
import FileUpload from '../../../assets/images/svg/file-upload.svg';
import Download from '../../../assets/images/svg/download.svg';

const EditCollaborators = () => {
  //  🌱🌱  Etats (données)  🌱🌱
  const [collab, setCollab] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  Data option  🌱🌱
  const statutOptions = [{ name: 'Electricité' }, { name: 'Climatisation' }, { name: 'Electricité / Climatisation' }];
  const isDriverOptions = [{ name: 'Oui' }, { name: 'Non' }];

  //  🌱🌱  Data Form Edit  🌱🌱
  const formEditCollab = [
    {
      label: 'Prénom',
      inputType: 'text',
      valueName: 'firstName',
      placeholder: 'Prénom',
      errorMsg: 'Veuillez entrer un prénom',
      required: true,
      halfWidth: true,
      defaultValue: collab?.first_name,
    },
    {
      label: 'Nom',
      inputType: 'text',
      valueName: 'lastName',
      placeholder: 'Nom',
      errorMsg: 'Veuillez entrer un nom',
      required: true,
      halfWidth: true,
      defaultValue: collab?.last_name,
    },
    {
      label: 'Téléphone',
      inputType: 'tel_custom',
      valueName: 'phone',
      placeholder: 'Téléphone',
      errorMsg: 'Veuillez entrer un numéro de téléphone valide',
      required: true,
      regex: /^\+?[0-9]{1,4}[0-9]{9,10}$/,
      halfWidth: true,
      defaultValue: collab?.phone,
    },
    {
      label: 'Mot de passe',
      inputType: 'password',
      valueName: 'password',
      placeholder: 'Mot de passe',
      errorMsg:
        'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.',
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/,
      halfWidth: true,
    },
    {
      label: 'Catégorie',
      inputType: 'select',
      valueName: 'statut',
      options: statutOptions,
      keyOptions: 'name',
      keyValues: 'name',
      errorMsg: 'Veuillez choisir le statut',
      halfWidth: true,
      required: true,
      style: 'max-w-[318px] flex',
      defaultValue: collab?.type?.name,
    },
    {
      label: 'Est-ce que ce collaborateur est chauffeur ?',
      inputType: 'select',
      valueName: 'isDriver',
      options: isDriverOptions,
      keyOptions: 'name',
      keyValues: 'name',
      errorMsg: 'Veuillez choisir si ce collaborateur est chauffeur',
      halfWidth: true,
      required: true,
      style: 'max-w-[318px] flex',
      defaultValue: collab?.isDriver ? 'Oui' : 'Non',
    },
  ];

  //  🌱🌱 init store(services)  🌱🌱
  const getListCategory = useStoreActions((actions) => actions.middleware.TypeMiddleware.getListCategory);
  const getCollab = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.getCollaborator);
  const updateCollab = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.updateCollaborator);
  const deleteCollab = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.deleteCollaborator);
  const deleteCollaboratorUserCognito = useStoreActions(
    (action) => action.middleware.Cognito.deleteCollaboratorUserCognito
  );
  const setCollaboratorPassword = useStoreActions((action) => action.middleware.Cognito.setCollaboratorPassword);
  const setCollaboratorUsername = useStoreActions((action) => action.middleware.Cognito.setCollaboratorUsername);
  const setCollaboratorPhoneNumber = useStoreActions((action) => action.middleware.Cognito.setCollaboratorPhoneNumber);
  const userAdmin = useStoreState((state) => state.userData.userAdmin);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);

  //  🌱🌱  Etats (données)  🌱🌱
  const [category, setCategory] = useState([]);
  const [selectedFileVm, setSelectedFileVm] = useState(null);
  const [msgErrorDocVm, setMsgErrorDocVm] = useState(false);
  const [msgErrorInputVm, setMsgErrorInputVm] = useState(false);
  const [dateVm, setDateVm] = useState('');

  //  🌱🌱  Init Navigation
  const navigate = useNavigate();
  const { idCollaborator } = useParams();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`vm-${Date.now()}`).current;

  //  🌱🌱  function getListCategory   🌱🌱
  const callGetListCategory = async () => {
    try {
      const res = await getListCategory();
      console.log('callGetListCategory', res);
      setCategory(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des catégories');
    }
  };

  //  🌱🌱 Request call get Collab  🌱🌱
  const callGetCollab = async () => {
    try {
      const res = await getCollab(idCollaborator);
      setCollab(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetListCategory();
    callGetCollab();
  }, []);

  //  🌱🌱  function updateProfileCollab   🌱🌱
  const updateProfileCollab = async (data) => {
    try {
      // Map categories array to get id
      const categorySelected = category.find((type) => type.name === data.statut);
      const newData = { ...data, id_type: categorySelected.id };
      await updateCollab(newData);
      setShowModalUpdate(true);
    } catch (error) {
      console.log('updateProfileCollab', error);

      toast.error('❗️ Une erreur est survenue durant la modification de votre collaborateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function update password Collab   🌱🌱
  const updatePasswordCollab = async (data) => {
    try {
      await setCollaboratorPassword(data);
    } catch (error) {
      console.log('updatePasswordCollab', error);
      toast.error('❗️ Une erreur est survenue durant la modification du mot de passe, veuillez réessayer.');
    }
  };

  //  🌱🌱  function DeleteProfileCollab   🌱🌱
  const deleteProfileCollab = async () => {
    setShowModalDelete(false);
    try {
      await deleteCollab(collab);
      navigate(-1);
    } catch (error) {
      console.log('deleteCollab', error);
      toast.error('❗️ Une erreur est survenue durant la suppression de votre collaborateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function Delete cognito Collab   🌱🌱
  const deleteCollabCognito = async () => {
    try {
      await deleteCollaboratorUserCognito(idCollaborator);
      deleteProfileCollab();
    } catch (error) {
      console.log('deleteCollabCognito', error);
      toast.error('❗️ Une erreur est survenue durant la suppression de votre collaborateur, veuillez réessayer.');
    }
  };

  //  🌱🌱  function setCollaboratorUsername   🌱🌱
  const setCollabUsername = async (data) => {
    try {
      await setCollaboratorUsername(data);
    } catch (error) {
      console.log('setCollabUsername', error);
      toast.error('❗️ Une erreur est survenue durant la modification de votre collaborateur, veuillez réessayer.');
    }
  };

  //  🌱🌱 Function  handleFileInput vm  🌱🌱
  const handleFileVmInput = (e) => {
    setSelectedFileVm(e.target.files[0]);
    setMsgErrorDocVm(false);
  };

  useEffect(() => {
    if (dateVm) {
      setMsgErrorInputVm(false);
    }
  }, [dateVm]);

  //  🌱🌱  function FormCustom   🌱🌱
  const onSubmit = async (value) => {
    if (value.status) {
      if ((!dateVm && selectedFileVm) || (dateVm && !selectedFileVm)) {
        setMsgErrorInputVm(true);
      }
      if ((!dateVm && selectedFileVm) || (dateVm && !selectedFileVm)) return;

      const isDriver = value.values.isDriver === 'Oui' ? true : false;
      const newData = {
        ...value.values,
        id: idCollaborator,
        id_cognito: idCollaborator,
        isDriver: isDriver,
        docVm: selectedFileVm ? `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}` : null,
        dateVm: selectedFileVm ? dateVm : null,
      };
      // Check if password value is different from empty
      if (value.values.password && value.values.password !== '') {
        const data = {
          ...newData,
          password: value.values.password,
        };
        await updatePasswordCollab(data);
      }
      // Set collaborator username
      const fullName = `${value.values.firstName.trim()}${value.values.lastName.trim()}`.toLowerCase();
      const dataCollab = {
        ...newData,
        fullName: fullName,
      };
      if (selectedFileVm && dateVm) {
        try {
          await uploadFile({
            file: selectedFileVm,
            nameFile: `vm-${fileNameRef}.${typeFile(selectedFileVm.name)}`,
            contentType: selectedFileVm.type,
          });
        } catch (error) {
          toast.error("❗️ Une erreur est survenue durant la modification du collaborateur, veuillez réessayer.");
        }
      }
      await setCollabUsername(dataCollab);
      await setCollaboratorPhoneNumber(dataCollab);
      await updateProfileCollab(newData);
    }
  };

  return (
    <section className="flex flex-col h-full md:pt-14">
      {/* div title */}
      <div className="flex justify-between pb-4 mx-4 mb-4 border-b items center border-gray-admin pt-11 md:pt-0 md:mx-9">
        <h3 className="text-xl font-bold ">Modifier un collaborateur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {collab?.id && (
          <FormCustom
            data={formEditCollab}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
      </div>
      {/* div Vm */}
      <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6 px-9">
        <label>Visite Médicale</label>
        <label
          htmlFor="dropzone-file-vm"
          className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
        >
          <img
            className="w-1/6"
            src={selectedFileVm ? FileUpload : Download}
            alt="icon"
          />
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <p className="mb-2 text-sm text-gray-file">
              <span className="font-semibold">
                {selectedFileVm ? selectedFileVm.name : ' Cliquez pour ajouter la visite médical'}
              </span>
            </p>
            <p className="text-xs text-gray-file font-normal">{selectedFileVm ? '' : ' PNG, JPG, JPEG, PDF'}</p>
          </div>
          <input
            id="dropzone-file-vm"
            type="file"
            className="hidden"
            onChange={handleFileVmInput}
            accept=".jpeg,.jpg,.png,.pdf"
          />
        </label>
        <p className="text-red-error">{msgErrorDocVm ? 'Veuillez ajouter une visite médical' : ''}</p>
        <div>
          <label>Date de validité</label>
          <DatePicker_date
            onChange={setDateVm}
            selected={dateVm}
            onManual={true}
          />
          <p className="text-red-error">{msgErrorInputVm ? 'Veuillez ajouter la date de fin validité' : ''}</p>
        </div>
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse pt-4 pb-5 border-t gap-y-4 px-9 mt-9 border-bg-gray-admin md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          {collab?.id && userAdmin.id !== collab?.id && (
            <SecondaryButton
              title="Supprimer ce collaborateur"
              onClick={() => {
                setShowModalDelete(true);
              }}
            />
          )}
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-yellow-admin text-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur le profil du collaborateur ${collab?.first_name}  ${collab?.last_name} `}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>

      {/* Modal delete */}
      <Modal
        title="Supprimer un collaborateur"
        text="Êtes-vous sûre de vouloir supprimer ce profil ? 
        Cette action est définitive. Vous n’aurez plus accès à ce profil."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteCollabCognito();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditCollaborators;


import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../SecondaryButton';
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import Search from '../../../assets/images/svg/search.svg';

// .Env value
const supplierName = process.env.REACT_APP_SUPPLIER_NAME;

const ListSuppliers = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListSuppliers = useStoreActions((actions) => actions.middleware.SupplierMiddleware.getListSuppliers);

  //init state
  const [suppliers, setSuppliers] = useState([]);
  const [suppliersList, setSuppliersList] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  // fun call list Suppliers
  const callSupppliers = async () => {
    try {
      const res = await getListSuppliers(supplierName);
      setSuppliers(res);
    } catch (error) {
      toast.error('Erreur lors de la récupération des fournisseurs');
    }
  };

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setSuppliersList(suppliers);
    } else {
      const tabFilter = suppliers.filter((element) => element.name.toLowerCase().includes(textSearch.toLowerCase()));
      setSuppliersList(tabFilter);
    }
  }, [textSearch, suppliers]);

  //Effect
  useEffect(() => {
    callSupppliers();
  }, []);

  return (
    <section className="md:pt-14 px-4 md:px-9">
      {/* div Title = button */}
      <div className=" flex flex-col space-y-4 items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row md:space-y-0">
        <h3 className="font-bold text-xl ">Liste des fournisseurs</h3>
        <SecondaryButton
          title=" + Ajouter un nouveau fournisseur"
          link={'add'}
        />
      </div>
      {/* div search bar */}
      <div className="relative flex mt-6 border border-gray-admin rounded-xl">
        <input
          type="text"
          placeholder="Recherche fournisseur"
          className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
          onInput={(e) => setTextSearch(e.target.value)}
        />

        <img
          src={Search}
          alt="search icon"
          className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
        />
      </div>
      {/* Table liste clients */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Nom du fournisseur</th>
              <th>N° de siret</th>
              <th>Adresse de facturation</th>
              <th>Téléphone</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {suppliersList.map((supplier) => (
              <tr key={supplier.id}>
                <td>{supplier.name}</td>
                <td>{supplier.siret}</td>
                <td>{supplier.address}</td>
                <td>{supplier.phone}</td>
                <td>
                  <Link
                    to={`edit/${supplier.id}`}
                    className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                  >
                    <img
                      src={More}
                      alt="more icon"
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListSuppliers;


import React from 'react';
import ListMaterialsSettings from './ListMaterialsSettings';

const PurchaseOrderSettings = () => {
  return (
    <div>
      <ListMaterialsSettings />
    </div>
  );
};

export default PurchaseOrderSettings;

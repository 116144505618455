import { action, thunk } from 'easy-peasy';

const userData = {
  cognitoData: {},
  setCognitoData: action((state, payload) => {
    state.cognitoData = payload;
  }),

  userAdmin: { first_name: '' },
  setUserAdmin: action((state, payload) => {
    state.userAdmin = payload;
  }),

  //Type correspond à la liste des types avec leur id et nom de la base de données
  typeCompanies: [],
  setTypeCompanies: action((state, payload) => {
    state.typeCompanies = payload;
  }),

  //RoleUsers correspond à la liste des rôles avec leur id et nom de la base de données
  roleUsers: [],
  // C'est la fonction qui permet d'assigner des données à RoleUsers
  setRoleUsers: action((state, payload) => {
    state.roleUsers = payload;
  }),

  //Categories corespond à la liste des catégories avec leur id et nom de la base de données
  categories: [],
  setCategories: action((state, payload) => {
    state.categories = payload;
  }),

  currentUser: null,
  setcurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
};

export default userData;

import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const BillMiddleware = {
  //✨✨==========================================✨✨
  //            CREATE BILL
  //✨✨==========================================✨✨
  createBill: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
        createBill(input: {
            amountHT: ${payload.amountHT},
            amountRemaining: ${payload.amountRemaining},
            amountTTC: ${payload.amountTTC},
            billWorksiteId: "${payload.billWorksiteId}", 
            date: "${payload.date}", 
            documentNumber: "${payload.documentNumber}", 
            status: "${payload.status}",
            type: "${payload.type}",
            }) {
            id
        }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createBill;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST BILLS
  //✨✨==========================================✨✨
  listBills: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listBills(limit: 500000) {
          items {
          amountHT
          amountRemaining
          amountTTC
          billWorksiteId
          date
          documentNumber
          id
          status
          type
          worksite {
            address
            id
            date_planned
            name
            company {
              id
              name
            }
          }
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listBills.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET BILL BY ID
  //✨✨==========================================✨✨
  getBillById: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getBill(id: "${payload}") {
        amountHT
        amountRemaining
        amountTTC
        billWorksiteId
        date
        documentNumber
        id
        status
        type
        worksite {
          address
          id
          date_planned
          name
          company {
            id
            name
            address
            siret
            mail
            phone
          }
        }

      }
    }
    `;
    try {
      getStoreActions().global.setActivity(true);
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getBill;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST BILLS BY DOCUMENT NUMBER
  //✨✨==========================================✨✨
  listBillsByDocumentNumber: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listBills(filter: {documentNumber: {eq: "${payload}"}} limit: 500000) {
        items {
          id
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listBills.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            LIST BILLS BY BILL WORKSITE ID
  //✨✨==========================================✨✨
  listBillsByBillWorksiteId: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listBills(filter: {billWorksiteId: {eq: "${payload}"}} limit: 500000) {
        items {
          id
        }
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listBills.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE BILL
  //✨✨==========================================✨✨
  deleteBill: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteBill(input: {id: "${payload}"}) {
        id
      }
    }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteBill;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default BillMiddleware;


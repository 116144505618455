import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { typeFile } from '../../tools/file';

// Import Components
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';
import DatePicker_date from '../../components/DatePicker_date';

//Import icons
import Check from '../../../assets/images/svg/check.svg';
import FileUpload from '../../../assets/images/svg/file-upload.svg';
import Download from '../../../assets/images/svg/download.svg';

const EditDrivers = () => {
  //  🌱🌱  Init Navigation   🌱🌱
  const navigate = useNavigate();
  const { idDriver } = useParams();

  const fileNameRef = useRef(`permis-${Date.now()}`).current;

  //  🌱🌱 Init store(services)  🌱🌱
  const getDriver = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.getCollaborator);
  const updateDriver = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.updateDriverCollaborator);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);

  //  🌱🌱  Etats (données)  🌱🌱
  const [driver, setDriver] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [selectedFilePermis, setSelectedFilePermis] = useState(null);
  const [msgErrorDocPermis, setMsgErrorDocPermis] = useState(false);
  const [msgErrorInputPermis, setMsgErrorInputPermis] = useState(false);
  const [datePermis, setDatePermis] = useState('');

  //  🌱🌱 Request call get Driver  🌱🌱
  const callGetDriver = async () => {
    try {
      const res = await getDriver(idDriver);
      setDriver(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱  function updateProfileDriver   🌱🌱
  const updateProfileDriver = async (data) => {
    try {
      await updateDriver(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la modification du chauffeur, veuillez réessayer.');
    }
  };

  //  🌱🌱 Function  handleFileInput permis  🌱🌱
  const handleFilePermisInput = (e) => {
    setSelectedFilePermis(e.target.files[0]);
    setMsgErrorDocPermis(false);
  };

  useEffect(() => {
    if (datePermis) {
      setMsgErrorInputPermis(false);
    }
  }, [datePermis]);

  const postupdateProfileDriver = (data) => {
    updateProfileDriver(data);
  };

  //  🌱🌱  function FormCustom Driver  🌱🌱
  const onSubmit = async () => {
    if ((!datePermis && selectedFilePermis) || (datePermis && !selectedFilePermis)) {
      setMsgErrorInputPermis(true);
    }

    if ((!datePermis && selectedFilePermis) || (datePermis && !selectedFilePermis)) return;
    const newData = {
      id: idDriver,
      docPermis: selectedFilePermis ? `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}` : null,
      datePermis: selectedFilePermis ? datePermis : null,
    };
    try {
      await uploadFile({
        file: selectedFilePermis,
        nameFile: `permis-${fileNameRef}.${typeFile(selectedFilePermis.name)}`,
        contentType: selectedFilePermis.type,
      });
      postupdateProfileDriver(newData);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau chauffeur, veuillez réessayer.");
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetDriver();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">
          {driver?.first_name} {driver?.last_name}
        </h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {/* div permis */}
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Permis de conduire</label>
          <label
            htmlFor="dropzone-file-permis"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFilePermis ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFilePermis ? selectedFilePermis.name : ' Cliquez pour ajouter le permis de conduire'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFilePermis ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-permis"
              type="file"
              className="hidden"
              onChange={handleFilePermisInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocPermis ? 'Veuillez ajouter un permis de conduire' : ''}</p>
          <div>
            <label>Date de validité</label>
            <DatePicker_date
              selected={datePermis}
              onChange={setDatePermis}
              onManual={true}
            />
            <p className="text-red-error">{msgErrorInputPermis ? 'Veuillez ajouter la date de fin validité' : ''}</p>
          </div>
        </div>
        {/* div Vm */}
        {/* <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Visite Médicale</label>
          <label
            htmlFor="dropzone-file-vm"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFileVm ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFileVm ? selectedFileVm.name : ' Cliquez pour ajouter la visite médical'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFileVm ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-vm"
              type="file"
              className="hidden"
              onChange={handleFileVmInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocVm ? 'Veuillez ajouter une visite médical' : ''}</p>
          <div>
            <label>Date de validité</label>
            <DatePicker_date
              onChange={setDateVm}
              selected={dateVm}
            />
            <p className="text-red-error">{msgErrorInputVm ? 'Veuillez ajouter la date de fin validité' : ''}</p>
          </div>
        </div> */}
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-yellow-admin text-blue-admin"
            onClick={() => onSubmit()}
          />
        </div>
      </div>
      {/* Modal Update */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur le profil de chauffeur ${driver.first_name}  ${driver.last_name} `}
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default EditDrivers;


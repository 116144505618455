import React, { useEffect } from 'react';
import Provider from './routes/Provider';
import { messaging } from './firebase'; // Assurez-vous que `messaging` est bien exporté depuis firebase.js
import { getToken, onMessage } from 'firebase/messaging';
import { useStoreActions } from 'easy-peasy';

const App = () => {
  const setTokenNotification = useStoreActions((actions) => actions.middleware.Notification.setTokenNotification);
  useEffect(() => {
    // Demande de permission pour les notifications et récupération du token
    const requestPermissionAndGetToken = async () => {
      try {
        const permission = await Notification.requestPermission();
        console.log('Notification permission status:', permission);

        if (permission === 'granted') {
          const currentToken = await getToken(messaging, {
            vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY, // VAPID Key pour FCM
          });

          if (currentToken) {
            console.log('FCM Token:', currentToken);
            // Vous pouvez ici envoyer le token au backend pour l'enregistrer
            await setTokenNotification(currentToken);
          } else {
            console.warn('Aucun token FCM disponible. Assurez-vous que Firebase est bien configuré.');
          }
        }
      } catch (error) {
        console.error('Erreur lors de la demande de permission ou de la récupération du token:', error);
      }
    };

    requestPermissionAndGetToken();

    // Gestion des messages Firebase en mode foreground
    const unsubscribe = onMessage(messaging, (payload) => {
      console.log('Message received in foreground: ', payload);

      const { title, body, icon } = payload.notification || {};
      if (Notification.permission === 'granted') {
        new Notification(title || 'Nouveau message', {
          body: body || 'Default Body',
          icon: icon || './assets/images/png/Icon_Sacarabany SARL.png',
        });
      }
    });

    return () => unsubscribe(); // Nettoyage à la désinitialisation
  }, []);

  return <Provider />;
};

export default App;

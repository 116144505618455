import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import Table from '../shared/components/Table';

const CallForTenders = () => {
  const [data, setData] = useState([]);
  const [activeFilter, setActiveFilter] = useState('climatisation');
  const setActivity = useStoreActions((state) => state.global.setActivity);

  // URLs spécifiques pour chaque filtre
  const urls = {
    climatisation:
      'https://boamp-datadila.opendatasoft.com/api/explore/v2.1/catalog/datasets/boamp/records?order_by=dateparution%20desc&limit=20&refine=code_departement%3A%22972%22&refine=descripteur_libelle%3A%22Climatisation%22',
    electricity:
      'https://boamp-datadila.opendatasoft.com/api/explore/v2.1/catalog/datasets/boamp/records?order_by=dateparution%20desc&limit=20&refine=code_departement%3A%22972%22&refine=descripteur_libelle%3A%22Electricit%C3%A9%22',
  };

  // Fonction pour charger les appels d'offres
  const fetchCallForTenders = async (filter) => {
    setData([]);
    setActiveFilter(filter);
    setActivity(true);

    try {
      const response = await axios.get(urls[filter]);
      const results = response.data.results || [];

      // Obtenir la date du jour (au format Date)
      const today = new Date();

      // Filtrer les appels d'offres selon les conditions
      const filteredResults = results.filter((offer) => {
        const dateLimiteReponse = offer.datelimitereponse ? new Date(offer.datelimitereponse) : null;
        const dateFinDiffusion = offer.datefindiffusion ? new Date(offer.datefindiffusion) : null;

        // Vérifier si l'un des deux champs (date limite de réponse ou date de fin de diffusion) est supérieur ou égal à la date d'aujourd'hui
        return (dateLimiteReponse && dateLimiteReponse >= today) || (dateFinDiffusion && dateFinDiffusion >= today);
      });

      setData(filteredResults); // Mettre à jour les données filtrées
    } catch (err) {
      console.error('Erreur lors de la récupération des données:', err);
      toast.error('Erreur lors de la récupération des données');
    } finally {
      setActivity(false);
    }
  };

  // Chargement initial
  useEffect(() => {
    fetchCallForTenders('climatisation');
  }, []);

  return (
    <section className="px-4 md:pt-14 md:px-9">
      {/* Titre et Boutons */}
      <div className="flex flex-col justify-between pb-4 mb-4 space-y-1 border-b items-center border-gray-admin pt-11 md:pt-0 md:flex-row md:space-y-0">
        <h3 className="text-xl font-bold">Liste des appels d'offres</h3>
      </div>
      <div className="flex justify-end mb-4 space-x-4">
        {Object.keys(urls).map((key) => (
          <button
            key={key}
            className={`${
              activeFilter === key
                ? 'bg-yellow-admin text-blue-admin'
                : 'bg-white text-blue-admin border border-gray-admin'
            } py-2 px-4 rounded-md`}
            onClick={() => fetchCallForTenders(key)}
          >
            {key === 'electricity' ? 'Electricité' : 'Climatisation'}
          </button>
        ))}
      </div>

      {/* Table des Appels d'Offres */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Objet</th>
              <th>Département</th>
              <th>Acheteur</th>
              <th>Date de publication</th>
              <th>Date limite de réponse</th>
              <th>Lien</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((offer, index) => (
                <tr key={index}>
                  <td>{offer?.objet}</td>
                  <td className="pl-10">{offer?.code_departement?.join(', ')}</td>
                  <td>{offer?.nomacheteur}</td>
                  <td>{new Date(offer?.dateparution).toLocaleDateString()}</td>
                  <td>{new Date(offer?.datelimitereponse || offer?.datefindiffusion).toLocaleDateString()}</td>
                  <td>
                    <a
                      href={offer?.url_avis}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      Voir l'avis complet
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={6}
                  className="text-lg text-center"
                >
                  Il n'y a pas encore d'appels d'offres.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default CallForTenders;


import React from 'react';

const Modal_2 = ({ isVisible, children, styleModal }) => {
  if (!isVisible) return null;

  return (
    <div
      className={`absolute left-[50%] top-[50%] transform -translate-x-1/2  max-w-[400px] rounded-xl bg-white shadow-lg drop-shadow-2xl pr-5 pb-5 shadow-gray-200 ${styleModal} `}
      id="wrapper"
    >
      <div>
        <div>{children}</div>
      </div>
    </div>
  );
};
export default Modal_2;


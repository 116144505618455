import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const VoiceNoteMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST VOICE NOTE
  //✨✨==========================================✨✨
  getListVoiceNotes: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listVoiceNotes(filter: {userVoiceNotesId: {eq: "${payload}"}}, limit: 500000) {
        items {
          id
          firstname
          lastname
          audioUri
          read
          userVoiceNotesId
          createdAt
          updatedAt
          receiver {
            id
            first_name
            last_name
          }
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listVoiceNotes.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET VOICE NOTE
  //✨✨==========================================✨✨
  getVoiceNote: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      getVoiceNote(id: "${payload}") {
        id
        firstname
        lastname
        audioUri
        read
        userVoiceNotesId
        createdAt
        updatedAt
        receiver { 
            id
            first_name
            last_name
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.getVoiceNote;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE VOICE NOTE
  //✨✨==========================================✨✨
  createVoiceNote: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      createVoiceNote(input: {
        firstname: "${payload?.firstname}",
        lastname: "${payload?.lastname}",
        audioUri: "${payload?.audioUri}",
        read: false,
        userVoiceNotesId: "${payload?.userVoiceNotesId}"
        }) {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createVoiceNote;
      console.log('myData', myData);

      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE VOICE NOTE READ
  //✨✨==========================================✨✨
  updateVoiceNote: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      updateVoiceNote(input: {
        id: "${payload?.id}",
        read: ${payload?.read}, 
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateVoiceNote;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE VOICE NOTE
  //✨✨==========================================✨✨
  deleteVoiceNote: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    mutation MyMutation {
      deleteVoiceNote(input: {
        id: "${payload}"
      }) 
      {
        id
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteVoiceNote;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default VoiceNoteMiddleware;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const TypeMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST ROLE USER
  //✨✨==========================================✨✨

  getListRoleUser: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listRoleUsers {
        items {
          id
          name
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      console.log('DATA', query);
      
      const myData = query.data?.listRoleUsers.items;
      console.log('DATA', myData);
      
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST CATEGORY
  //✨✨==========================================✨✨
  getListCategory: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listTypes {
        items {
          id
          name
        }
      }
    }

    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTypes.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            GET LIST TYPE COMPANIES
  //✨✨==========================================✨✨
  getListTypeCompanies: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
    query MyQuery {
      listTypeCompanies {
        items {
          name
          id
        }
      }
    }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTypeCompanies.items;
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default TypeMiddleware;


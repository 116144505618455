export const awsAuthAdmin = {
  region: 'eu-west-3',
  userPoolId: process.env.REACT_APP_POOL_ID_ADMIN,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_ADMIN,
};

export const awsAuthCollaborator = {
  region: 'eu-west-3',
  userPoolId: process.env.REACT_APP_POOL_ID_COLLABORATOR,
  userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_COLLABORATOR,
};

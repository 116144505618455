import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';

// import logo
import LogoSaca from '../../assets/images/svg/Logo_Sacarabany SARL.svg';

// import icones
import Box from '../../assets/images/svg/box.svg';
import Buildings from '../../assets/images/svg/buildings.svg';
import Calendar from '../../assets/images/svg/calendar.svg';
import PersonalCard from '../../assets/images/svg/personalcard.svg';
import Setting from '../../assets/images/svg/setting.svg';
import Truck from '../../assets/images/svg/truck.svg';
import ProfileUser from '../../assets/images/svg/profile-user.svg';
import Elements from '../../assets/images/svg/element-3.svg';
import RedWarning from '../../assets/images/svg/warning_red.svg';
import Receipt from '../../assets/images/svg/receipt-edit.svg';
import Collabs from '../../assets/images/svg/people.svg';
import Suppliers from '../../assets/images/svg/3d-cube-scan.svg';
import Ticket from '../../assets/images/svg/ticket.svg';
import Prestataires from '../../assets/images/svg/user-search.svg';
import Microphone from '../../assets/images/svg/microphone.svg';

const SideBar = () => {
  let location = useLocation();
  //init service
  const logOutAdminUser = useStoreActions((action) => action.middleware.Cognito.logOutAdminUser);
  const warnningNewClient = useStoreState((state) => state.global.warnningNewClient);

  //Render
  return (
    <div className="flex flex-col justify-between w-full h-full overflow-hidden ">
      <div className="flex flex-col h-full pt-10 overflow-hidden rounded-3xl bg-blue-dropdown md:my-5 md:ml-5">
        <div className="flex justify-center">
          <img
            src={LogoSaca}
            alt="logo"
            width={212}
          />
        </div>
        <div className="mt-20 overflow-hidden grow">
          <div className="flex flex-col h-full pb-10 pl-5 space-y-8 overflow-auto">
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Elements}
                alt="icone elements-3"
                width={20}
                height={20}
              />
              <p> Tableau de bord </p>
            </NavLink>
            <NavLink
              to="/administrators"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={ProfileUser}
                alt="icone ProfileUser"
                width={20}
                height={20}
              />
              <p>Administrateurs</p>
            </NavLink>
            <NavLink
              to="/collaborators"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Collabs}
                alt="icone Collabs"
                width={20}
                height={20}
              />
              <p>Collaborateurs</p>
            </NavLink>
            <NavLink
              to="/trainings"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Box}
                alt="icone Box"
                width={20}
                height={20}
              />
              <p>Formations</p>
            </NavLink>
            <NavLink
              to="/drivers"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                className="stroke-current"
                src={PersonalCard}
                alt="icone elements-3"
                width={20}
                height={20}
              />
              <p>Chauffeurs</p>
            </NavLink>
            <NavLink
              to="/trucks"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Truck}
                alt="icone Truck"
                width={20}
                height={20}
              />
              <p>Véhicules</p>
            </NavLink>
            <NavLink
              to="/clients"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Buildings}
                alt="icone Buildings"
                width={20}
                height={20}
              />
              <p> Clients et chantiers </p>
              {warnningNewClient && (
                <img
                  src={RedWarning}
                  alt="icone Box"
                  width={20}
                  height={20}
                />
              )}
            </NavLink>
            <NavLink
              to="/suppliers"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Suppliers}
                alt="icone Suppliers"
                width={20}
                height={20}
              />
              <p> Fournisseurs</p>
            </NavLink>
            <NavLink
              to="/prestataires"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Prestataires}
                alt="icone Prestataires"
                width={20}
                height={20}
              />
              <p>Prestataires</p>
            </NavLink>
            <NavLink
              to="/classify-order/orderCoupon"
              className={({ isActive }) =>
                location.pathname.includes('/classify-order') ? 'sideMenuActive' : 'sideMenu'
              }
            >
              <img
                src={Ticket}
                alt="Bons"
                width={20}
                height={20}
              />
              <p> Bons à classer</p>
            </NavLink>

            <NavLink
              to="/all-coupons/purchaseOrders"
              className={({ isActive }) => (location.pathname.includes('/all-coupons') ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Ticket}
                alt="Bons"
                width={20}
                height={20}
              />
              <p> Liste des bons</p>
            </NavLink>
            <NavLink
              to="/planning"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Calendar}
                alt="icone Calendar"
                width={20}
                height={20}
              />
              <p> Planning</p>
            </NavLink>
            <NavLink
              to="/appels-d-offres"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Receipt}
                alt="icone appels d'offres"
                width={20}
                height={20}
              />
              <p> Appels d'offres</p>
            </NavLink>
            <NavLink
              to="/notes-vocales"
              className={({ isActive }) => (isActive ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Microphone}
                alt="icone microphone"
                width={20}
                height={20}
              />
              <p> Notes vocales</p>
            </NavLink>
            <NavLink
              to="/settings/purchase-order"
              className={({ isActive }) => (location.pathname.includes('/settings') ? 'sideMenuActive' : 'sideMenu')}
            >
              <img
                src={Setting}
                alt="icone Setting"
                width={20}
                height={20}
              />
              <p> Paramètres</p>
            </NavLink>

            <button
              className="sideMenu btnLogOut"
              onClick={() => logOutAdminUser()}
            >
              Déconnexion
            </button>
          </div>
        </div>
      </div>

      <div className="imgBottomSidebar"></div>
    </div>
  );
};

export default SideBar;

import React from 'react';
import Customers from '../shared/components/Customers/Customers';

const Clients = () => {
  return (
    <>
      <Customers />
    </>
  );
};

export default Clients;


import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';

// Import Components
import Table from '../../Table';
import Modal from '../../Modal_2';

// import logo
import Search from '../../../../assets/images/svg/search.svg';
import DeleteIcon from '../../../../assets/images/svg/delete-icon.svg';
import AttentionIcon from '../../../../assets/images/svg/attention.svg';
import More from '../../../../assets/images/svg/more.svg';

const Expenses = () => {
  const { idTruck } = useParams();
  //  🌱🌱 Init store(services)   🌱🌱
  const getListExpenses = useStoreActions((actions) => actions.middleware.ExpensesMiddleware.getListExpenses);
  const deleteExpensesById = useStoreActions((actions) => actions.middleware.ExpensesMiddleware.deleteExpenses);

  //  🌱🌱  Etats (données)  🌱🌱
  const [expenses, setExpenses] = useState([]);
  const [expensesList, setExpensesList] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [expensesSelected, setExpensesSelected] = useState(null);

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setExpensesList(expenses);
    } else {
      const tabFilter = expenses.filter((element) => element.name.toLowerCase().includes(textSearch.toLowerCase()));
      setExpensesList(tabFilter);
    }
  }, [textSearch, expenses]);

  //  🌱🌱 Request call List Expenses  🌱🌱
  const callListExpenses = async () => {
    try {
      const res = await getListExpenses(idTruck);

      res.forEach((element) => {
        // Convertir les champs en nombres après avoir remplacé les virgules par des points
        const fuel = parseFloat(element.fuel.replace(',', '.')) || 0;
        const controlTechnique = parseFloat(element.controlTechnique.replace(',', '.')) || 0;
        const breakdown = parseFloat(element.breakdown.replace(',', '.')) || 0;
        const contravention = parseFloat(element.contravention.replace(',', '.')) || 0;
        const revision = parseFloat(element.revision.replace(',', '.')) || 0;
        const other = parseFloat(element.other.replace(',', '.')) || 0;

        // Calculer le total des dépenses
        element.totalCost = fuel + controlTechnique + breakdown + contravention + revision + other;
      });
      setExpenses(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des dépenses');
    }
  };

  //   🌱🌱 Request delete Expenses  🌱🌱
  const deleteExpenses = async () => {
    try {
      await deleteExpensesById(expensesSelected.id);
      callListExpenses();
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la dépense');
    }
  };

  //  🌱🌱  Delete action  🌱🌱
  const callDeleteExpenses = (expenses) => {
    setShowModal(true);
    setExpensesSelected(expenses);
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListExpenses();
  }, []);

  return (
    <>
      <section className="px-4 md:px-9">
        {/* div search bar */}
        {/* <div className="relative mt-12">
          <input
            type="text"
            placeholder="Recherche une dépense"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
            onInput={(e) => setTextSearch(e.target.value)}
          />
          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div> */}
        {/* Table list Expenses */}
        <div className="mt-9">
          <Table>
            <thead>
              <tr>
                <th>Ravitaillement</th>
                <th>Contrôle technique</th>
                <th>Panne</th>
                <th>Contravention</th>
                <th>Révision</th>
                <th>Autre</th>
                <th>Coût total</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {expensesList.length ? (
                expensesList.map((expenses) => {
                  return (
                    <tr key={expenses.id}>
                      <td>{expenses?.fuel + ' €'}</td>
                      <td>{expenses?.controlTechnique + ' €'}</td>
                      <td>{expenses?.breakdown + ' €'}</td>
                      <td>{expenses?.contravention + ' €'}</td>
                      <td>{expenses?.revision + ' €'}</td>
                      <td>{expenses?.other + ' €'}</td>
                      <td>{expenses?.totalCost.toFixed(2) + ' €'}</td>
                      <td className="flex items-center pt-4 space-x-4 justify-end">
                        <button
                          className="w-8 p-2 border rounded-lg border-gray-separate"
                          onClick={() => callDeleteExpenses(expenses)}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete icon"
                          />
                        </button>
                        <Link
                          to={`edit-expenses/${expenses.id}`}
                          className="float-right w-8 rounded-lg bg-gray-sidebar py-0.5 px-1 "
                        >
                          <img
                            src={More}
                            alt="more icon"
                          />
                        </Link>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan={8}
                    className="text-lg text-center"
                  >
                    La liste des dépenses est vide. Veuillez ajouter des dépenses.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression de dépenses</p>
            <p className="px-5 pt-2 text-gray-admin">
              Êtes-vous sûre de vouloir supprimer ces dépenses ? Cette action est définitive.
            </p>
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={deleteExpenses}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Expenses;


import React, { useEffect, useRef, useState } from 'react';
import { useStoreActions } from 'easy-peasy';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

// import icons
import Check from '../../../../assets/images/svg/check.svg';
import FileUpload from '../../../../assets/images/svg/file-upload.svg';
import Download from '../../../../assets/images/svg/download.svg';

// import components
import Modal from '../../Modal';
import FormCustom from '../../FormCustom';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';

// import tools
import { typeFile } from '../../../tools/file';

const EditContravention = () => {
  const { idContravention } = useParams();
  const { idTruck } = useParams();
  const navigate = useNavigate();

  //  🌱🌱 Init store(services)   🌱🌱
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const deleteFile = useStoreActions((actions) => actions.middleware.FileS3.deleteFile);
  const getContravention = useStoreActions((actions) => actions.middleware.ContraventionMiddleware.getContravention);
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);
  const updateContravention = useStoreActions(
    (actions) => actions.middleware.ContraventionMiddleware.updateContravention
  );

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [msgError, setMsgError] = useState(false);
  const [drivers, setDrivers] = useState([]);
  const [contravention, setContravention] = useState({});

  //  🌱🌱  Data Form Contravention  🌱🌱
  const statusOptions = [{ name: 'Non traitée' }, { name: 'Traitée' }];

  //   🌱🌱  Form Custom  🌱🌱
  const formEditContravention = [
    {
      label: 'Sélectionner un chauffeur',
      inputType: 'select',
      valueName: 'idDriver',
      options: drivers,
      keyOptions: 'fullName',
      keyValues: 'id',
      halfWidth: true,
      required: true,
      style: 'max-w-[194px] flex',
      placeholder: '- Chauffeur -',
      errorMsg: 'Veuillez Selectionner un chauffeur',
      defaultValue: contravention?.idDriver,
    },
    {
      label: 'Date de la contravention',
      inputType: 'text',
      valueName: 'date',
      placeholder: '01-12-2023',
      errorMsg: 'Veuillez entrer une date valide',
      required: true,
      halfWidth: true,
      regex: /^\d{2}-\d{2}-\d{4}$/,
      defaultValue: contravention?.date,
    },
    {
      label: 'Statut',
      inputType: 'select',
      valueName: 'status',
      options: statusOptions,
      keyOptions: 'name',
      keyValues: 'name',
      errorMsg: 'Veuillez choisir le statut de la contravention',
      halfWidth: true,
      required: true,
      style: 'max-w-[318px] flex',
      defaultValue: contravention?.status,
    },
  ];

  //  🌱🌱  Init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`${Date.now()}`).current;

  //  🌱🌱 Function  handleFileInput  🌱🌱
  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
    setMsgError(false);
  };

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status) {
      const splitDate = value.values.date.split('-');
      const newData = {
        ...value.values,
        date: new Date(`${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`),
        document: selectedFile ? `contravention-${fileNameRef}.${typeFile(selectedFile.name)}` : '',
        idTruck: idTruck,
        id: idContravention,
      };
      if (selectedFile) {
        try {
          await uploadFile({
            file: selectedFile,
            nameFile: `contravention-${fileNameRef}.${typeFile(selectedFile.name)}`,
            contentType: selectedFile.type,
          });
          await deleteFile({nameFile: contravention.document});
          editProfileContravention(newData);
        } catch (error) {
          toast.error('❗️ Une erreur est survenue lors de l’envoi du document, veuillez réessayer.');
        }
      } else {
        editProfileContravention(newData);
      }
    }
  };

  //  🌱🌱 Request call List Driver  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      setDrivers(
        res.map((truck) => {
          return { ...truck, fullName: `${truck.firstName} ${truck.lastName}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱 Request update Contravention  🌱🌱
  const editProfileContravention = async (data) => {
    try {
      await updateContravention(data);
      setShowModal(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue durant la création de la contravention, veuillez réessayer.');
    }
  };

  //   🌱🌱  Request call get Contravention  🌱🌱
  const callGetContravention = async () => {
    try {
      const res = await getContravention(idContravention);
      res.date = new Date(res.date).toLocaleDateString().replace(/\//g, '-');
      setContravention(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la contravention');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callGetContravention();
    callListDriver();
  }, []);

  return (
    <section className="md:pt-14 h-full flex flex-col overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Modifier une contravention</h3>
      </div>
      <div className="mx-9 max-w-[1100px] grow">
        {/* FormCustom */}
        {!!contravention && (
          <FormCustom
            data={formEditContravention}
            resultSubmit={onSubmit}
            ref={submitRef}
          />
        )}
        {/* div upload file */}
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Document</label>
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFile ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFile ? selectedFile.name : ' Cliquez pour ajouter un Document'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFile ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              onChange={handleFileInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgError ? 'Veuillez sélectionner une revision' : ''}</p>
        </div>
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Enregistrer les modifications"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      {/* Modal Add Contravention */}
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur la contravention ${contravention?.id}`}
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default EditContravention;


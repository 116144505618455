import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import icons
import Check from '../../../../assets/images/svg/check.svg';

// import components
import Modal from '../../Modal';
import FormCustom from '../../FormCustom';
import PrimaryButton from '../../PrimaryButton';
import SecondaryButton from '../../SecondaryButton';

const EditExpenses = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idTruck } = useParams();
  const { idExpense } = useParams();

  //  🌱🌱 Init store(services)   🌱🌱
  const getExpenses = useStoreActions((actions) => actions.middleware.ExpensesMiddleware.getExpenses);
  const updateExpenses = useStoreActions((actions) => actions.middleware.ExpensesMiddleware.updateExpenses);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [expenses, setExpenses] = useState({});

  //   🌱🌱  Form Custom  🌱🌱
  const formEditExpenses = [
    {
      label: 'Révision',
      inputType: 'text',
      valueName: 'revision',
      placeholder: 'Entrez le montant de la révision',
      errorMsg: 'Veuillez entrer le montant de la révision',
      required: false,
      halfWidth: true,
      defaultValue: expenses?.revision,
    },
    {
      label: 'Contrôle technique',
      inputType: 'text',
      valueName: 'controlTechnique',
      placeholder: 'Entrez le montant du contrôle technique',
      errorMsg: 'Veuillez entrer le montant du contrôle technique',
      required: false,
      halfWidth: true,
      defaultValue: expenses?.controlTechnique,
    },
    {
      label: 'Ravitaillement',
      inputType: 'text',
      valueName: 'fuel',
      placeholder: 'Entrez le montant du ravitaillement',
      errorMsg: 'Veuillez entrer le montant du ravitaillement',
      required: false,
      halfWidth: true,
      defaultValue: expenses?.fuel,
    },
    {
      label: 'Panne',
      inputType: 'text',
      valueName: 'breakdown',
      placeholder: 'Entrez le montant de la panne',
      errorMsg: 'Veuillez entrer le montant de la panne',
      required: false,
      halfWidth: true,
      defaultValue: expenses?.breakdown,
    },
    {
      label: 'Contravention',
      inputType: 'text',
      valueName: 'contravention',
      placeholder: 'Entrez le montant de la contravention',
      errorMsg: 'Veuillez entrer le montant de la contravention',
      required: false,
      halfWidth: true,
      defaultValue: expenses?.contravention,
    },
    {
      label: 'Autre',
      inputType: 'text',
      valueName: 'other',
      placeholder: "Entrez le montant de l'autre paramètre",
      errorMsg: "Veuillez entrer le montant de l'autre paramètre",
      required: false,
      halfWidth: true,
      defaultValue: expenses?.other,
    },
  ];

  //  🌱🌱  Init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 Request call Expenses  🌱🌱
  const callExpenses = async () => {
    try {
      const res = await getExpenses(idExpense);
      setExpenses(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des dépenses');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callExpenses();
  }, []);

  //  🌱🌱 Request update expenses  🌱🌱
  const updateProfileExpenses = async (data) => {
    try {
      await updateExpenses(data);
      setShowModal(true);
    } catch (error) {
      toast.error('❗️  Une erreur est survenue durant la modification des dépenses.');
    }
  };

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        idTruck: idTruck,
        id: idExpense,
      };
      updateProfileExpenses(newData);
    }
  };

  return (
    <section className="md:pt-14 h-full flex flex-col overflow-y-auto">
      <div className="flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Modifier des dépenses</h3>
      </div>
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formEditExpenses}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Modifier des dépenses"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      <Modal
        title="Modification enregistrée"
        text={`Nous avons bien enregistré les modifications effectuées sur vos dépenses`}
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default EditExpenses;


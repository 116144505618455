import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';
import { typeFile } from '../../tools/file';

//import icons
import Check from '../../../assets/images/svg/check.svg';
import FileUpload from '../../../assets/images/svg/file-upload.svg';
import Download from '../../../assets/images/svg/download.svg';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const AddTrucks = () => {
  //  🌱🌱 Init store(services)  🌱🌱
  const createTruck = useStoreActions((actions) => actions.middleware.TruckMiddleware.createTruck);
  const getListDriver = useStoreActions((actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators);
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);

  //  🌱🌱  Etats (données)  🌱🌱
  const [drivers, setDrivers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFileCarte, setSelectedFileCarte] = useState(null);
  const [msgErrorDocCarte, setMsgErrorDocCarte] = useState(false);

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱 Init use Ref  🌱🌱
  const submitRef = useRef();
  const fileNameRef = useRef(`${Date.now()}`).current;

  const formTruck = [
    {
      label: 'Modèle du véhicule',
      inputType: 'text',
      valueName: 'model',
      placeholder: 'Modèle du véhicule',
      errorMsg: 'Veuillez rentrer le modèle du véhicule',
      required: true,
      halfWidth: true,
    },
    {
      label: 'Année',
      inputType: 'number',
      valueName: 'year',
      placeholder: 'Année',
      errorMsg: "Veuillez rentrer l'année de votre véhicule",
      required: true,
      halfWidth: true,
    },
    {
      label: 'Immatriculation',
      inputType: 'text',
      valueName: 'registration',
      placeholder: 'Immatriculation AB-123-CD',
      errorMsg: "Veuillez entrer une plaque d'immatriculation valide au format AA-000-BB",
      regex: /^[a-zA-Z]{2}-[0-9]{3}-[a-zA-Z]{2}$/,
      required: true,
      halfWidth: true,
    },
    {
      label: 'Attribution',
      inputType: 'select',
      valueName: 'truckDriverId',
      options: drivers,
      keyOptions: 'fullName',
      keyValues: 'id',
      halfWidth: true,
      required: true,
      style: 'max-w-[194px] flex',
      placeholder: '- Chauffeurs -',
      errorMsg: 'Veuillez Selectionner un chauffeur',
    },
  ];

  //  🌱🌱 Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver({ nameCollab: collaboratorName.toString() });
      const drivers = res.filter((driver) => driver.isDriver === true);
      setDrivers(
        drivers.map((truck) => {
          return { ...truck, fullName: `${truck.first_name} ${truck.last_name}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListDriver();
  }, []);

  //  🌱🌱 Request create Truck  🌱🌱
  const createProfileTruck = async (data) => {
    try {
      const res = await createTruck(data);
      setShowModal(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouveau véhicule, veuillez réessayer.");
    }
  };

  //  🌱🌱 Function  handleFileInput vm  🌱🌱
  const handleFileCarteInput = (e) => {
    setSelectedFileCarte(e.target.files[0]);
    setMsgErrorDocCarte(false);
  };

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = async (value) => {
    if (value.status && selectedFileCarte) {
      const newData = {
        ...value.values,
        document: `grise-${fileNameRef}.${typeFile(selectedFileCarte.name)}`,
      };
      try {
        await uploadFile({
          file: selectedFileCarte,
          nameFile: `grise-${fileNameRef}.${typeFile(selectedFileCarte.name)}`,
          contentType: selectedFileCarte.type,
        });
        createProfileTruck(newData);
      } catch (error) {
        toast.error("❗️Une erreur est survenue durant la création d'un nouveau véhicule, veuillez réessayer.");
      }
    }
    if (!selectedFileCarte) {
      setMsgErrorDocCarte(true);
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className=" h-full flex flex-col md:pt-14 overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Ajouter un nouveau véhicule</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formTruck}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Carte grise</label>
          <label
            htmlFor="dropzone-file-permis"
            className="flex flex-col items-center justify-center w-full h-64 border border-gray-admin border-dashed rounded-lg cursor-pointer   hover:bg-gray-100"
          >
            <img
              className="w-1/6"
              src={selectedFileCarte ? FileUpload : Download}
              alt="icon"
            />
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <p className="mb-2 text-sm text-gray-file">
                <span className="font-semibold">
                  {selectedFileCarte ? selectedFileCarte.name : ' Cliquez pour ajouter une carte grise'}
                </span>
              </p>
              <p className="text-xs text-gray-file font-normal">{selectedFileCarte ? '' : ' PNG, JPG, JPEG, PDF'}</p>
            </div>
            <input
              id="dropzone-file-permis"
              type="file"
              className="hidden"
              onChange={handleFileCarteInput}
              accept=".jpeg,.jpg,.png,.pdf"
            />
          </label>
          <p className="text-red-error">{msgErrorDocCarte ? 'Veuillez ajouter une carte grise' : ''}</p>
        </div>
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un nouveau véhicule"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => submitRef.current.submit()}
        />
      </div>
      {/* Modal Add Truck */}
      <Modal
        title="Nouveau véhicule enregistré"
        text="Nous avons bien enregistré votre nouveau véhicule. Vous le retrouverez dans la liste des véhicules et pouvez le modifier à tout moment.
        Attention, veuillez ajouter un contrôle technique"
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddTrucks;


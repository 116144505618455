import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStoreState, useStoreActions } from 'easy-peasy';

const AddWorksite = () => {
  //init navigation
  const navigate = useNavigate();

  //url params
  const { idCustomer } = useParams();

  //init store
  const categories = useStoreState((state) => state.userData.categories);
  const createWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.createWorksite);
  const listWorksitesByName = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.listWorksitesByName);

  //init default values fourmisseur
  const currentCustomer = useLocation().state;

  //no data navigate to Marie©
  if (!currentCustomer) {
    if (idCustomer) {
      window.location.href = `/clients/worksite/${idCustomer}`;
    } else {
      window.location.href = '/clients';
    }
  }

  //Schema form
  const schema = yup.object().shape({
    name: yup.string().required('Ce champ est requis.'),
    address: yup.string().required('Ce champ est requis.'),
    date: yup.date().required('Ce champ est requis.'),
  });

  //config form verifications
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //Submit func
  const onSubmit = async (data) => {
    const newDatas = { ...data, id_customer: currentCustomer.id };
    console.log('newDatas:', newDatas);
    try {
      const existingWorksite = await listWorksitesByName(data.name);
      if (existingWorksite.length > 0) {
        toast.error('❗️ Ce chantier existe déjà');
        return;
      }
      await createWorksite(newDatas);
      navigate(-1);
    } catch (error) {
      console.log('error', error);
      toast.error("❗️ Une erreur est survenue lors de l'ajout d'un chantier");
    }
  };

  //Render
  return (
    <section className="flex flex-col h-screen">
      {/* Header */}
      <div className="pt-11 pb-4 border-b border-white-admin mb-3 mx-14">
        <h3 className="font-semibold">Créer un nouveau chantier</h3>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col flex-grow"
      >
        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="name">Nom du chantier</label>
          <input
            id="name"
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            placeholder="Nom du chantier"
            {...register('name')}
          />
          <p className="text-xs text-red-500">{errors.name?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label>Type de client</label>
          {categories?.length &&
            categories.map((cat, index) => (
              <div key={cat.id}>
                <input
                  className="w-4 h-4 checkedFocus"
                  type="radio"
                  id={cat.name}
                  {...register('typeClient')}
                  value={cat.id}
                  defaultChecked={index === 0}
                />
                <label
                  className="ml-2"
                  htmlFor={cat.name}
                >
                  {cat.name}
                </label>
              </div>
            ))}
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="address">Adresse postale du chantier</label>
          <input
            id="address"
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            placeholder="22 rue du général de Gaulle 97236 Lamentin"
            {...register('address')}
          />
          <p className="text-xs text-red-500">{errors.address?.message}</p>
        </div>

        <div className="flex items-center pb-4 mb-4 space-x-4 border-b mx-14 border-white-admin">
          <label htmlFor="date">Date de début du chantier</label>
          <input
            id="date"
            type="date"
            className="w-[318px] py-3 pl-5 border border-white-admin rounded-lg text-sm input"
            placeholder="22 rue du général de Gaulle 97236 Lamentin"
            {...register('date')}
          />
          <p className="text-xs text-red-500">{errors.address?.message}</p>
        </div>

        <div className="mt-auto py-4 flex justify-between items-center border-t border-white-admin px-14">
          <div
            onClick={() => navigate(-1)}
            className="px-5 py-3 border rounded-lg cursor-pointer text-blue-admin border-gray-admin"
          >
            Annuler
          </div>

          <div className="rounded-lg cursor-pointer bg-yellow-admin">
            <button
              type="submit"
              className="px-5 py-3 font-semibold text-blue-admin"
            >
              Créer un nouveau chantier
            </button>
          </div>
        </div>
      </form>
    </section>
  );
};

export default AddWorksite;

import React, { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';

//import icons
import Check from '../../assets/images/svg/check.svg';

// Env value
const customerName = process.env.REACT_APP_CUSTOMER_NAME;

const FileUpload = () => {
  // Stores
  const activities = useStoreState((state) => state.userData.categories);
  const typeCompanies = useStoreState((state) => state.userData.typeCompanies);

  // Import middlewares
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const setActivity = useStoreActions((state) => state.global.setActivity);
  const createBill = useStoreActions((actions) => actions.middleware.BillMiddleware.createBill);
  const createClient = useStoreActions((actions) => actions.middleware.CompanyMiddleware.createCompany);
  const createWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.createWorksite);
  const listCompaniesByName = useStoreActions((actions) => actions.middleware.CompanyMiddleware.listCompaniesByName);

  const listWorksitesByName = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.listWorksitesByName);
  const listBillsByDocumentNumber = useStoreActions(
    (actions) => actions.middleware.BillMiddleware.listBillsByDocumentNumber
  );

  //  🌱🌱  Etats (données)  🌱🌱
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    console.log('activities : ', activities);
    console.log('typeCompanies : ', typeCompanies);
  }, [activities, typeCompanies]);

  // Gestion de la sélection du fichier
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  // Fonction pour uploader le fichier dans S3
  const uploadFileToS3 = async (file) => {
    setUploading(true);
    const fileName = `${file.name}`;
    try {
      // Upload du fichier vers S3
      await uploadFile({
        file: file,
        nameFile: fileName,
        contentType: file.type,
      });

      // Une fois le batch créé, traiter le fichier Excel
      await processExcelFile(file);
    } catch (error) {
      console.error("Erreur d'upload S3 : ", error);
      toast.error("Erreur lors de l'upload");
      setActivity(false);
    } finally {
      setUploading(false);
    }
  };

  // Fonction pour convertir une date Excel en format lisible
  const parseExcelDate = (excelDate) => {
    const startDate = new Date(1900, 0, 1); // 1er janvier 1900
    const days = Math.floor(excelDate) - 2; // Ajustement : Excel inclut 29/02/1900 comme jour, mais ce n'était pas une année bissextile
    startDate.setDate(startDate.getDate() + days);
    return startDate.toISOString().split('T')[0]; // Format "YYYY-MM-DD"
  };

  // Fonction pour traiter le fichier Excel et créer les documents
  const processExcelFile = async (file) => {
    setActivity(true); // Activer le loader
    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];

        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        let headerRowIndex = -1;
        let columnIndices = {};

        // Identification des en-têtes
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (row.includes('Numéro de document')) {
            headerRowIndex = i;

            columnIndices = {
              documentNumber: row.indexOf('Numéro de document'),
              date: row.indexOf('Date'),
              projectName: row.indexOf('Nom du chantier'),
              clientName: row.indexOf('Nom du client'),
              billingAddress: row.indexOf('Adresse de facturation'),
              projectAddress: row.indexOf('Adresse du projet'),
              amountHT: row.indexOf('Montant HT'),
              amountTTC: row.indexOf('Montant TTC'),
              amountRemaining: row.indexOf('Reste à payer'),
              status: row.indexOf('Statut'),
              type: row.indexOf('Type'),
            };
            break;
          }
        }

        if (headerRowIndex === -1 || Object.values(columnIndices).some((index) => index === -1)) {
          throw new Error('Les en-têtes obligatoires sont absents dans le fichier.');
        }

        // Extraction et filtrage des données
        const dataRows = rows.slice(headerRowIndex + 1);
        const filteredRows = dataRows.filter((row) => {
          const documentNumber = row[columnIndices.documentNumber];
          const clientName = row[columnIndices.clientName];
          return documentNumber && documentNumber.includes('FAC') && clientName;
        });

        // Formater les données
        const formattedRows = filteredRows.map((row) => ({
          documentNumber: row[columnIndices.documentNumber] || '',
          date:
            typeof row[columnIndices.date] === 'number'
              ? parseExcelDate(row[columnIndices.date])
              : row[columnIndices.date] || '',
          projectName: row[columnIndices.projectName] || '',
          clientName: row[columnIndices.clientName] || '',
          billingAddress: row[columnIndices.billingAddress] || '',
          projectAddress: row[columnIndices.projectAddress] || '',
          amountHT: row[columnIndices.amountHT] || '',
          amountTTC: row[columnIndices.amountTTC] || '',
          amountRemaining: row[columnIndices.amountRemaining] || '',
          status: row[columnIndices.status] || '',
          type: row[columnIndices.type] || '',
        }));

        // Grouper par client
        const clients = {};
        formattedRows.forEach((row) => {
          if (!clients[row.clientName]) {
            clients[row.clientName] = {
              clientName: row.clientName,
              billingAddress: row.billingAddress,
              projects: [],
            };
          }
          clients[row.clientName].projects.push(row);
        });

        const typeCompany = typeCompanies.find((el) => el.name === customerName);
        console.log('typeCompany', typeCompany);

        // Utilisation d'un objet pour éviter de créer des clients en doublon
        const processedClients = {};

        // Création des entités
        for (const [clientName, clientData] of Object.entries(clients)) {
          if (processedClients[clientName]) {
            // Si ce client a déjà été traité, on le saute
            continue;
          }

          let localCompanyId = null;
          const existingClient = await listCompaniesByName(clientName);
          if (existingClient.length > 0) {
            localCompanyId = existingClient[0].id;
          } else {
            const clientID = await createClient({
              address: clientData.billingAddress,
              mail: '',
              name: clientName,
              phone: '',
              siret: '',
              id_type_Company: typeCompany?.id,
              ctm:
                clientName.includes('CTM') ||
                clientName.includes('COLLECTIVITE TERRITORIALE DE MARTINIQUE') ||
                clientName.includes('COLLECTIVITE TERRITOTIALE DE MQUE'),
            });
            console.log('COMPANY CREATED', clientID);

            localCompanyId = clientID?.id;
          }
          console.log('localCompanyId', localCompanyId);

          // Ajout du client au Set pour éviter de traiter à nouveau ce client
          processedClients[clientName] = localCompanyId;

          const typeWorksite = activities.find((el) => el.name === 'Electricité / Climatisation');
          console.log('typeWorksite', typeWorksite);

          for (const project of clientData.projects) {
            console.log('project', project);
            const existingWorksite = await listWorksitesByName(project.projectName);
            let worksiteId = null;
            if (existingWorksite.length > 0) {
              worksiteId = existingWorksite[0].id;
            } else {
              const res = await createWorksite({
                name: project.projectName,
                address: project.projectAddress || 'Non défini',
                date: project.date,
                typeClient: typeWorksite?.id,
                id_customer: localCompanyId,
              });
              console.log('createWorksite', res);
              worksiteId = res?.id;
            }
            if (project.documentNumber.includes('FAC-')) {
              const existingBill = await listBillsByDocumentNumber(project.documentNumber);
              if (existingBill.length === 0) {
                await createBill({
                  documentNumber: project.documentNumber,
                  date: project.date,
                  amountHT: project?.amountHT ? project.amountHT : 0,
                  amountTTC: project?.amountTTC ? project.amountTTC : 0,
                  amountRemaining: project?.amountRemaining ? project.amountRemaining : 0,
                  status: project.status,
                  type: project.type,
                  billWorksiteId: worksiteId,
                });
              }
            }
          }
        }

        // Afficher le modal de succès
        setShowModal(true);
      } catch (error) {
        console.error('Erreur pendant le traitement :', error);
        toast.error('Erreur lors du traitement du fichier.');
      } finally {
        setActivity(false); // Désactiver le loader
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <section>
      <div style={{ padding: 20 }}>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileChange}
          className="mb-4 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary-blue mr-4"
        />
        <button
          onClick={() => uploadFileToS3(selectedFile)}
          disabled={uploading || !selectedFile}
          className={`${
            uploading ? 'bg-gray-400' : 'bg-blue-admin hover:bg-primary-blue'
          } text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-blue-500`}
        >
          {uploading ? 'Uploading...' : 'Uploader le fichier'}
        </button>
      </div>
      {/* Modal Add Bills */}
      <Modal
        title="Document ajouté"
        text="Nous avons bien enregistré votre document."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Modal>
    </section>
  );
};

export default FileUpload;


import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../shared/components/SecondaryButton';
import Table from '../shared/components/Table';
import Modal from '../shared/components/Modal_2';

// Import icons
import Search from '../assets/images/svg/search.svg';
import Eye from '../assets/images/svg/eye.svg';
import DeleteIcon from '../assets/images/svg/delete-icon.svg';
import AttentionIcon from '../assets/images/svg/attention.svg';

const Bills = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListBills = useStoreActions((actions) => actions.middleware.BillMiddleware.listBills);
  const deleteWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.deleteWorksite);
  const deleteBill = useStoreActions((actions) => actions.middleware.BillMiddleware.deleteBill);

  // Store
  const activity = useStoreState((state) => state.userData.activity);

  //  🌱🌱  Etats (données)  🌱🌱
  const [bills, setBills] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [billsList, setBillsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [billSelected, setBillSelected] = useState(null);
  const [deleteChoice, setDeleteChoice] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setBillsList(bills);
    } else {
      const tabFilter = bills.filter((element) =>
        element.worksite.company.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setBillsList(tabFilter);
    }
  }, [textSearch, bills]);

  //  🌱🌱 Request call List Bills  🌱🌱
  const callListBills = async () => {
    try {
      const res = await getListBills();
      // Sort by date
      res.sort((a, b) => new Date(b.date) - new Date(a.date));
      setBills(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des factures');
    }
  };

  // 🌱🌱  Call delete function  🌱🌱
  const callDeleteBill = async () => {
    try {
      if (deleteChoice === 'BILL & WORKSITE') {
        // call delete bill
        await deleteBill(billSelected.id);
        // call delete worksite
        await deleteWorksite(billSelected.worksite.id);
      } else {
        // call delete bill
        await deleteBill(billSelected.id);
      }
      toast.success('✅ La facture a bien été supprimée');
      setShowModalConfirm(false);
      setShowModal(false);
      getListBills();
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la facture');
    }
  };

  // fun click
  const deleteAction = (bill) => {
    setShowModal(true);
    setBillSelected(bill);
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListBills();
  }, []);
  return (
    <>
      <section className="md:pt-14 px-4 md:px-9">
        {/* div Title = button */}
        <div className=" flex flex-col space-y-4 items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row md:space-y-0">
          <h3 className="font-bold text-xl ">Liste des factures</h3>
          <SecondaryButton
            title="Retour"
            link={'/'}
          />
        </div>
        <div className="relative flex mt-6 border border-white-admin rounded-xl">
          <input
            type="text"
            placeholder="Recherche par client"
            className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
            onInput={(e) => setTextSearch(e.target.value)}
          />
          <img
            src={Search}
            alt="search icon"
            className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
          />
        </div>
        {/* Table list bills */}
        <div className="mt-9 max-h-[1000px] overflow-y-auto">
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Numéro de facture</th>
                <th>Client </th>
                <th>Nom du chantier</th>
                <th>Montant TTC</th>
                <th>Reste à payer</th>
                <th>Statut</th>
                <th className="flex justify-end">Actions</th>
              </tr>
            </thead>
            <tbody>
              {billsList.length ? (
                billsList.map((bill) => (
                  <tr key={bill?.id}>
                    <td>{new Date(bill?.date).toLocaleDateString()}</td>
                    <td>{bill?.documentNumber}</td>
                    <td>{bill?.worksite?.company?.name}</td>
                    <td>{bill?.worksite?.name}</td>
                    <td>{bill?.amountTTC.toFixed(2)}</td>
                    <td>{bill?.amountRemaining.toFixed(2)}</td>
                    <td>{bill?.status}</td>
                    <td className="flex justify-end items-center space-x-4 mt-3">
                      <Link
                        to={`preview/${bill.id}`}
                        className="flex justify-end p-2 border rounded-lg"
                      >
                        <img
                          src={Eye}
                          alt="see icon"
                          className="w-4 h-4"
                        />
                      </Link>
                      <button
                        className="w-8 p-2 border rounded-lg border-gray-separate"
                        onClick={() => deleteAction(bill)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="delete icon"
                        />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    className="text-center text-lg font-semibold pt-20"
                    colSpan={6}
                  >
                    {activity ? (
                      <></>
                    ) : textSearch ? (
                      "Oups, ce client n'existe pas 🤭"
                    ) : (
                      "Vous n'avez pas encore créé de facture"
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div className="p-5">
          <div className="flex items-center">
            <img
              src={AttentionIcon}
              alt="attention icon"
              className="w-10 h-10"
            />
            <p className="px-2 text-lg text-red-error font-medium">Que souhaitez-vous supprimer ?</p>
          </div>
          <div className="flex flex-col items-center justify-center space-y-4 pt-6">
            <button
              className="w-full max-w-xs px-10 py-3 font-semibold border rounded-xl text-red-error hover:text-white hover:bg-red-error"
              onClick={() => setShowModal(false)}
            >
              Annuler
            </button>
            <button
              className="w-full max-w-xs px-10 py-3 text-white bg-yellow-admin hover:bg-yellow-500 rounded-xl"
              onClick={() => {
                setShowModalConfirm(true);
                setDeleteChoice('BILL');
              }}
            >
              La facture
            </button>
            <button
              className="w-full max-w-xs px-10 py-3 text-white bg-yellow-admin hover:bg-yellow-500 rounded-xl"
              onClick={() => {
                setShowModalConfirm(true);
                setDeleteChoice('BILL & WORKSITE');
              }}
            >
              La facture et le chantier associé
            </button>
          </div>
        </div>
      </Modal>

      {/* modal confirm */}
      <Modal isVisible={showModalConfirm}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            {deleteChoice === 'BILL' ? (
              <p className="pl-5 pt-7">Supression de la facture: {billSelected?.documentNumber}</p>
            ) : (
              <p className="px-5 pt-2 text-gray-admin">
                Supression de la facture: {billSelected?.documentNumber} et du chantier: {billSelected?.worksite?.name}
              </p>
            )}
            {deleteChoice === 'BILL' ? (
              <p className="px-5 pt-2 text-gray-admin">
                Êtes-vous sûre de vouloir supprimer cette facture ? Cette action est définitive.
              </p>
            ) : (
              <p className="px-5 pt-2 text-gray-admin">
                Êtes-vous sûre de vouloir supprimer cette facture et le chantier associé ?
              </p>
            )}
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModalConfirm(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={callDeleteBill}
              >
                Je confirme
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Bills;


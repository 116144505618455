// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyByZYoS0R8iZVqAKqTggzj8_FGALlLWbu8',
  authDomain: 'sacarabany-sarl.firebaseapp.com',
  projectId: 'sacarabany-sarl',
  storageBucket: 'sacarabany-sarl.firebasestorage.app',
  messagingSenderId: '741295259658',
  appId: '1:741295259658:web:5e8e5e5996a50f680ddff3',
  measurementId: 'G-LEX0DL4MS9',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Messaging
export const messaging = getMessaging(app);

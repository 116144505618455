import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

// Import Components
import Table from '../Table';

// Import icons
import More from '../../../assets/images/svg/more.svg';
import Search from '../../../assets/images/svg/search.svg';

// format date
const dateFormat = (date) => {
  const temps = new Date(date);
  return {
    year: temps.getFullYear().toString(),
    month: ('0' + (temps.getMonth() + 1)).slice(-2),
    day: ('0' + temps.getDate()).slice(-2),
  };
};

const AllCouponsOrders = () => {
  const navigation = useNavigate();

  //  🌱🌱 init store(services)   🌱🌱
  const getListOrders = useStoreActions((actions) => actions.middleware.OrdersMiddleware.getListOrders);

  //Etats (données)
  const [orderCoupons, setOrderCoupons] = useState([]);
  const [listOrderCoupons, setListOrderCoupons] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setListOrderCoupons(orderCoupons);
    } else {
      const tabFilter = orderCoupons.filter(
        (element) =>
          element?.worksite?.company?.name.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.worksite?.name?.toLowerCase().includes(textSearch.toLowerCase()) ||
          element?.numero.includes(textSearch)
      );
      setListOrderCoupons(tabFilter);
    }
  }, [orderCoupons, textSearch]);

  //  🌱🌱 Request call List Order Coupons  🌱🌱
  const callListOrderCoupons = async () => {
    try {
      const res = await getListOrders();
      console.log('DATA', res);
      setOrderCoupons(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des bons de commande');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListOrderCoupons();
  }, []);

  return (
    <section className="md:pt-8 px-4 md:px-9">
      {/* div search bar */}
      <div className="relative flex mt-6 border border-gray-admin rounded-xl">
        <input
          type="text"
          placeholder="Recherche bons"
          className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
          onInput={(e) => setTextSearch(e.target.value)}
        />

        <img
          src={Search}
          alt="search icon"
          className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
        />
      </div>
      {/* Table liste orders coupon */}
      <div className="mt-9">
        <Table>
          <thead>
            <tr>
              <th>Réalisé le :</th>
              <th>Numéro </th>
              <th>Type de chantier</th>
              <th>Nom du client</th>
              <th className="flex justify-end">Nom du chantier</th>
            </tr>
          </thead>
          <tbody>
            {listOrderCoupons?.length ? (
              listOrderCoupons
                ?.sort((a, b) => b.numero - a.numero)
                ?.map((orderCoupon) => {
                  const dateCouponCreate = dateFormat(orderCoupon.createdAt);
                  return (
                    <tr
                      key={orderCoupon.id}
                      className={`h-16 border-b border-white-admin hover:bg-blue-50 transition-all ${
                        orderCoupon.ctm !== 'true' ? 'cursor-pointer' : ''
                      }`}
                      onClick={() => {
                        if (orderCoupon.ctm !== 'true') navigation(`/pdf-preview/order/${orderCoupon.id}`);
                      }}
                    >
                      <td>
                        {dateCouponCreate.day}.{dateCouponCreate.month}.{dateCouponCreate.year}
                      </td>
                      <td className="text-left">
                        {orderCoupon?.numero} {orderCoupon.ctm !== 'true' ? '' : '- pré-rempli'}
                      </td>
                      <td>{orderCoupon?.worksite?.type?.name}</td>
                      <td>{orderCoupon?.worksite?.company?.name}</td>
                      <td className="flex justify-end mt-5">{orderCoupon?.worksite?.name}</td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td
                  className="text-center text-lg font-semibold pt-20"
                  colSpan={6}
                >
                  {textSearch ? "Oups, ce chantier ou client n'existe pas 🤭" : 'Ajouter un bon de commande'}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default AllCouponsOrders;


import { useState } from "react";

// Import icons
import PlayIcon from '../../assets/images/svg/play.svg';
import PauseIcon from '../../assets/images/svg/pause.svg';

const AudioPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useState(new Audio(src))[0];

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.pause();
    } else {
      audioRef.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Assurez-vous que l'état revient à "pause" lorsque la lecture est terminée
  audioRef.onended = () => setIsPlaying(false);

  return (
    <div className="flex items-center space-x-2 bg-gray-100 p-2 rounded-lg shadow-md">
      <button
        onClick={togglePlay}
        className="w-10 h-10 flex items-center justify-center text-white"
      >
        {isPlaying ? (
          <img
            src={PauseIcon}
            alt="pause icon"
            className="w-6 h-6"
          />
        ) : (
          <img
            src={PlayIcon}
            alt="play icon"
            className="w-6 h-6"
          />
        )}
      </button>
      <div className="flex-grow text-sm text-gray-700">
        {isPlaying ? 'Lecture en cours...' : 'Cliquez pour écouter'}
      </div>
    </div>
  );
};

export default AudioPlayer;

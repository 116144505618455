import React from 'react';
import './css/HoverStatuts.css';

const HoverStatuts = ({ text }) => {
  return (
    <>
      <div className="hoverText">{text}</div>
      <div className="boxHover">
        <div className="boxInfos">
          <ul>
            <li>
              <div>
                <span style={{ backgroundColor: '#5BB264' }} /> Chantier en cours
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#F9B653' }} />
                Chantier en attente
              </div>
            </li>
            <li>
              <div>
                <span style={{ backgroundColor: '#ED4337' }} /> Chantier terminé
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default HoverStatuts;


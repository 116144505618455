import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import FormCustom from '../FormCustom';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';
import Modal from '../../components/Modal';

//import icons
import Check from '../../../assets/images/svg/check.svg';

//Data option
const statutOptions = [{ name: 'Admin' }, { name: 'Super Admin' }];

const formAddAdmin = [
  {
    label: 'Prénom',
    inputType: 'text',
    valueName: 'firstName',
    placeholder: 'Prénom',
    errorMsg: 'Veuillez entrer un prénom',
    required: true,
    halfWidth: true,
  },
  {
    label: 'Nom',
    inputType: 'text',
    valueName: 'lastName',
    placeholder: 'Nom',
    errorMsg: 'Veuillez entrer un nom',
    required: true,
    halfWidth: true,
  },
  {
    label: 'E-mail',
    inputType: 'email',
    valueName: 'mail',
    placeholder: 'Email',
    errorMsg: 'Veuillez entrer un email valide',
    required: true,
    regex: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    halfWidth: true,
  },

  {
    label: 'Mot de passe',
    inputType: 'password',
    valueName: 'password',
    placeholder: 'Mot de passe',
    errorMsg:
      'Votre mot de passe doit comporter au moins 8 caractères, une lettre majuscule, un chiffre et un caractère spécial.',
    regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-_]).{8,}$/,
    required: true,
    halfWidth: true,
  },

  {
    label: 'Statut',
    inputType: 'select',
    valueName: 'statut',
    options: statutOptions,
    keyOptions: 'name',
    keyValues: 'name',
    errorMsg: 'Veuillez choisir le statut',
    halfWidth: true,
    required: true,
    style: 'max-w-[318px] flex',
  },
];

const AddAdministrators = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const getListRoleUser = useStoreActions((actions) => actions.middleware.TypeMiddleware.getListRoleUser);
  const createAdmin = useStoreActions((actions) => actions.middleware.AdminMiddleware.createAdmin);
  const createAdminUser = useStoreActions((action) => action.middleware.Cognito.createAdminUser);
  const setAdminPassword = useStoreActions((action) => action.middleware.Cognito.setAdminPassword);

  //  🌱🌱  Etats (données)  🌱🌱
  const [showModal, setShowModal] = useState(false);
  const [roleUser, setRoleUser] = useState([]);

  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱  Function FormCustom onSubmit  🌱🌱
  const onSubmit = (value) => {
    if (value.status) {
      createCognitoAdmin(value.values);
    }
  };

  //  🌱🌱  function getListRoleUser   🌱🌱
  const callGetListRoleUser = async () => {
    try {
      const res = await getListRoleUser();
      console.log('callGetListRoleUser', res);
      setRoleUser(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement des rôles');
    }
  };

  useEffect(() => {
    callGetListRoleUser();
  }, []);

  //  🌱🌱 Request create Admins Table  🌱🌱
  const createProfileAdmin = async (data) => {
    try {
      // Map roles user array to get id
      const roleUserSelected = roleUser.find((role) => role.name === data.statut);
      const newData = { ...data, id_role_user: roleUserSelected.id };
      await createAdmin(newData);
      setShowModal(true);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouvel administrateur, veuillez réessayer.");
    }
  };

  //  🌱🌱 Request Admins confirme password 🌱🌱
  const confirmPasswordAdmin = async (data) => {
    try {
      await setAdminPassword(data);
      createProfileAdmin(data);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouvel administrateur, veuillez réessayer.");
    }
  };

  //  🌱🌱 Request create Admins cognito  🌱🌱
  const createCognitoAdmin = async (data) => {
    try {
      const res = await createAdminUser(data);
      const newData = { ...data, id_cognito: res.userSub };
      confirmPasswordAdmin(newData);
    } catch (error) {
      toast.error("❗️ Une erreur est survenue durant la création d'un nouvel administrateur, veuillez réessayer.");
    }
  };

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 h-full flex flex-col">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9 ">
        <h3 className="font-bold text-xl ">Ajouter un nouveau administrateur</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <FormCustom
          data={formAddAdmin}
          resultSubmit={onSubmit}
          ref={submitRef}
        />
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Créer un nouveau administrateur"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => {
            submitRef.current.submit();
          }}
        />
      </div>

      {/* Modal Add Admin */}
      <Modal
        title="Nouvel admin enregistré"
        text="Nous avons bien enregistré votre nouvel admin. Vous le retrouverez dans la liste des admins et pouvez le modifier à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddAdministrators;


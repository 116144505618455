import React from "react";
import ListSuppliers from "../shared/components/suppliers/ListSuppliers";

const Suppliers = () => {
  return (
    <div>
      <ListSuppliers />
    </div>
  );
};

export default Suppliers;

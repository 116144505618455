/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import DatePicker_month from '../DatePicker_month';
import DatePicker_year from '../DatePicker_year';

import {
  Chart as ChartJS,
  LineElement,
  CategoryScale, // Axe X
  LinearScale, // Axe Y
  Tooltip,
  Legend,
  PointElement,
  Title,
} from 'chart.js';
import DatePicker_date from '../DatePicker_date';
ChartJS.register(LineElement, CategoryScale, LinearScale, Tooltip, Legend, PointElement, Title);

const LineChartJs = ({ data, onChangeDate }) => {
  const [myData, setMyData] = useState([]);
  const [typeDisplay, setTypeDisplay] = useState(0);
  const [selectDate, setSelectDate] = useState(new Date());

  // effect watch data event
  useEffect(() => {
    if (typeDisplay === 0) setMyData(byDay(data, selectDate));
    if (typeDisplay === 1) setMyData(byMonth(data, selectDate));
    if (typeDisplay === 2) setMyData(byYear(data, selectDate));
  }, [data, typeDisplay, selectDate]);

  useEffect(() => {
    let result = null;
    if (typeDisplay === 0) result = { type: 'day', date: selectDate };
    if (typeDisplay === 1) result = { type: 'month', date: selectDate };
    if (typeDisplay === 2) result = { type: 'year', date: selectDate };
    onChangeDate(result);
  }, [typeDisplay, selectDate]);

  const byDay = (data, date) => {
    const filteredData = data.filter((d) => new Date(d.date).toDateString() === date.toDateString());
    return {
      label: filteredData.map((d) => d.date),
      data: filteredData.map((d) => d.amountTTC - d.amountRemaining),
    };
  };

  const byMonth = (data, date) => {
    const filteredData = data.filter(
      (d) => new Date(d.date).getMonth() === date.getMonth() && new Date(d.date).getFullYear() === date.getFullYear()
    );
    return {
      label: filteredData.map((d) => d.date),
      data: filteredData.map((d) => d.amountTTC - d.amountRemaining),
    };
  };

  const byYear = (data, date) => {
    const filteredData = data.filter((d) => new Date(d.date).getFullYear() === date.getFullYear());
    return {
      label: filteredData.map((d) => d.date),
      data: filteredData.map((d) => d.amountTTC - d.amountRemaining),
    };
  };

  const dataLine = (data) => {
    return {
      labels: data.label,
      datasets: [
        {
          label: 'Euro €',
          data: data.data,
          backgroundColor: ['#E7ACAC'],
          borderColor: '#E7ACAC',
          tension: 0.4, // TENSION :c'est pour avoir une courbe arrondie
        },
      ],
    };
  };

  const optionsLine = {
    plugins: {
      legend: false,
      title: {
        display: true,
        color: '#1F2A37',
        align: 'start',
        font: {
          size: 16,
          family: 'Mulish',
        },
        padding: {
          top: 10,
          bottom: 10,
        },
      },
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Mulish',
          },
          color: '#1F2A37',
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Mulish',
          },
          color: '#1F2A37',
          callback: function (label) {
            return `${this.getLabelForValue(label)} €`;
          },
        },
      },
    },
  };

  const onChange = (event) => {
    setTypeDisplay(parseInt(event.target.value));
  };

  return (
    <div className="flex flex-col md:h-[450px] h-[250px]">
      <div className="flex items-center justify-between ">
        <p className="font-bold text-blue-admin font-mulish ">Suivi des chantiers</p>
        <div className="flex space-x-3">
          {typeDisplay === 0 && (
            <DatePicker_date
              selected={selectDate}
              onChange={setSelectDate}
              styleInput="inputFocus p-2 pr-6"
            />
          )}
          {typeDisplay === 1 && (
            <DatePicker_month
              selected={selectDate}
              onChange={setSelectDate}
              styleInput="inputFocus p-2 pr-6"
            />
          )}
          {typeDisplay === 2 && (
            <DatePicker_year
              selected={selectDate}
              onChange={setSelectDate}
              styleInput="inputFocus p-2 pr-6"
            />
          )}
          <select
            className="p-2 text-blue-admin border-2 rounded-lg w-fit bg-yellow-admin font-mulish inputFocus"
            name=""
            id=""
            onChange={onChange}
          >
            <option value={0}>Par jour</option>
            <option value={1}>Par mois</option>
            <option value={2}>Par année</option>
          </select>
        </div>
      </div>

      <Line
        data={dataLine(myData)}
        options={optionsLine}
      ></Line>
    </div>
  );
};

export default LineChartJs;


import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import SecondaryButton from '../../components/SecondaryButton';

const NavigationConstructionSite = () => {
  //  🌱🌱 init store(services)   🌱🌱
  const setSelectDriver = useStoreActions((actions) => actions.global.setSelectDriver);
  const getListDriver = useStoreActions((actions) => actions.middleware.DriverMiddleware.getListDriver);
  const getListMaterials = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.getListMaterials);
  const setSelectMarchandise = useStoreActions((actions) => actions.global.setSelectMarchandise);
  const selectDriver = useStoreState((state) => state.global.selectDriver);
  const selectMarchandise = useStoreState((state) => state.global.selectMarchandise);

  //  🌱🌱  Etats (données)  🌱🌱
  const [drivers, setDrivers] = useState([]);
  const [marchandises, setMarchandises] = useState([]);

  //  🌱🌱 Request call List Drivers  🌱🌱
  const callListDriver = async () => {
    try {
      const res = await getListDriver();
      setDrivers(
        res.map((truck) => {
          return { ...truck, fullName: `${truck.firstName} ${truck.lastName}` };
        })
      );
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chauffeurs');
    }
  };

  //  🌱🌱 Request call List Material  🌱🌱
  const callListMaterials = async () => {
    try {
      const res = await getListMaterials();
      setMarchandises(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des marchandises');
    }
  };
  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListDriver();
    callListMaterials();
  }, []);
  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-14 px-4 md:px-9">
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 mb-4 pt-11 md:pt-0 flex-col md:flex-row">
        <h3 className="font-bold text-xl md:pb-0 pb-5 ">Liste des chantiers</h3>
        <SecondaryButton
          title=" + Ajouter un nouveau chantier"
          link={'/constructionSites/add-constructionSite'}
        />
      </div>

      <div className="flex flex-col gap-y-4 justify-between md:flex-row md:gap-y-0">
        <div className="flex md:flex-row md:space-x-4 flex-col space-y-4 md:space-y-0">
          <NavLink
            to="list-construction-sites-not-finish"
            className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
          >
            Chantiers non traités
          </NavLink>
          <NavLink
            to="list-construction-sites-finish"
            className={({ isActive }) => (isActive ? 'navigationActive' : 'navigation')}
          >
            Chantiers traités
          </NavLink>
        </div>
        <div className="flex flex-col md:flex-row gap-y-4 md:gap-y-0 md:gap-x-4">
          <select
            value={selectDriver}
            onChange={(e) => setSelectDriver(e.target.value)}
            className="navigation inputFocus"
          >
            <option value=""> Par chauffeur</option>
            {drivers.map((driver) => (
              <option
                key={driver.id}
                value={driver.id}
              >
                {driver.fullName}
              </option>
            ))}
          </select>
          <select
            value={selectMarchandise}
            className="navigation inputFocus"
            onChange={(e) => setSelectMarchandise(e.target.value)}
          >
            <option value="">Par marchandise</option>
            {marchandises.map((marchandise) => (
              <option
                key={marchandise.id}
                value={marchandise.id}
              >
                {marchandise.name}
              </option>
            ))}
          </select>
        </div>
      </div>
    </section>
  );
};

export default NavigationConstructionSite;


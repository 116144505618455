import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getWaveBlob } from 'webm-to-wav-converter';

// Import Components
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';
import SecondaryButton from '../SecondaryButton';

// Import icons
import Check from '../../../assets/images/svg/check.svg';
import RecordIcon from '../../../assets/images/svg/record.svg';
import StopIcon from '../../../assets/images/svg/stop.svg';

// Import components
import AudioPlayer from '../../components/AudioPlayer';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const AddVoiceNote = () => {
  //  🌱🌱 Init navigation  🌱🌱
  const navigate = useNavigate();

  //  🌱🌱 init store(services)   🌱🌱
  const currentUser = useStoreState((state) => state.userData.currentUser);
  const createVoiceNote = useStoreActions((actions) => actions.middleware.VoiceNoteMiddleware.createVoiceNote);
  const getListCollaborators = useStoreActions(
    (actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators
  );
  const uploadFile = useStoreActions((actions) => actions.middleware.FileS3.uploadFile);
  const sendNotificationPush = useStoreActions((actions) => actions.middleware.Notification.sendNotificationPush);
  const getTokenNotification = useStoreActions((actions) => actions.middleware.Notification.getTokenNotification);

  // 🌱🌱 Etats (données)  🌱🌱
  const [receiver, setReceiver] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [collabs, setCollabs] = useState([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);

  //  🌱🌱 Request call List Collaborators  🌱🌱
  const callListCollaborators = async () => {
    try {
      const payload = {
        nameCollab: collaboratorName.toString(),
      };
      const res = await getListCollaborators(payload);
      //   Sort collabs by first name and last name
      res.sort((a, b) => a.first_name.localeCompare(b.first_name));
      console.log('DATA', res);
      setCollabs(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callListCollaborators();
  }, []);

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else if (mediaRecorder) {
      mediaRecorder.stop();
    }
  }, [isRecording]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        getWaveBlob(event.data, true).then((e) => {
          setAudioBlob(e);
        });
      };
      recorder.start();
      setMediaRecorder(recorder);
    } catch (error) {
      toast.error("Impossible de démarrer l'enregistrement audio.");
    }
  };

  const handleSubmit = async () => {
    if (!receiver) {
      toast.error('❗️ Veuillez sélectionner un destinataire');
      return;
    }
    if (!audioBlob) {
      toast.error('❗️ Veuillez ajouter une note vocale');
      return;
    }

    try {
      // Convertir le blob en fichier
      const file = new File([audioBlob], `audio_note_${receiver}_${Date.now()}.wav`, {
        type: 'audio/wave',
      });
      console.log('file', file);

      // Générer un chemin unique
      const filePath = `audio/audio_note_${receiver}_${Date.now()}.wav`;
      console.log('filePath', filePath);

      // Étape 1 : Upload du fichier audio dans S3
      await uploadFile({
        file: file,
        nameFile: filePath,
        contentType: file.type,
      });

      // Étape 2 : Mise à jour des données du chantier avec l'URL de l'audio
      const audioUrl = filePath;
      console.log('audioUrl', audioUrl);
      await createVoiceNote({
        firstname: currentUser.first_name,
        lastname: currentUser.last_name,
        audioUri: audioUrl,
        userVoiceNotesId: receiver,
      });
      // Get token notification FCM of the receiver 'RECEIVER_ID'
      const res = await getTokenNotification(receiver);
      console.log('token', res.token);
      // Send notification push to the receiver
      if (res.token) {
        await sendNotificationPush({
          token: res.token,
          title: 'Nouveau message !!',
          message: `Vous avez reçu une nouvelle note vocale de ${currentUser.first_name} ${currentUser.last_name}`,
        });
      }
      toast.success('✅ La note vocale a été ajoutée avec succès');
      setAudioBlob(null);
      setIsRecording(false);
      setMediaRecorder(null);
      setReceiver(null);
      navigate('/notes-vocales');
    } catch (error) {
      toast.error("Erreur lors de l'ajout de la note vocale. Veuillez réessayer.");
      console.error('Erreur lors de la sauvegarde de la note vocale:', error);
    }
  };

  const handleReceiverChange = (e) => {
    console.log('e', e.target.value);
    setReceiver(e.target.value);
  };

  return (
    <section className=" h-full flex flex-col md:pt-14 overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-4 md:mx-9">
        <h3 className="font-bold text-xl ">Enregistrer une nouvelle note vocale</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Sélectionnez un destinataire</label>
          <select
            name=""
            className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown py-3 pl-5 pr-10 rounded-lg border-none inputFocus"
            onChange={handleReceiverChange}
            required
          >
            <option value="">-- Sélectionnez un destinataire --</option>
            {collabs.map((collab) => (
              <option
                key={collab.id}
                value={collab.id}
                data-collab={JSON.stringify(collab)} // Encodage de l'objet en JSON
              >
                {collab.first_name} {collab.last_name}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col space-y-3  justify-center max-w-[550px] py-6">
          <label>Enregistrer une nouvelle note vocale</label>
          <div className="py-4 flex flex-col sm:items-center text-xs md:text-sm">
            <button
              onClick={() => setIsRecording(!isRecording)}
              className={['px-4 py-2 rounded-lg w-full flex items-center justify-center bg-transparent']}
            >
              <img
                src={isRecording ? StopIcon : RecordIcon}
                alt={isRecording ? 'Arrêter l’enregistrement' : 'Enregistrer une note vocale'}
                className="w-8 h-8"
              />
            </button>

            {/* Lecture et sauvegarde de l'audio */}
            {audioBlob && (
              <div className="mt-4 flex flex-col sm:items-center w-full">
                <AudioPlayer src={URL.createObjectURL(audioBlob)} />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />

        <PrimaryButton
          title="Enregistrer"
          style="bg-yellow-admin text-blue-admin"
          onClick={() => handleSubmit()}
        />
      </div>
      {/* Modal Add Truck */}
      <Modal
        title="Nouvelle note vocale enregistrée"
        text="Nous avons bien enregistré votre nouvelle note vocale. Vous la retrouverez dans la liste des notes vocales et pouvez la supprimer à tout moment."
        showModal={showModal}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModal(false);
            navigate(-1);
          }}
        />
      </Modal>
    </section>
  );
};

export default AddVoiceNote;


import { thunk } from 'easy-peasy';
import { API } from '@aws-amplify/api';
import awsApiConfig from './config/awsApiConfigure';

API.configure(awsApiConfig);

const SettingsMiddleware = {
  //✨✨==========================================✨✨
  //            GET LIST MATERIAL
  //✨✨==========================================✨✨
  getListMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listMaterialSettings {
          items {
            id
            name
            references {
              reference
              published
            }
          }
        }
      }
    `;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listMaterialSettings.items;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //        GET LIST TYPE INTERVENTION SETTINGS
  //✨✨==========================================✨✨
  getListTypeIntervention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      query MyQuery {
        listTypeInterventionSettings {
          items {
            id
            name
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.listTypeInterventionSettings.items;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            CREATE MATERIAL SETTINGS
  //✨✨==========================================✨✨
  createMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        createMaterialSettings(input: {
        name: "${payload.material}", 
        references: {
          published: true, 
          reference: "${payload.values}"
          }}) {
          name
          references {
            published
            reference
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createMaterialSettings;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //      CREATE TYPE INTERVENTION SETTINGS
  //✨✨==========================================✨✨
  createTypeIntervention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        createTypeInterventionSettings(input: {
        name: "${payload}"
      }) {
        name
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.createTypeInterventionSettings;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            UPDATE MATERIAL SETTINGS
  //✨✨==========================================✨✨
  updateMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        updateMaterialSettings(input: {
          id: "${payload.id}", 
          name: "${payload.material}", 
          references: {
            published: true, 
            reference: "${payload.values}"
            }}) {
          id
          name
          references {
            reference
            published
          }
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateMaterialSettings;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //       UPDATE TYPE INTERVENTION SETTINGS
  //✨✨==========================================✨✨
  updateTypeIntervention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        updateTypeInterventionSettings(input: {
          id: "${payload.id}", 
          name: "${payload.typeIntervention}"
        }) {
          id
          name
        }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.updateTypeInterventionSettings;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //            DELETE MATERIAL SETTINGS
  //✨✨==========================================✨✨
  deleteMaterial: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        deleteMaterialSettings(input: {id: "${payload}"}) 
            {
          id
        }  
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteMaterialSettings;
      console.log('DATA', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),

  //✨✨==========================================✨✨
  //        DELETE TYPE INTERVENTION SETTINGS
  //✨✨==========================================✨✨
  deleteTypeIntervention: thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    // body request
    const graphqlQuery = `
      mutation MyMutation {
        deleteTypeInterventionSettings(
          input: 
          {
            id: "${payload}"
          }) 
          {
            id
          }
      }`;
    getStoreActions().global.setActivity(true);
    try {
      const query = await API.graphql({
        query: graphqlQuery,
      });
      const myData = query.data?.deleteTypeInterventionSettings;
      console.log('DATA: deleteTypeInterventionSettings', myData);
      return myData;
    } finally {
      getStoreActions().global.setActivity(false);
    }
  }),
};

export default SettingsMiddleware;


import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { toast } from 'react-toastify';

// Import Components
import Table from '../../Table';
import PrimaryInput from '../../PrimaryInput';
import HoverStatuts from '../../HoverStatuts';
import { dateFormat } from '../../../tools/date';
import { sorted } from '../../../tools/file';

// Import icons
import More from '../../../../assets/images/svg/more.svg';
import Search from '../../../../assets/images/svg/search.svg';
import TruckDay from '../../../../assets/images/svg/truckDay.svg';

const ListConstructionSiteNotFinish = () => {
  const { pathname } = useLocation();
  const { idClient } = useParams();

  const path = pathname.includes('clients') ? '/clients/editNotFinish/' : '/constructionSites/edit-constructionSite/';
  const isClientMode = pathname.includes('clients');

  //  🌱🌱 init store(services)   🌱🌱
  //🌿 Actions
  const getListConstructionSiteNotFinish = useStoreActions((actions) => actions.middleware.ConstructionSiteMiddleware.getListConstructionSiteNotFinish);
  const setWarnningConstructionSite = useStoreActions((actions) => actions.global.setWarnningConstructionSite);
  const getListConstructionSiteNotFinishByClient = useStoreActions(
    (actions) => actions.middleware.ConstructionSiteMiddleware.getListConstructionSiteNotFinishByClient
  );
  // 🌿 State
  const selectDriver = useStoreState((state) => state.global.selectDriver);
  const selectMarchandise = useStoreState((state) => state.global.selectMarchandise);

  //  🌱🌱  Etats (données)  🌱🌱
  const [listConstructionSite, setListConstructionSite] = useState([]);
  const [constructionSites, setConstructionSites] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  //  🌱🌱  useRef  🌱🌱
  const listRefConstructionSite = useRef([]);
  const textSearchRef = useRef(null);
  const isFilterRef = useRef(false);

  //  🌱🌱 Request call List constructionSite  🌱🌱
  const callListConstructionSiteNotFinish = async () => {
    try {
      const res = await getListConstructionSiteNotFinish();
      setConstructionSiteliste(res);
      const action = res.some(
        (el) => el?.driver?.id == undefined || new Date(el?.datePlanned).getTime() < Date.now() - 21600000
      );
      if (action) {
        setWarnningConstructionSite(true);
      } else {
        setWarnningConstructionSite(false);
      }
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chantiers');
    }
  };

  //  🌱🌱 Request call List constructionSite by client  🌱🌱
  const callListConstructionSiteNotFinishByClient = async () => {
    try {
      const res = await getListConstructionSiteNotFinishByClient(idClient);
      setConstructionSiteliste(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des chantiers');
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    if (isClientMode) {
      callListConstructionSiteNotFinishByClient();
    } else {
      callListConstructionSiteNotFinish();
    }
  }, []);

  //  🌱🌱  fun classed list constructionSites  🌱🌱
  const setConstructionSiteliste = (TabConstructionSites) => {
    let table1 = [];
    let table2 = [];
    let table3 = [];
    TabConstructionSites.forEach((element) => {
      let plDate = new Date(element?.datePlanned);
      if (plDate.getTime() < Date.now() - 21600000) {
        table1.push(element);
      } else if (element?.driver?.id === undefined) {
        table2.push(element);
      } else {
        table3.push(element);
      }
    });
    let myTable = [
      ...sorted(table1, 'numero', true),
      ...sorted(table2, 'numero', true),
      ...sorted(table3, 'numero', true),
    ];
    setListConstructionSite(myTable);
    setConstructionSites(myTable);
    listRefConstructionSite.current = myTable;
  };

  //  🌱🌱  2de effect for search 🌱🌱
  useEffect(() => {
    if (listRefConstructionSite.current.length) {
      if (isFilterRef.current === false) {
        if (textSearch === '') {
          setListConstructionSite(constructionSites);
        } else {
          const tabFilter = constructionSites.filter(
            (element) =>
              element.client?.firstName?.toLowerCase().includes(textSearch.toLowerCase()) ||
              element.client?.lastName?.toLowerCase().includes(textSearch.toLowerCase()) ||
              element.numero?.toLowerCase().includes(textSearch.toLowerCase())
          );
          setListConstructionSite(tabFilter);
        }
      }
      isFilterRef.current = false;
    }
  }, [textSearch]);

  //  🌱🌱  3e effect for filter by driver 🌱🌱
  useEffect(() => {
    if (listRefConstructionSite.current.length) {
      if (selectDriver) {
        setListConstructionSite(listRefConstructionSite.current.filter((element) => element?.driver?.id === selectDriver));
        setConstructionSites(listRefConstructionSite.current.filter((element) => element?.driver?.id === selectDriver));
      }
      if (selectDriver === '' && selectMarchandise === '') {
        setListConstructionSite(listRefConstructionSite.current);
        setConstructionSites(listRefConstructionSite.current);
      }
      textSearchRef.current.value = '';
      setTextSearch('');
      if (textSearchRef) {
        isFilterRef.current = false;
      } else {
        isFilterRef.current = true;
      }
    }
  }, [selectDriver]);

  //  🌱🌱  4e effect for filter by marchandise 🌱🌱
  useEffect(() => {
    if (listRefConstructionSite.current.length) {
      if (selectMarchandise) {
        setListConstructionSite(listRefConstructionSite.current.filter((element) => element?.marchandise?.id === selectMarchandise));
        setConstructionSites(listRefConstructionSite.current.filter((element) => element?.marchandise?.id === selectMarchandise));
      }
      if (selectDriver === '' && selectMarchandise === '') {
        setListConstructionSite(listRefConstructionSite.current);
        setConstructionSites(listRefConstructionSite.current);
      }
      textSearchRef.current.value = '';
      setTextSearch('');
      if (textSearchRef) {
        isFilterRef.current = false;
      } else {
        isFilterRef.current = true;
      }
    }
  }, [selectMarchandise]);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="pt-8 px-4 md:px-9">
      {/* div Search bar */}
      <div className=" relative mb-10">
        <PrimaryInput
          styleInput={'pl-10 h-12 '}
          type="text"
          placeholder="Recherche (client ou n° de commande)"
          onChangeText={setTextSearch}
          myRef={textSearchRef}
        />
        <img
          src={Search}
          alt="search icon"
          className=" absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
        />
      </div>
      {/* Liste des commandes */}
      <div className="mt-4 pb-5">
        <Table>
          <thead>
            <tr>
              <th>Créé le </th>
              <th>N° de commande</th>
              <th>
                <HoverStatuts text="Statut ☉" />
              </th>
              <th>Nom du client</th>
              <th>Adresse</th>
              <th>Marchandise</th>
              <th>Prévu le</th>
              <th>Chauffeur</th>
              <th className="flex justify-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {listConstructionSite.length ? (
              listConstructionSite.map((constructionSite) => {
                const createDate = dateFormat(constructionSite?.date).full;
                const Planneddate = dateFormat(constructionSite?.datePlanned).full;
                let plDate = new Date(constructionSite?.datePlanned);
                const setpcolor = () => {
                  if (plDate.getTime() < Date.now() - 21600000) {
                    return 'bg-statut-step-0';
                  }
                  if (constructionSite?.driver) {
                    return 'bg-statut-step-2';
                  } else {
                    return 'bg-statut-step-1';
                  }
                };
                return (
                  <tr key={constructionSite.id}>
                    <td>{createDate}</td>
                    <td>{constructionSite.numero}</td>
                    <td>
                      <span className={`w-4 h-4 block rounded-md shadow-2xl ${setpcolor()}`}></span>
                    </td>
                    <td>
                      {constructionSite?.client?.firstName} {constructionSite?.client?.lastName}
                    </td>
                    <td>{constructionSite?.address?.address}</td>
                    <td>
                      {constructionSite.shipType == 'Journée' ? (
                        <img
                          src={TruckDay}
                          className="w-10"
                          alt="icon truck"
                        />
                      ) : (
                        `${constructionSite?.marchandise?.name}`
                      )}
                    </td>
                    <td>{Planneddate}</td>

                    <td>
                      <div className="flex items-center ">
                        {constructionSite?.driver ? (
                          <div className="hoverInitial">
                            {constructionSite?.driver?.firstName[0]}
                            {constructionSite?.driver?.lastName[0]}
                            <span>
                              {constructionSite?.driver?.firstName}
                              {constructionSite?.driver?.lastName}
                            </span>
                          </div>
                        ) : (
                          <div className="text-red-error">Assigner un chauffeur</div>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className="flex space-x-8 justify-end items-center">
                        <Link
                          to={`${path}${constructionSite.id}`}
                          className="w-8 rounded-lg bg-gray-sidebar py-0.5 px-1"
                        >
                          <img
                            src={More}
                            alt="more icon"
                          />
                        </Link>
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td
                  className="text-center"
                  colSpan={9}
                >
                  {textSearch
                    ? "Votre recherche n'a pas donné de résultats. Veuillez essayer avec d'autres mots-clés."
                    : 'La liste des chantiers est vide.'}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </section>
  );
};

export default ListConstructionSiteNotFinish;


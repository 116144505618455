import { useEffect, useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import Modal from '../shared/components/Modal_2';
import ModalV1 from '../shared/components/Modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrimaryButton from '../shared/components/PrimaryButton';
import { useStoreState, useStoreActions } from 'easy-peasy';

// import components
import Table from '../shared/components/Table';

// import icons
import DeleteIcon from '../assets/images/svg/delete-icon.svg';
import EditIcon from '../assets/images/svg/edit-icon.svg';
import FolderIcon from '../assets/images/svg/folder.svg';
import Search from '../assets/images/svg/search.svg';
import AttentionIcon from '../assets/images/svg/attention.svg';
import AssignedIcon from '../assets/images/svg/assigned.svg';
import Check from '../assets/images/svg/check.svg';

// data format
import { dateFormat } from '../shared/tools/manager';

// .Env value
const collaboratorName = process.env.REACT_APP_COLLABORATOR_NAME;

const WorkSite = () => {
  //init navigation
  const navigate = useNavigate();

  //fun navigation
  const navigation = (id, state) => {
    navigate(`./order/${id}/purchaseorder`, { state: state });
  };

  //init store

  const getCompany = useStoreActions((actions) => actions.middleware.CompanyMiddleware.getCompany);
  const getlistWorkSite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.getlistWorkSite);
  const getlistWorksiteUsers = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.getlistWorksiteUsers);
  const getlistWorksiteCollaborators = useStoreActions(
    (actions) => actions.middleware.WorksiteMiddleware.getlistWorksiteCollaborators
  );
  const deleteMultipleWorksiteUsers = useStoreActions(
    (actions) => actions.middleware.WorksiteMiddleware.deleteMultipleWorksiteUsers
  );
  const deleteWorksite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.deleteWorksite);
  const updateStatusWorkSite = useStoreActions((actions) => actions.middleware.WorksiteMiddleware.updateStatusWorkSite);
  const createWorksiteCollaborators = useStoreActions(
    (actions) => actions.middleware.WorksiteMiddleware.createWorksiteCollaborators
  );
  const deleteMultipleWorksiteCollaborators = useStoreActions(
    (actions) => actions.middleware.WorksiteMiddleware.deleteMultipleWorksiteCollaborators
  );
  const getListCollaborators = useStoreActions(
    (actions) => actions.middleware.CollaboratorMiddleware.getListCollaborators
  );
  const deleteBill = useStoreActions((actions) => actions.middleware.BillMiddleware.deleteBill);
  const listBillsByBillWorksiteId = useStoreActions(
    (actions) => actions.middleware.BillMiddleware.listBillsByBillWorksiteId
  );
  const activity = useStoreState((state) => state.activity);

  //url params
  const { idCustomer } = useParams();
  const { idPrestataire } = useParams();

  //init state
  const [listeWorkSites, setListeWorkSites] = useState([]);
  const [collabs, setCollabs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCollabModal, setShowCollabModal] = useState(false);
  const [worksiteSelected, setWorksiteSelected] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(useLocation().state);
  const [worksitesList, setWorksitesList] = useState(listeWorkSites);
  const [textSearch, setTextSearch] = useState('');
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);

  // func filter search, Listen
  useEffect(() => {
    if (textSearch === '') {
      setWorksitesList(listeWorkSites);
    } else {
      const tabFilter = listeWorkSites.filter((element) =>
        element.name.toLowerCase().includes(textSearch.toLowerCase())
      );
      setWorksitesList(tabFilter);
    }
  }, [textSearch, listeWorkSites]);

  // fun call customer
  const callCustomer = async () => {
    try {
      let id = idCustomer ? idCustomer : idPrestataire;
      const res = await getCompany(id);
      setCurrentCustomer(res);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors de la récupération des données client 🤯');
      if (!idCustomer) window.location.href = '/clients';
      else window.location.href = '/prestataires';
    }
  };

  // fun call list
  const callWorksites = async () => {
    try {
      const res = await getlistWorkSite(currentCustomer?.id);
      // Sort by date_planned
      res.sort((a, b) => new Date(b.date_planned) - new Date(a.date_planned));
      setListeWorkSites(res);
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors de la récupération de la liste des chantiers 🤯');
    }
  };

  //udate status
  const updateStatus = async (data) => {
    try {
      await updateStatusWorkSite(data);
      await callWorksites();
      toast.success('Statut modifié avec succès');
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors du changement de statut 🤯');
    }
  };

  //  🌱🌱 Request call List Collaborators  🌱🌱
  const callListCollaborators = async () => {
    try {
      const payload = {
        nameCollab: collaboratorName.toString(),
      };
      const res = await getListCollaborators(payload);
      // Sort collaborators by first_name
      res.sort((a, b) => a.first_name.localeCompare(b.first_name));
      console.log('DATA', res);
      setCollabs(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la liste des collaborateurs');
    }
  };

  useEffect(() => {
    callListCollaborators();
  }, []);

  //Effect
  useEffect(() => {
    if (!currentCustomer) {
      callCustomer();
    } else if (!listeWorkSites.length && currentCustomer) {
      callWorksites();
    }
  }, [currentCustomer]);

  // fun click
  const deleteAction = async (workSite) => {
    setShowModal(true);
    const res = await listBillsByBillWorksiteId(workSite.id);
    workSite.worksiteBillId = res[0]?.id;
    setWorksiteSelected(workSite);
  };

  // fun delete
  const callDeleteWorksite = async () => {
    try {
      const res = await getlistWorksiteUsers(worksiteSelected.id);
      console.log(res);
      if (res.length > 0) {
        await deleteMultipleWorksiteUsers(res);
      }
      await deleteWorksite(worksiteSelected.id);
      if (worksiteSelected?.worksiteBillId) {
        await deleteBill(worksiteSelected?.worksiteBillId);
      }
      setShowModal(false);
      callWorksites();
      setWorksiteSelected(null);
      toast.success('✅ Le chantier a bien été supprimé');
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors de la suppression du chantier 🤯');
    }
  };

  const callDeleteOrCreateCollaborators = async () => {
    try {
      const res = await getlistWorksiteCollaborators(worksiteSelected.id);
      // Check if the user is already in the list of collaborators assigned to the worksite
      let collaboratorsToDelete = [];
      let collaboratorsToCreate = [];
      res.forEach((element) => {
        // If the user is not in the list of collaborators assigned to the worksite, add it to the list of collaborators to delete
        if (!selectedCollaborators.includes(element.user.id)) {
          collaboratorsToDelete.push(element.id);
        }
      });
      selectedCollaborators.forEach((element) => {
        // If the user is not in the list of collaborators assigned to the worksite, add it to the list of collaborators to create
        if (!res.some((e) => e.user.id === element)) {
          collaboratorsToCreate.push(element);
        }
      });

      console.log('collaboratorsToDelete', collaboratorsToDelete);
      console.log('collaboratorsToCreate', collaboratorsToCreate);
      if (collaboratorsToDelete.length) {
        await deleteMultipleWorksiteCollaborators(collaboratorsToDelete);
      }
      if (collaboratorsToCreate.length) {
        collaboratorsToCreate.forEach(async (element) => {
          console.log('element', element);
          await createWorksiteCollaborators({ id_user: element, id_worksite: worksiteSelected.id });
        });
      }
      toast.success('Liste des assignements modifiée avec succès');
      setShowCollabModal(false);
      setWorksiteSelected(null);
      setSelectedCollaborators([]);
      callWorksites();
    } catch (error) {
      console.log('Error: ', error);
      toast.error('❌ Une erreur est survenue lors de la modification des collaborateurs assignés au chantier 🤯');
    }
  };

  const callAssignableCollaborators = async (worksite) => {
    console.log('worksite', worksite);

    // Si le chantier a déjà des collaborateurs assignés, afficher la liste des collaborateurs sélectionnés (checkbox)
    if (worksite.collaborators.items.length) {
      let collaboratorsAlreadyAssigned = [];
      worksite.collaborators.items.forEach((element) => {
        collaboratorsAlreadyAssigned.push(element.user.id);
      });
      setSelectedCollaborators(collaboratorsAlreadyAssigned);
    }
    setShowCollabModal(true);
    setWorksiteSelected(worksite);
  };

  //Render
  return (
    <>
      <section className="px-14">
        <div>
          <div className="flex items-center justify-between pb-4 border-b pt-11 border-white-admin ">
            <h3 className="font-semibold">
              Liste des chantiers: <span className="font-bold text-blue-admin">{currentCustomer?.name}</span>
            </h3>
            <PrimaryButton
              title="+ Ajouter un nouveau chantier"
              link="add"
              style={'border border-gray-admin text-blue-admin bg-white'}
              state={currentCustomer}
            />
          </div>
          <div className="relative flex mt-6 border border-white-admin rounded-xl">
            <input
              type="text"
              placeholder="Recherche chantier"
              className="w-full py-3 pl-10 border-none rounded-xl placeholder:text-blue-admin inputFocus"
              onInput={(e) => setTextSearch(e.target.value)}
            />
            <img
              src={Search}
              alt="search icon"
              className="absolute left-0 flex items-center w-8 h-5 pl-3 top-4"
            />
          </div>
          <div className="overflow-auto pt-14">
            <Table>
              <thead className="h-10">
                <tr>
                  <th>Débute le:</th>
                  <th className="pl-10 xl:pl-24">Type chantier</th>
                  <th className="pl-10 xl:pl-24">Nom chantier</th>
                  <th className="pl-10 xl:pl-24">Statut</th>
                  <th className="pl-10 xl:pl-24 w-48">Assigné à</th>
                  <th className="pl-10 xl:pl-24">Actions</th>
                </tr>
              </thead>
              <tbody>
                {worksitesList.length ? (
                  worksitesList.map((WorkSite) => {
                    const dateWorksiteStart = dateFormat(WorkSite.date_planned, '/').full;
                    return (
                      <tr
                        key={WorkSite.id}
                        className="h-16 border-b border-white-admin hover:bg-blue-50 transition-all cursor-pointer"
                      >
                        <td onClick={() => navigation(WorkSite.id, WorkSite)}>{dateWorksiteStart}</td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(WorkSite.id, WorkSite)}
                        >
                          {WorkSite.type.name}
                        </td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => navigation(WorkSite.id, WorkSite)}
                        >
                          {WorkSite.name}
                        </td>
                        <td className="pl-10 xl:pl-24">
                          <select
                            name=""
                            className="cursor-pointer text-blue-admin text-sm bg-blue-dropdown py-3 pl-5 pr-10 rounded-lg border-none inputFocus"
                            value={WorkSite.status} // La valeur sélectionnée correspond à `WorkSite.status`
                            onChange={(e) =>
                              updateStatus({
                                status: e.target.value,
                                id: WorkSite.id,
                              })
                            }
                          >
                            <option value="En cours">En cours</option>
                            <option value="Passe">Passé</option>
                            <option value="Futur">Futur</option>
                          </select>
                        </td>
                        <td
                          className="pl-10 xl:pl-24"
                          onClick={() => {
                            callAssignableCollaborators(WorkSite);
                          }}
                        >
                          {/* Si le chantier a des collaborateurs assignés, afficher la liste des collaborateurs sinon afficher un bouton pour afficher la liste des collaborateurs */}
                          {WorkSite.collaborators.items.length ? (
                            WorkSite.collaborators.items.map((collab) => (
                              <div
                                className="flex items-center gap-4 my-2"
                                key={collab.user.id}
                              >
                                <span className="hoverInitial">
                                  {collab.user.first_name[0]}
                                  {collab.user.last_name[0]}
                                  <span>
                                    {collab.user.first_name}
                                    {collab.user.last_name}
                                  </span>
                                </span>
                              </div>
                            ))
                          ) : (
                            <img
                              src={AssignedIcon}
                              alt="assigned icon"
                            />
                          )}
                        </td>
                        <td className="flex items-center pt-4 pl-10 space-x-4 xl:pl-24 ">
                          <Link
                            to={`./order/${WorkSite.id}/purchaseorder`}
                            state={WorkSite}
                          >
                            <button className="w-8 p-2 border rounded-lg border-gray-separate">
                              <img
                                src={FolderIcon}
                                alt="Worksite icon"
                              />
                            </button>
                          </Link>
                          <Link
                            to={`./edit/${WorkSite.id}`}
                            state={WorkSite}
                          >
                            <button className="w-8 p-2 border rounded-lg border-gray-separate">
                              <img
                                src={EditIcon}
                                alt="edit icon"
                              />
                            </button>
                          </Link>
                          <button
                            className="w-8 p-2 border rounded-lg border-gray-separate"
                            onClick={() => deleteAction(WorkSite)}
                          >
                            <img
                              src={DeleteIcon}
                              alt="delete icon"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      className="text-center text-lg font-semibold pt-20"
                      colSpan={6}
                    >
                      {activity ? <></> : textSearch ? "Oups, ce chantier n'existe pas 🤭" : 'Ajouter un Chantier'}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
      <Modal isVisible={showModal}>
        <div>
          <div className="pt-5 pl-5">
            <img
              src={AttentionIcon}
              alt="attention icon"
            />
            <p className="pl-5 pt-7">Supression du chantier: {worksiteSelected?.name}</p>
            {!worksiteSelected?.worksiteBillId && (
              <p className="px-5 pt-2 text-gray-admin">
                Êtes-vous sûre de vouloir supprimer ce chantier ? Cette action est définitive.
              </p>
            )}
            {worksiteSelected?.worksiteBillId && (
              <p className="px-5 pt-2 text-gray-admin">
                Ce chantier est associé à une facture. Êtes-vous sûre de vouloir supprimer ce chantier et la facture
                associée? Cette action est définitive.
              </p>
            )}
            <div className="text-center pt-7">
              <button
                className="px-12 py-3 mr-4 font-semibold border rounded-xl text-red-error"
                onClick={() => setShowModal(false)}
              >
                Annuler
              </button>
              <button
                className="px-10 py-3 text-white bg-red-error rounded-xl"
                onClick={callDeleteWorksite}
              >
                Supprimer
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {/* Modal Collaborators avec checkbox pour sélectionner à quels collaborateurs attribuer le chantier */}
      <ModalV1
        title="Attribution de collaborateurs"
        text="Sélectionnez les collaborateurs à attribuer au chantier"
        showModal={showCollabModal}
        image={Check}
      >
        <div className="flex flex-col space-y-4 h-40 overflow-auto">
          {collabs.map((collab) => (
            <div
              key={collab.id}
              className="flex items-center space-x-4 m-2"
            >
              {/* Si le collaborateur est déjà assigné au chantier, afficher un checkbox pour le désélectionner, sinon afficher un checkbox pour l'ajouter (checkbox) */}
              <input
                type="checkbox"
                name=""
                className="cursor-pointer text-blue-admin text-sm bg-yellow-admin py-3 pl-2 pr-5 rounded-lg border-none inputFocus"
                value={collab.id} // La valeur sélectionnée correspond à `WorkSite.status`
                onChange={(e) =>
                  setSelectedCollaborators(
                    e.target.checked
                      ? [...selectedCollaborators, collab.id]
                      : selectedCollaborators.filter((element) => element !== collab.id)
                  )
                }
                checked={selectedCollaborators.includes(collab?.id)}
              />
              <p>
                {collab.first_name} {collab.last_name}
              </p>
            </div>
          ))}
        </div>
        <PrimaryButton
          title="Confirmer"
          style="w-full mt-8"
          onClick={() => {
            callDeleteOrCreateCollaborators();
          }}
        />
        <PrimaryButton
          title="Annuler"
          style="w-full mt-4 text-red-error border border-red-error bg-white"
          onClick={() => {
            setShowCollabModal(false);
            setWorksiteSelected(null);
            setSelectedCollaborators([]);
          }}
        />
      </ModalV1>
    </>
  );
};

export default WorkSite;


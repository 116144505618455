import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { toast } from 'react-toastify';

// import components
import FormCustom from '../../FormCustom';
import SecondaryButton from '../../SecondaryButton';
import PrimaryButton from '../../PrimaryButton';
import Modal from '../../Modal';

//import icons
import Check from '../../../../assets/images/svg/check.svg';
import Warning from '../../../../assets/images/svg/warning.svg';

const EditMaterials = () => {
  //  🌱🌱  Init Navigation  🌱🌱
  const navigate = useNavigate();
  const { idMaterial } = useParams();

  //  🌱🌱  init use Ref  🌱🌱
  const submitRef = useRef();

  //  🌱🌱 init store(services)   🌱🌱
  const getMaterial = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.getMaterial);
  const updateMaterial = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.updateMaterial);
  const deleteMaterial = useStoreActions((actions) => actions.middleware.MaterialsMiddleware.deleteMaterial);

  //  🌱🌱  Etats (données)  🌱🌱
  const [material, setMaterial] = useState({});
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  //  🌱🌱  formulaire material  🌱🌱
  const formulairMaterial = [
    {
      label: 'Matériaux',
      inputType: 'text',
      valueName: 'name',
      placeholder: 'Matériaux',
      errorMsg: 'Entrer un materials',
      required: true,
      halfWidth: true,
      defaultValue: material?.name,
    },
  ];

  //  🌱🌱 Request call  material  🌱🌱
  const callMaterial = async () => {
    try {
      const res = await getMaterial(idMaterial);
      setMaterial(res);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors du chargement de la marchandise');
    }
  };

  //  🌱🌱 Request update  material  🌱🌱
  const updateProfileMaterial = async (data) => {
    try {
      const res = await updateMaterial(data);
      setShowModalUpdate(true);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors des changements de la marchandise');
    }
  };

  //  🌱🌱 Request delete  material  🌱🌱
  const deleteProfileMaterial = async () => {
    try {
      const res = await deleteMaterial(idMaterial);
      setShowModalDelete(false);
      navigate(-1);
    } catch (error) {
      toast.error('❗️ Une erreur est survenue lors de la suppression de la marchandise');
    }
  };

  // 🌱🌱 submit Form 🌱🌱
  const submit = (value) => {
    if (value.status) {
      const newData = {
        ...value.values,
        id: idMaterial,
      };
      updateProfileMaterial(newData);
    }
  };

  //  🌱🌱  First effect  🌱🌱
  useEffect(() => {
    callMaterial();
  }, []);

  //  🎉🎉  Render  🎉🎉
  return (
    <section className="md:pt-16 h-full flex flex-col overflow-y-auto">
      {/* div title */}
      <div className=" flex items center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 mx-9">
        <h3 className="font-bold text-xl ">Modifier un matériel</h3>
      </div>
      {/* div form custom */}
      <div className="mx-9 max-w-[1100px] grow">
        {material?.id && (
          <FormCustom
            data={formulairMaterial}
            resultSubmit={submit}
            ref={submitRef}
          />
        )}
      </div>
      {/* div buttons */}
      <div className="flex flex-col-reverse gap-y-4 px-9 pt-4 mt-9 border-t border-bg-gray-admin pb-5  md:flex-row md:items-center md:justify-between md:mt-1">
        <SecondaryButton
          title="Annuler"
          onClick={() => navigate(-1)}
        />
        <div className="flex space-x-3">
          <SecondaryButton
            title="Supprimer ce matériel"
            onClick={() => setShowModalDelete(true)}
          />
          <PrimaryButton
            title="Enregistrer les modifications"
            style="bg-yellow-admin text-blue-admin"
            onClick={() => submitRef.current.submit()}
          />
        </div>
      </div>
      <Modal
        title="Modification marchantise enregistrée"
        text="Nous avons bien enregistré votre les modification de marchandise. Vous la retrouverez dans la liste des marchandises."
        showModal={showModalUpdate}
        image={Check}
      >
        <PrimaryButton
          title="OK, j’ai compris"
          style="w-full"
          onClick={() => {
            setShowModalUpdate(false);
            navigate(-1);
          }}
        />
      </Modal>
      <Modal
        title="Supprimer une marchantise"
        text="Êtes-vous sûre de vouloir supprimer cette marchantise ? 
        Cette action est définitive. Vous n’aurez plus accès à cette marchantise."
        showModal={showModalDelete}
        image={Warning}
      >
        <div className="flex gap-6">
          <SecondaryButton
            title="Retour"
            style="w-1/2"
            onClick={() => {
              setShowModalDelete(false);
            }}
          />
          <PrimaryButton
            title="Supprimer"
            style="w-1/2 "
            onClick={() => {
              deleteProfileMaterial();
            }}
          />
        </div>
      </Modal>
    </section>
  );
};

export default EditMaterials;


import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const NavigationClassifyOrder = () => {
  const location = useLocation(); // Récupère l'URL actuelle
  const currentPath = location.pathname; // Chemin complet de l'URL

  const isActive = (path) => currentPath === `/classify-order/${path}`; // Vérifie si le chemin est actif

  return (
    <div>
      <section className="md:pt-14 px-4 md:px-9">
        {/* div Title = button */}
        <div className="flex flex-col items-center justify-between border-b border-gray-admin pb-4 pt-11 md:pt-0 mb-4 md:flex-row">
          <h3 className="font-bold text-xl text-center md:text-left">Liste des bons à classer</h3>
        </div>
        <div className="pb-4 border-b pt-11 border-white-admin">
          <ul className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8">
            <li className={`text-blue-admin text-center rounded-lg p-3 ${isActive('orderCoupon') ? 'bg-yellow-admin text-blue-admin' : 'border border-gray-admin bg-white'}`}>
              <Link to="orderCoupon">Bons de commande</Link>
            </li>
            <li className={`text-blue-admin text-center rounded-lg p-3 ${isActive('interventionCoupon') ? 'bg-yellow-admin text-blue-admin' : 'border border-gray-admin bg-white'}`}>
              <Link to="interventionCoupon">Bons d’intervention</Link>
            </li>
          </ul>
        </div>
      </section>
    </div>
  );
};

export default NavigationClassifyOrder;

